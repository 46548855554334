import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flex: '1',
  },

  lists: {
    display: 'flex',
    width: '50%',
    marginBottom: 45,
    minWidth: 300,
  },

  icon: {
    marginRight: 12,
    width: '18px',
    height: '20px',
    position: 'relative',
    top: -4,
  },

  sectors: {
    '& p': {
      fontSize: '13px',
      lineHeight: '1.23',
      color: ({ theme: { colors } }) => colors.accent,
    },
  },

  sectorsList: {
    listStyle: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    marginTop: '20px',
  },

  '@media (max-width: 768px)': {
    content: {
      justifyContent: 'flex-start',
    },
  },
});
