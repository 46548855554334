import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    width: '80%',
    maxWidth: 1200,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    lineHeight: 1.2,
    fontFamily: 'GoogleSans',
    '& p': {
      marginBottom: 15,
      fontSize: 16,
    },
    '& ul': {
      marginBottom: 15,
    },
    '& li': {
      marginLeft: 18,
    },
    '& a': {
      color: 'rgba(23, 192, 255, 0.87)'
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  mainTitle: {
    marginBottom: 15,
  }
})