import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#282e51',
    borderRadius: 5,
  },
  searchIcon: {
    width: 22,
    height: 22,
    padding: '2px',
    marginRight: '8px',
    marginLeft: '8px',
    color: ({ theme }) => theme.colors.accent,
  },

  input: {
    border: 'none',
    display: 'block',
    backgroundColor: 'transparent',
    color: '#fff',
    lineHeight: 2,
    fontFamily: 'GoogleSans',
    width: 200,
    fontSize: 18,

    '&:focus': {
      outline: 'none',
    },

    '&::placeholder, &::-webkit-input-placeholder, &-moz-input-placeholder,  &::-ms-input-placeholder': {
      width: '150px',
      height: '24px',
      fontSize: '16px',
      lineHeight: '1.5',
      color: ({ theme }) => theme.colors.accent,
    },
  },

  '@media (max-width: 768px)': {
    input: {
      width: '-webkit-fill-available',
    },

    searchBox: {
      width: '100%',
      // marginRight: 10,
    }
  },
});
