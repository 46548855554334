import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as VerifiedIcon } from '../../assets/verified.svg';
import { truncateString } from '../../helpers';
import { Benefits } from '../Benefits/Benefits';
import { DeployedRegions } from '../DeployedRegions/DeployedRegions';
import { DeploymentCases } from '../DeploymentCases/DeploymentCases';
import { GoBack } from '../GoBack/GoBack';
import { LearningFormat } from '../LearningFormat/LearningFormat';
import { Media } from '../Media/Media';
import { SectorList } from '../SectorList/SectorList';
import { TechAndCompatibility } from '../SectorList/TechAndCompatibility';
import Slider from '../Slider/Slider';
import { GeneralInfo } from '../SolutionSideBar/GeneralInfo/GeneralInfo';
import { SideBarSectionTitle } from '../SolutionSideBar/SideBarSectionTitle/SideBarSectionTitle';
import { Tag } from '../Tag/Tag';
import { useStyles } from './style';
import { SolutionAreas } from '../SolutionAreas/SolutionAreas';
import HeroSlider from '../HeroSlider/HeroSlider';
import { TagNew } from '../TagNew/TagNew';

interface SolutionContentProps {
  provider: string;
  postedA: string;
  title: string;
  type: string;
  applicationGroup: any;
  applications: string[];
  sectors: string[];
  subSectors: any;
  digitalTechnologies: string[];
  solutionCompatibility?: string[]; // sol or boht???
  customizations?: string[];
  briefSummary: string;
  tags: string[];
  displyPhotoUrl: string;
  // both(sol + learning;
  detailedDescretpion: string; // required
  testimonials?: any;
  websiteUrl?: string;
  email?: string;
  media?: any;
  submittedAt: string;
  token: string;
  verified: boolean;

  // sol only
  deployedRegions?: string[];
  deployedCases?: string[];
  benefits?: any;
}

const getTypesShortcuts = (type: string) => {
  return [type];
};

const TEXT_LENGTH = 1400;

export const SolutionContent: React.FC<any> = ({
  postedA,
  title,
  provider,
  email,
  websiteUrl,
  displyPhotoUrl,
  type = '',
  applicationGroup,
  applications,
  sectors,
  subSectors,
  digitalTechnologies,
  solutionCompatibility,
  detailedDescretpion,
  benefits,
  testimonials,
  deployedRegions = [],
  deployedCases = [],
  media = [],
  verified: isVerified = false,
  durationType,
  duration,
  dates = [],
  locations = [],
  showProviderInfo = false,
  isAuthenticated,
  areas, // TODO: change this to a more discrptive name across the app,
  company,
  isNew,
  isTourFinished,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [isTruncated, setIsTruncated] = useState(true);
  const [desc, setDesc] = useState(detailedDescretpion);
  const history = useHistory();
  const { state } = useLocation();
  const params: any = useParams();

  let area: any = {};

  if (isAuthenticated) {
    area = areas?.find((item: any) => item.id === params.area);
  }

  const links: any[] = Object.values(media);
  const medias = Array.from(links);
  if (displyPhotoUrl) {
    medias.push({
      url: displyPhotoUrl,
      ext: getUrlExtension(displyPhotoUrl),
      fileName: title,
      type: 'image',
    });
  }

  useEffect(() => {
    setDesc(detailedDescretpion);
  }, [detailedDescretpion]);

  useEffect(() => {
    if (isTruncated) {
      const truncatedString = truncateString(desc || '', TEXT_LENGTH);
      setDesc(truncatedString);
    } else {
      setDesc(detailedDescretpion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTruncated, desc]);

  return (
    <div className={classes.content}>
      {(state?.area || params.area) && (
        <div onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
          <GoBack text={area?.title} />
        </div>
      )}

      <h2 className={classes.heading}>
        {title}&nbsp;&nbsp;&nbsp;&nbsp;
        {isVerified && (
          <span title="EnergyExpert Verified" className={classes.verifedIcon}>
            <VerifiedIcon />
          </span>
        )}
        {isNew && <TagNew />}
      </h2>

      <div className={classes.tags}>
        {getTypesShortcuts(type).map((text) => (
          <Tag key={text} text={text} />
        ))}
      </div>

      <div>
        <SectorList
          applicationGroup={applicationGroup}
          applications={applications}
          subSectors={subSectors}
          isTourFinished={isTourFinished}
        />
      </div>

      <div className={classes.description}>
        <p
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {desc}
          {desc && desc.length > TEXT_LENGTH && (
            <span
              style={{
                cursor: 'pointer',
              }}
              onClick={() =>
                setIsTruncated((prevIsTruncated) => !prevIsTruncated)
              }
            >
              &nbsp;{!isTruncated ? 'Read less' : 'Read more'}
            </span>
          )}
        </p>
      </div>

      {isTourFinished ? (
        <div className={classes.slider}>
          {medias && medias.length > 1 ? (
            <HeroSlider medias={medias}></HeroSlider>
          ) : (
            <img src={displyPhotoUrl} alt="" className={classes.slider} />
          )}
        </div>
      ) : (
        <img
          src={displyPhotoUrl}
          alt=""
          className={classes.slider}
          height={400}
        />
      )}

      <div className={classes.techAndCompatibility}>
        <TechAndCompatibility
          digitalTechnologies={digitalTechnologies}
          solutionCompatibility={solutionCompatibility}
          isTourFinished={isTourFinished}
        />
      </div>

      {/*  toggle the next two section on and off for learning and solution */}
      {postedA === 'Solution' && Object.keys(benefits).length > 0 && (
        <div className={classes.list}>
          <h4 className={classes.sectionHeading}>
            <span className="benefits-tip">Benefits</span>
          </h4>
          <Benefits benefits={benefits} />
        </div>
      )}

      {postedA === 'Learning' && (
        <LearningFormat
          type={type}
          durationType={durationType}
          duration={duration}
        />
      )}

      {/*  if phone screens show map + deployed cases */}
      {deployedRegions.length > 0 && (
        <div className={classes.regionsMap}>
          <div>
            <SideBarSectionTitle>Regions Deployed</SideBarSectionTitle>
          </div>
          <div>
            <DeployedRegions deployedRegions={deployedRegions} />
          </div>
        </div>
      )}

      {locations.length > 0 && (
        <div className={classes.regionsMap}>
          <div>
            <SideBarSectionTitle>Locations Offered </SideBarSectionTitle>
          </div>
          <div>
            {locations.map((location: any) => (
              <p key={location} className={classes.location}>
                {location}
              </p>
            ))}
          </div>
          <div>
            <SolutionAreas locations={locations} origin="learning" />
          </div>
        </div>
      )}

      {/* cases */}

      {(deployedCases.length > 0 || dates.length > 0) && (
        <div className={classes.deployedCases}>
          <DeploymentCases
            deployedCases={deployedCases.length > 0 ? deployedCases : dates}
            sectionTitle={
              deployedCases.length > 0 ? 'Deployment Cases' : 'Dates Offered'
            }
          />
        </div>
      )}

      {/* Media */}
      {links.length > 0 && (
        <div className={classes.deployedCases}>
          <Media links={links} />
        </div>
      )}

      {/*  testimonials (sol + learning)*/}
      {testimonials && Object.keys(testimonials).length > 0 && (
        <div
          style={{
            marginBottom: 70,
          }}
        >
          <Slider testimonials={testimonials} />
        </div>
      )}

      {/* if phone show CalltoAction section here */}
      <div className={classes.deployedCases}>
        <GeneralInfo
          email={email}
          provider={provider}
          websiteUrl={websiteUrl}
          postedA={postedA}
          showProviderInfo={showProviderInfo}
          isAuthenticated={isAuthenticated}
          area={state?.area}
          title={title}
          isVerified={isVerified}
          company={company}
        />
      </div>
    </div>
  );
};

function getUrlExtension(url: string) {
  const urlParts = url.split(/#|\?/)[0].split('.').pop();
  return urlParts ? urlParts.trim() : 'jpg';
}
