import React from 'react';

interface TopLevelLabelProps {
  sector: string;
  level: string;
}

export const StyledLabel: React.FC<TopLevelLabelProps> = ({
  sector,
  level,
}) => {
  const topStyle = {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: 1.41,
  };
  const subStyle = {
    fontSize: 17,
    lineHeight: 1.41,
  };
  return <div style={level === 'top' ? topStyle : subStyle}>{sector}</div>;
};
