import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoggedOutRoute: React.FC<any> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!rest.isAuthenticated) {
          return children;
        } else {
          if (props.match.path === '/listings/:id') {
            return <Redirect to={`/home/listings/${props.match.params.id}`} />;
          } else {
            return <Redirect to="/home" />;
          }
        }
      }}
    />
  );
};

export default LoggedOutRoute;
