import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  navbar: {
    backgroundColor: ({ theme }) => theme.colors.bg.accent,
    height: '100%',
    minHeight: 84,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiPaper-root': {
      backgroundColor: 'red !important',
    },
  },
  searchWrap: {},

  avatarInfoIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  infoIcon: {
    marginRight: 20,
  },

  '@media (max-width: 768px)': {
    navbar: {
      backgroundColor: 'black',
      minHeight: 50,
    },
    searchWrap: {
      width: '50%',
    },
  },
});
