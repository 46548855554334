import React from 'react';
import Alert from './Alert';

interface SuccessAlertProps {
  msg: string;
}

export default function SuccessAlert({ msg, ...props }: SuccessAlertProps) {
  return (
    <Alert severity="success" {...props}>{msg}</Alert>
  );
}