import {
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    root: {
      opacity: 0.7,
      backgroundColor: 'white',
    },

    paper: {
      backgroundColor: '#303865',
      width: 618,
      borderRadius: 24,
      border: 'none',
      outline: 'none',
    },
    contentWapper: {
      padding: '30px 48px',
    },
    title: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.14,
      marginBottom: 6,
    },
    introParagraph: {
      opacity: 0.8,
      fontSize: 14,
      lineHeight: 1.43,
      fontWeight: 'normal',
      marginBottom: 22,
    },
    actionsButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    error: {
      color: 'red',
    },
    filled: {
      borderRadius: 4,
      backgroundColor: '#282e51',
    },
    success: {
      color: 'green',
    },
  })
);

export default useStyles;