import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'inline-block',
    marginRight: '8px',
    marginBottom: 4,
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '100px',
    border: ({ theme }) => `solid 1px ${theme.colors.bg.tagBorder}`,
    padding: '8px 12px',
    backgroundColor: '#5f9afc',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '1.54',
    whiteSpace: 'normal',
    textAlign: 'center',
  },
});
