import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

interface CustomTextFieldProps {
  title: string;
  placeholder?: string;
  required: boolean;
  value: string;
  error?: string;
  handleChange: any;
  name: string;
  hidden?: boolean,
}

export const TextField: React.FC<CustomTextFieldProps> = (props: CustomTextFieldProps) => {

  const {
    value,
    error,
    title,
    handleChange,
    placeholder,
    required,
    name,
    hidden = false,
  } = props;

  const theme = useTheme();
  const classes = useStyles({ theme });
  const [touched, setTouched] = useState(false);

  const handleBlur = () => {
    setTouched(true)
  };

  return (
    <div className={classes.formGroup}>
      <label
        className={classes.inputLabel}
        htmlFor={name}>
        {title}
      </label>
      <input
        type={hidden ? 'password' : 'text'}
        name={name}
        placeholder={placeholder}
        className={classes.input}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        id={name}
      />
      {Boolean(error) && touched ? (<small className={classes.error}>{error}</small>) : null}
  </div>
  );
}

export default TextField;
