import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  map: {
    height: 390,
  },

  '@media (max-width: 768px)': {
    map: {
      height: 180,
    },
  },
});
