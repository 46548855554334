import React from 'react';

import TreeWrapper from '../MuTree/TreeWrapper';
import TreeItem from '../MuTree/TreeItem';
import { StyledLabel } from './TreeItemLabel';

const CustomizedTreeView: React.FC<any> = ({
  applicationGroup,
  applications,
}) => {
  return (
    <TreeWrapper defaultExpanded={['1']}>
      <TreeItem
        nodeId="1"
        label={<StyledLabel sector={applicationGroup} level="top" />}
      >
        {applications &&
          applications.map((app: any) => {
            return (
              <TreeItem
                nodeId={app}
                label={<StyledLabel sector={app} level="sub" />}
                key={app}
              />
            );
          })}
      </TreeItem>
    </TreeWrapper>
  );
};

export default CustomizedTreeView;
