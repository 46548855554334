import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  goBack: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '60vh',
    right: 0,
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  mockIcon: {
    height: '24px',
    width: '20px',
    position: 'relative',
    top: '2px',
    animation: '1s linear 0s infinite alternate none running $move-x',
  },
  sector: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.5',
    color: ({ theme }) => theme.colors.accent,
    marginRight: '8px',
  },

  '@media (max-width: 768px)': {
    sector: {
      color: ({ theme }) => theme.colors.primary,
    },

    goBack: {
      top: -50,
      right: 25,
    }
  },

  '@keyframes move-x': {
    from: { transform: 'translateX(0)' },
    to: { transform: 'translateX(10px)' }
  },

});
