import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

import footerBackground from '../../assets/contact-us.svg';
import AskModal from '../SolutionSideBar/AskEnergyExpert/AskModal';

export const ContactUs: React.FC<any> = ({
  isAuthenticated,
  provider,
  title,
  area,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      onClickCapture={handleOpen}
    >
      <footer className={classes.contactUs}>
        <AskModal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          isAuthenticated={isAuthenticated}
          provider={provider}
          title={title}
          area={area?.title}
          subText="Ask EnergyExpert to explore something new."
          isContactUsForm={true}
          success={success}
          setSuccess={setSuccess}
        />
        <div className={classes.textContent}>
          <p className={classes.lookingForSomething}>
            Looking for something similar?
          </p>
          <p>
            The{' '}
            <span className={classes.contactSupportTeam}>
              EnergyExpert intelligence team{' '}
            </span>
            will help you find it.
          </p>
        </div>
        <img src={footerBackground} alt="" className={classes.image} />
      </footer>
    </div>
  );
};
