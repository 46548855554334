interface Colors {
  primary: string;
  accent: string;
  third: string;
  verified: string;
  detailsBtn: string;
  detailsBtnLight: string;
  favBtn: string;
  favBtnClicked: string;
  solutoinsCount: string;
  skyBlue: string;
  errors: string;
  notificationOrange: string;
  green: string;
  red: string;

  bg: {
    main: string;
    accent: string;
    count: string;
    countShadow: string;
    tagBorder: string;
    tagDot: string;
    verified: string;
    detailsBtn: string;
    favBtn: string;
    favBtnClicked: string;
    boxBorders: string;
    darkGreyBlueThree: string;
    tooltipBg: string;
    intelAreaHighlighted: string;
    newListings: string;
  };
}

const cornFlowerBlue = '#5f9afc';
const white = '#fff';
const darkGreyBlue = '#303865';
const cornflower = '#5c94f4';

// this not used for now
export const light: any = {
  primary: '#393161',
  accent: '#8a8699',
  third: '#3b46c2',
  verified: '#24b47e',
  detailsBtn: '#fff',
  detailsBtnLight: '#ffffff80',
  favBtn: '#393161',
  favBtnClicked: '#393161',
  solutoinsCount: '#fff',
  skyBlue: cornFlowerBlue,
  bg: {
    main: '#fff',
    accent: '#fff',
    count: '#3b46c2',
    countShadow: 'rgba(160, 115, 255, 0.28)',
    tagBorder: '#e9e8f0',
    tagDot: '#0271e3',
    verified: '#f0fef3',
    detailsBtn: '#3b46c2',
    favBtn: '#e2e6ed',
    favBtnClicked: '#ffe69c',
    boxBorders: '#e9e8f0',
  },
};

export const dark: Colors = {
  primary: white,
  accent: '#8389b8',
  third: '#3b46c2',
  verified: '#22274f',
  detailsBtn: white,
  detailsBtnLight: '#ffffff80',
  favBtn: '#f0fef3',
  favBtnClicked: darkGreyBlue,
  solutoinsCount: white,
  skyBlue: cornFlowerBlue,
  errors: '#ff8a64',
  notificationOrange: '#ff8d00',
  green: '#6dd400',
  red: '#e02020',

  bg: {
    main: '#161933',
    accent: darkGreyBlue,
    count: cornFlowerBlue,
    countShadow: 'rgba(160, 115, 255, 0.28)',
    tagBorder: '#5a5f87',
    tagDot: '#0271e3',
    verified: '#6ed69a',
    detailsBtn: cornFlowerBlue,
    favBtn: '#414976',
    favBtnClicked: '#ffe69c',
    boxBorders: '#e9e8f0',
    darkGreyBlueThree: '#282e51',
    tooltipBg: 'rgba(22, 25, 51, 0.5)',
    intelAreaHighlighted: cornflower,
    newListings: '#483d8b',
  },
};
