import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { ReactComponent as FileIcon } from '../../assets/media-file.svg';
import { ReactComponent as ImageIcon } from '../../assets/media-image.svg';
import { ReactComponent as PdfIcon } from '../../assets/media-pdf.svg';
import { ReactComponent as VideoIcon } from '../../assets/media-video.svg';
import { ReactComponent as WordIcon } from '../../assets/media-word.svg';
import { SimpleBackdrop } from '../PdfViewer/PdfViewer';
import { SideBarSectionTitle } from '../SolutionSideBar/SideBarSectionTitle/SideBarSectionTitle';
import { TagNew } from '../TagNew/TagNew';
import { useStyles } from './style';

const icon: any = {
  pdf: PdfIcon,
  video: VideoIcon,
  word: WordIcon,
  image: ImageIcon,
  general: FileIcon,
};

let baseURL = process.env.REACT_APP_API_BASE_URL;
const localUrl = 'http://localhost:4000';

if (process.env.NODE_ENV === 'development') {
  baseURL = localUrl;
}

export const Media: React.FC<any> = ({
  links = [],
  dataBox,
  fullUrl,
  updateSeenStatus,
  sectionTitle,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [link, setLink] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setShowPdf(false);
    setLink(null);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (link) {
      handleToggle();
      setShowPdf(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const renderMediaType = (link: any, Icon: any) => {
    const content = (
      <div className={classes.providerInfo}>
        <div className={classes.icon}>
          <Icon />
        </div>
        <div className={classes.content}>
          <p className="title" title={link.fileName}>
            {link.fileName}&nbsp;&nbsp;&nbsp;&nbsp;
            {link.new && <TagNew />}
          </p>
          <p className="see-more">{link.type.toUpperCase()}</p>
        </div>
      </div>
    );
    if (link.type === 'video') {
      return (
        <a
          href={link.url}
          id={link.url}
          data-link-id={links.url}
          className={dataBox ? classes.dataBoxFileLink : classes.fileLink}
          key={link._id || link.url}
          style={{
            cursor: 'pointer',
          }}
        >
          {content}
        </a>
      );
    } else {
      return (
        <div
          onClick={(e: any) => {
            if (link.type !== 'general') {
              setLink(e.target.id);
              !!dataBox && updateSeenStatus(link._id);
            }
          }}
          id={link.url}
          data-link-id={links.url}
          className={dataBox ? classes.dataBoxFileLink : classes.fileLink}
          key={link._id || link.url}
          style={{
            cursor: 'pointer',
          }}
        >
          {content}
        </div>
      );
    }
  };

  return (
    <>
      {showPdf && (
        <SimpleBackdrop
          url={fullUrl ? link : `${baseURL}/api/v1/download?url=${link}`}
          open={open}
          handleClose={handleClose}
        />
      )}
      {!dataBox && <SideBarSectionTitle>{sectionTitle}</SideBarSectionTitle>}
      <div className={classes.mediaWrap}>
        {links.map((link: any, i: any) => {
          const Icon = icon[link.type] || FileIcon;
          return renderMediaType(link, Icon);
        })}
      </div>
    </>
  );
};
