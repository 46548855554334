import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#6ed69a',
    color: '#22274f',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
  arrow: {
    color: '#6ed69a',
  },
}))(Tooltip);

interface VerifiedTooltipProps {
  children: any;
}

export default function VerifiedTooltip({ children }: VerifiedTooltipProps) {
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">EnergyExpert Verified</Typography>
          </React.Fragment>
        }
        arrow
        placement="top"
      >
        {children}
      </HtmlTooltip>
    </div>
  );
}
