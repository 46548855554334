import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

export const Tag: React.FC<{ text: string }> = ({ text }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.wrapper}>
      <div className={classes.tag}>
        <span className={classes.text}>{text}</span>
      </div>
    </div>
  );
};
