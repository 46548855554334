import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { useHistory } from 'react-router-dom';

const ENTER_KEY = 'Enter';

export const SearchBox: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchStart = (e: any) => {
    if (e.key === ENTER_KEY) {
      if (searchTerm) {
        history.push('/home/search', searchTerm);
      }
    }
  };

  return (
    <div className={[classes.searchBox, 'search-input'].join(' ')}>
      <div>
        <SearchIcon className={classes.searchIcon} />
      </div>
      <input
        className={classes.input}
        value={searchTerm}
        type="text"
        placeholder="Search..."
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleSearchStart}
      />
    </div>
  );
};
