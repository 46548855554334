import React, { FC, useState } from 'react';
import { useTheme } from 'react-jss';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useStyles } from './style';
import { ReactComponent as ShareIcon } from '../../../assets/share.svg';
import { SocialMediaShare } from './SocialMediaShare';
import { GoBack } from '../../GoBack/GoBack';
import { gAEvent } from '../../../components/EnergyExpertTracking';
import CopyToClipboard from '../../CopyToClipboard/CopyToClipboard';

const shareWithSomeoneData = {
  id: 3,
  title: 'Share with someone',
  text: 'Share a link to this page',
};

const ColleagueOrExternal: React.FC<{ text: string; onClick: any }> = ({
  text,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div onClick={onClick} className={classes.buttonWrapper}>
      <div className={classes.tag}>
        <span className={classes.text}>{text}</span>
      </div>
    </div>
  );
};

export const ShareWithSomeone: FC<any> = ({
  company,
  postedA,
  enableAnimation,
  title,
  provider,
  generalInfoRef,
  onScrollUp,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { id } = useParams();
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [toggleSocialIcons, setToggleSocialIcons] = useState(false);
  const [isExternal, setIsExternal] = useState();

  if (!company && !toggleSocialIcons) {
    setToggleSocialIcons(true);
    setIsExternal(true);
  }

  const getExternalLink = () => {
    const sharedUrl = `${window.location.origin}/listings/${id}`;
    return sharedUrl;
  };

  return (
    <>
      <div className={classes.providerInfo}>
        <CopyToClipboard>
          {({ copy }: any) => (
            <div
              className={classes.copyToClipboard}
              onClickCapture={() => {
                onScrollUp(generalInfoRef);
                setShowShareOptions((state) => !state);
                showShareOptions && setToggleSocialIcons(false);
                if (!company && !showShareOptions && toggleSocialIcons) {
                  copy(getExternalLink());
                }
              }}
            >
              <div className={classes.iconWrapper}>
                <div
                  className={`${classes.icon} ${
                    enableAnimation
                      ? classes.slideUpAnimation + ' ' + classes.positionFixed
                      : ''
                  }`}
                >
                  <ShareIcon />
                </div>
              </div>
              <p
                className={`${classes.iconTitle} ${
                  enableAnimation ? classes.fadeInAnimation : ''
                }`}
              >
                Share
              </p>
            </div>
          )}
        </CopyToClipboard>

        {!showShareOptions ? (
          <div
            className={classes.content}
            onClickCapture={() => setShowShareOptions((state) => !state)}
          >
            <p className="title">{shareWithSomeoneData.title}</p>
            <p className="see-more">{shareWithSomeoneData.text}</p>
          </div>
        ) : (
          <div className={classes.shareActionWrapper}>
            <div className={classes.shareAction}>
              {!!company && (
                <div>
                  {!toggleSocialIcons ? (
                    <>
                      <CopyToClipboard>
                        {({ copy }: any) => (
                          <div
                            onClick={() => copy(window.location.href)}
                            className={classes.copyToClipboard}
                          >
                            <ColleagueOrExternal
                              text={`${company} colleague`}
                              onClick={() => {
                                setTimeout(() => {
                                  setToggleSocialIcons(true);
                                }, 500);
                                setIsExternal(false);
                                gAEvent({
                                  category: provider,
                                  action: 'Colleague Share',
                                  label: title,
                                });
                                ReactGA.set({
                                  contentGroup1: provider,
                                  contentGroup2: title,
                                });
                              }}
                            />
                          </div>
                        )}
                      </CopyToClipboard>
                      <CopyToClipboard>
                        {({ copy }: any) => (
                          <div
                            onClick={() => copy(getExternalLink())}
                            className={classes.copyToClipboard}
                          >
                            <ColleagueOrExternal
                              text="External"
                              onClick={() => {
                                setTimeout(() => {
                                  setToggleSocialIcons(true);
                                }, 500);
                                setIsExternal(true);
                                gAEvent({
                                  category: provider,
                                  action: 'External Share',
                                  label: title,
                                });
                                ReactGA.set({
                                  contentGroup1: provider,
                                  contentGroup2: title,
                                });
                              }}
                            />
                          </div>
                        )}
                      </CopyToClipboard>
                    </>
                  ) : null}
                </div>
              )}

              {toggleSocialIcons && (
                <div className={classes.shareWrapper}>
                  <div className={classes.externalOrInternal}>
                    {isExternal ? `External` : `Colleague`}
                  </div>
                  <SocialMediaShare
                    url={!isExternal ? window.location.href : getExternalLink()}
                    postedA={postedA}
                  />
                  <div
                    onClick={() => {
                      if (!!company) {
                        setToggleSocialIcons(false);
                      } else {
                        setShowShareOptions(false);
                      }
                    }}
                    className={classes.goBackIcon}
                  >
                    <GoBack text="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
