import React, { useState } from 'react';
import Slider from 'react-slick';
import { useTheme } from 'react-jss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useStyles } from './style';
import ReactPlayer from 'react-player';
import { ReactComponent as ArrowRight } from '../../assets/chevron-right-filled.svg';

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ArrowRight
      className={className}
      style={{
        ...style,
        display: 'block',
        right: '25px',
        zIndex: '100',
        width: '32px',
        height: '32px',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ArrowRight
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '25px',
        zIndex: '100',
        width: '32px',
        height: '32px',
        transform: 'rotate(180deg) translateY(16px)',
      }}
      onClick={onClick}
    />
  );
}

export const HeroSlider: React.FC<any> = props => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const slider = React.useRef<Slider>(null);
  const [settings, setSettings] = useState({
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  });
  const { medias } = props;
  const [playing, setPlaying] = useState(false);
  const videoConfig = {
    file: {
      attributes: {
        autoPlay: true,
        muted: true
      }
    }
  };
  
  const startSlider = () => {
    const newSettings = { ...settings };
    newSettings.autoplay = true;
    setSettings(newSettings);
    setPlaying(false);
    slider.current?.slickPlay();
  };

  const stopSlider = () => {
    const newSettings = { ...settings };
    newSettings.autoplay = false;
    setSettings(newSettings);
    setPlaying(true);
    slider.current?.slickPause();
  };

  return (
    <div className={classes.content}>
      <Slider ref={slider} {...settings}>
        {medias.map((m: any, i: number) => {
          let element = null;
          switch (m.type) {
            case 'image':
              element = (
                <img
                  key={i}
                  src={m.url}
                  alt={m.fileName}
                  className={classes.slider}
                />
              );
              break;
            case 'video':
              element = (
                <ReactPlayer
                  playing={playing}
                  key={i}
                  url={m.url}
                  controls
                  light={false}
                  volume={0}
                  muted={true}
                  loop={false}
                  config={videoConfig}
                  className={classes.video}
                  onPlay={stopSlider}
                  onPause={startSlider}
                  onEnded={startSlider}
                />
              );
              break;
          }
          return element;
        })}
      </Slider>
    </div>
  );
};

export default HeroSlider;
