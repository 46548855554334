import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

import LeafLetMap from '../Map/Map';

interface SolutionAreasProps {
  locations: string[];
  origin?: string;
}

export const SolutionAreas: FC<SolutionAreasProps> = ({
  locations,
  origin,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const countries = locations?.map(loc => {
    if (loc.includes(',')) {
      return loc?.split(',')[1]?.trim();
    }
    return loc;
  });

  return (
    <div className={classes.map}>
      <LeafLetMap deployedRegions={countries} origin={origin} />
    </div>
  );
};
