import React from 'react';
import { IntelligenceListLayout } from '../../layouts/IntelligenceListLayout/IntelligenceListLayout';
import { ListingPageContent } from '../../components/ListingPageContent/ListingPageContent';
import { ScrollToTopOnMount } from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';

export const Listing: React.FC<any> = (props) => {
  const {
    listings,
    isFetchingListings,
    name,
    jobTitle,
    updateAuthState,
    isAuthenticated,
    currentPortal,
    avatar,
  } = props;
  const param: any = useParams();
  const areas = currentPortal?.groupedAreas ? currentPortal?.groupedAreas.reduce((areas: any, group: any) => areas.concat(group.areas), []) : currentPortal?.areas;
  const currentArea = areas.find((item: any) => {
    return item.id === param?.area;
  });

  let listToRender: any = listings[param?.area];
  return currentArea ? (
    <>
      <ScrollToTopOnMount />
      <Helmet>
        <title>EnergyExpert</title>
      </Helmet>
      <IntelligenceListLayout
        main={
          <ListingPageContent
            listings={listToRender}
            isFetchingListings={isFetchingListings}
            currentArea={currentArea}
          />
        }
        name={name}
        jobTitle={jobTitle}
        updateAuthState={updateAuthState}
        isAuthenticated={isAuthenticated}
        areas={areas}
        company={currentPortal?.name}
        avatar={avatar}
        currentArea={currentArea}
        withTourGuide
      />
    </>
  ) : null;
};
