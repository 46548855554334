import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  slider: {
    height: '450px',
    width: '100%',
    border: 'none',
    outline: 'none',
  },
  video: {
    display: 'inline-block',
    width: '100% !important',
    height: '450px !important',
  },

  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    content: {
      '& .slick-track': {
        display: 'flex',
      },
      '& .slick-track .slick-slide': {
        display: 'flex',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    slider: {
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'bottom',
    },
    video: {
      display: 'inline-block',
      width: '100% !important',
    },
  },
});
