import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

import LeafLetMap from '../Map/Map';

interface DeployedRegionsProps {
  deployedRegions: string[];
  origin?: string;
}

export const DeployedRegions: FC<DeployedRegionsProps> = ({
  deployedRegions,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.map}>
      <LeafLetMap deployedRegions={deployedRegions} origin="solution" />
    </div>
  );
};
