import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';

import { ScrollToTopOnMount } from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import { PortalIntroLayout } from '../../layouts/PortalIntroLayout/PortalIntroLayout';

import { IntroPreparedFor } from '../../components/IntroPreparedFor/IntroPreparedFor';
import { IntroDiagnosis } from '../../components/IntroDiagnosis/IntroDiagnosis';
import { IntroToSupport } from '../../components/IntroToSupport/IntroToSupport';
import { IntroAreas } from '../../components/IntroAreas/IntroAreas';

const MAX_STEPS = 4;
const PortalIntro: React.FC<any> = (props) => {
  const [currentStep, setCurrentStep] = useState(MAX_STEPS);

  const next = () => {
    setCurrentStep((step) => (step >= MAX_STEPS - 1 ? MAX_STEPS : step + 1));
  };

  const {
    name: clinetName,
    jobTitle,
    currentPortal: currentPortalJson,
    email,
    loggedInBefore,
    portalAreasList,
    portalId,
    fetchData,
    isFetchingListings,
  } = props;

  useEffect(() => {
    const localStorageLoggedInBefore = localStorage.getItem('loggedInBefore');
    if (!localStorageLoggedInBefore) {
      setCurrentStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name: companyName, briefDiagnosis, toSupport, groupedAreas } = currentPortalJson;

  const renderRightScreen = () => {
    if (currentStep === 1) {
      return (
        <IntroPreparedFor
          name={clinetName}
          title={jobTitle}
          company={companyName}
          email={email}
          next={next}
          loggedInBefore={loggedInBefore}
        />
      );
    } else if (currentStep === 2) {
      return (
        <IntroDiagnosis
          title={briefDiagnosis?.title}
          briefDiagnosis={briefDiagnosis?.details}
          next={next}
        />
      );
    } else if (currentStep === 3) {
      return (
        <IntroToSupport portalId={portalId} company={companyName} next={next} toSupport={toSupport} />
      );
    } else if (currentStep === 4) {
      return (
        <IntroAreas
          company={companyName}
          portalAreasList={portalAreasList}
          groupedAreas={groupedAreas}
          next={next}
          portalId={props.portalId}
          fetchData={fetchData}
          isFetchingListings={isFetchingListings}
        />
      );
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Helmet>
        <title>EnergyExpert</title>
      </Helmet>

      <PortalIntroLayout
        main={renderRightScreen()}
        withSearch={currentStep === 4}
        withTourGuide={currentStep === 4}
      />
    </>
  );
};

export { PortalIntro };
