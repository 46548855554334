import React, { Fragment } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { BurgerIcon } from '../BurgerIcon/BurgerIcon';
import { Logo } from '../Logo/Logo';
import { ReactComponent as RightArrow } from '../../assets/chevron-right.svg';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: '#303865',
    },
  },
  list: {
    width: 380,
    color: '#ffffff',
  },
  listWrapper: {
    marginLeft: 60,
  },
  area: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.17,
    color: '#ffffff',
  },
  divider: {
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: 'rgb(92, 148, 244)',
    width: '85%',
    marginTop: 10,
    marginBottom: 10,
  },
});

function StyledMenuLink({
  label,
  to,
  activeOnlyWhenExact,
  children,
  target,
}: any) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <div
      className={match ? 'active' : ''}
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: -20,
          top: 0,
        }}
      >
        {match && (
          <div
            style={{
              width: 12,
              height: 12,
            }}
          >
            <RightArrow fill="#5c94f4" />
          </div>
        )}
      </div>
      <div>
        {!target ? (
          <Link
            style={
              !match
                ? {
                    textDecoration: 'none',
                    color: 'inherit',
                  }
                : {
                    textDecoration: 'none',
                    color: '#5c94f4',
                  }
            }
            to={to}
          >
            {children}
          </Link>
        ) : (
          <a
            href={to}
            target={target}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {children}
          </a>
        )}
      </div>
    </div>
  );
}

export const CustomDrawer: React.FC<any> = ({
  toggleDrawer,
  left,
  areas,
  setShowPdf,
}) => {
  const classes = useStyles();

  type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
  const sideList = (side: DrawerSide) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div
        style={{
          marginLeft: '24px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 100,
          marginTop: 20,
          fontSize: 20,
        }}
      >
        <div onClick={toggleDrawer('left', true)}>
          <BurgerIcon origin="navbar" />
        </div>
        <Logo />
      </div>

      <div className={classes.listWrapper}>
        <ListItem
          style={{
            marginBottom: 20,
          }}
        >
          <Link
            to="/home"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              opacity: 0.5,
            }}
          >
            Intelligence Areas
          </Link>
        </ListItem>
        <List className={classes.area}>
          {!!areas &&
            areas?.map((area: any) => (
              <Fragment key={area.id}>
                {area.title === `Learning` && (
                  <p
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      opacity: 0.5,
                      fontSize: 16,
                      marginLeft: 16,
                      marginBottom: 20,
                      marginTop: 20,
                      fontWeight: 'normal',
                    }}
                  >
                    Learning
                  </p>
                )}
                <ListItem
                  key={area.id}
                  style={{
                    marginBottom: 8,
                  }}
                >
                  <StyledMenuLink to={`/home/${area.id}`}>
                    {area.id === `learning` ? `Learning Listings` : area.title}
                  </StyledMenuLink>
                </ListItem>
              </Fragment>
            ))}
          <div onClick={() => setShowPdf(true)}>
            <ListItem
              style={{
                marginBottom: 8,
                cursor: 'pointer',
              }}
            >
              Learn Data Science Online
            </ListItem>
          </div>
        </List>
      </div>
    </div>
  );
  // Learning Listings
  return (
    <div>
      <Drawer
        open={left}
        onClose={toggleDrawer('left', false)}
        className={classes.root}
      >
        {sideList('left')}
      </Drawer>
    </div>
  );
};
