import React, { ReactElement, FC, useState, useRef, useEffect } from 'react';
import { ACTIONS, EVENTS, STATUS, StoreHelpers } from 'react-joyride';
import { NavBar } from '../../components/NavBar/NavBar';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { ContactUs } from '../../components/ContactUs/ContactUs';
import { useParams } from 'react-router-dom';
import { TourGuide } from './../../components/TourGuide/TourGuide';
import useLocalStorage from '../../hooks/useLocalStorage';
import {
  solutionsTourConfig,
  learningSolutionsTourConfig,
} from './solutionDetailsTips';
import { localStorageKeys } from './../../constants';

const getAskEnergyStepIndex = ({
  postedA,
  solutionCompatibility,
  benefits,
  verified,
}: any) => {
  if (!postedA) {
    return 0;
  }
  if (postedA === 'Learning') {
    if (verified) {
      return 5;
    } else {
      return 4;
    }
  } else if (postedA === 'Solution') {
    if (verified) {
      return 7;
    } else if (Object.keys(benefits).length > 0) {
      return 6;
    } else if (solutionCompatibility.length > 0) {
      return 5;
    } else {
      return 4;
    }
  }
  return 0;
};

interface IntelligenceDetailsLayoutProps {
  main: ReactElement;
  sidebar: ReactElement;
  name: string;
  jobTitle: string;
  updateAuthState: any;
  isAuthenticated: boolean;
  areas: any;
  provider: string;
  title: string;
  area?: string;
  company?: string;
  avatar?: string;
  withTourGuide: boolean;
  verificationStatus?: boolean;
  list: any;
  isTourFinished: any;
  setFinishedTour: any;
}

export const IntelligenceDetailsLayout: FC<IntelligenceDetailsLayoutProps> = ({
  main,
  sidebar,
  name,
  jobTitle,
  updateAuthState,
  isAuthenticated,
  areas,
  provider,
  title,
  company,
  avatar,
  withTourGuide,
  list: { postedA, solutionCompatibility, benefits, verified },
  isTourFinished,
  setFinishedTour,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const params: any = useParams();

  const [tourConfig, setTourConfig] = useState<any>([]);
  const [scrollingEnabled, setScrollingEnabled] = useState(false);
  const [target, setTarget] = useState(0);
  const [key, setKey] = useState(0);

  const [learningValue, setLearningValue] = useLocalStorage(
    localStorageKeys.isLearningSolutionToured,
    false,
  );
  const [solutionValue, setSolutionValue] = useLocalStorage(
    localStorageKeys.isSolutionToured,
    false,
  );

  let area: any = {};
  if (isAuthenticated) {
    area = areas?.find((item: any) => item.id === params.area) || {};
  }

  const [tourSettings, setTourSettings] = useState({
    stepIndex: 0,
    run: true,
  });

  const setAreaToured =
    area?.id === 'learning' ? setLearningValue : setSolutionValue;

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type, step } = data;
    setTarget(step.stepId);
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setTourSettings({
        ...tourSettings,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //mark tour as completed
      setAreaToured(true);
    }
    if (action === 'close') {
      setTourSettings({
        ...tourSettings,
        run: false,
      });
      helpersRef.current.skip();
      setAreaToured(true);
    }
  };

  useEffect(() => {
    if (postedA === 'Learning') {
      setTourConfig(learningSolutionsTourConfig);
      setFinishedTour(learningValue);
    } else {
      setTourConfig(solutionsTourConfig);
      setFinishedTour(solutionValue);
    }
  }, [postedA, learningValue, setFinishedTour, solutionValue]);

  const helpersRef: any = useRef();
  const setHelpers = (helpers: StoreHelpers) => {
    helpers.reset(true);
    helpersRef.current = helpers;
  };

  const handleClickRestart = () => {
    if (postedA === 'Learning') {
      setLearningValue(null);
    } else {
      setSolutionValue(null);
    }
    setKey((k) => k++);
  };

  const preAskStepId = getAskEnergyStepIndex({
    postedA,
    solutionCompatibility,
    benefits,
    verified,
  });
  useEffect(() => {
    if (target === preAskStepId || target === preAskStepId + 1) {
      setScrollingEnabled(true);
    } else {
      setScrollingEnabled(false);
    }
  }, [preAskStepId, target]);

  return (
    <div className={classes.wrapper}>
      <nav className={classes.navbar}>
        <NavBar
          withUserList={true}
          name={name}
          jobTitle={jobTitle}
          updateAuthState={updateAuthState}
          isAuthenticated={isAuthenticated}
          areas={areas}
          company={company}
          withSearch={false}
          avatar={avatar}
          resetTour={handleClickRestart}
        />
      </nav>
      <div className={classes.mainView}>
        <div className={classes.mainContent}>
          <main>
            <div className={classes.contentWrapper}>
              {main}
              <section className={classes.contactUs}>
                <ContactUs
                  isAuthenticated={isAuthenticated}
                  provider={provider}
                  title={title}
                  area={area}
                />
              </section>
            </div>
          </main>
        </div>
        <aside className={classes.sideBar}>{sidebar}</aside>
      </div>
      {!isTourFinished && withTourGuide && (
        <TourGuide
          stepsContent={tourConfig}
          scrollingEnabled={scrollingEnabled}
          handleJoyrideCallback={handleJoyrideCallback}
          tourSettings={tourSettings}
          setHelpers={setHelpers}
          key={key}
        />
      )}
    </div>
  );
};
