import React, { useState, useEffect } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { SideBarSectionTitle } from '../../SolutionSideBar/SideBarSectionTitle/SideBarSectionTitle';
import { ReactComponent as PdfIcon } from '../../../assets/media-pdf.svg';
import { ReactComponent as VideoIcon } from '../../../assets/media-video.svg';
import { ReactComponent as WordIcon } from '../../../assets/media-word.svg';
import { ReactComponent as ImageIcon } from '../../../assets/media-image.svg';
import { ReactComponent as FileIcon } from '../../../assets/media-file.svg';
import { TagNew } from '../../TagNew/TagNew';
import { SimpleBackdrop } from '../../PdfViewer/PdfViewer';

const icon: any = {
  pdf: PdfIcon,
  video: VideoIcon,
  word: WordIcon,
  image: ImageIcon,
  general: FileIcon,
  file: FileIcon,
};

let baseURL = process.env.REACT_APP_API_BASE_URL;
const localUrl = 'http://localhost:4000';

if (process.env.NODE_ENV === 'development') {
  baseURL = localUrl;
}

export const DataBox: React.FC<any> = ({
  links = [],
  dataBox,
  fullUrl,
  updateSeenStatus,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [link, setLink] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setShowPdf(false);
    setLink(null);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (link) {
      handleToggle();
      setShowPdf(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const isTypeForm = (url: any) => {
    const res = url.match(/api.typeform/) != null;
    return res;
  };

  return (
    <>
      {showPdf && (
        <SimpleBackdrop
          url={
            !isTypeForm(link) ? link : `${baseURL}/api/v1/download?url=${link}`
          }
          open={open}
          handleClose={handleClose}
        />
      )}
      {!dataBox && <SideBarSectionTitle>Related Media</SideBarSectionTitle>}
      <div className={classes.mediaWrap}>
        {links.map((link: any, i: any) => {
          const Icon = icon[link.fileType] || FileIcon;
          return (
            <div
              onClick={(e: any) => {
                if (link.type !== 'general') {
                  setLink(e.target.id);
                  !!dataBox && updateSeenStatus(link.token, link.rowNumber);
                }
              }}
              id={link.fileLink}
              data-link-id={link.fileLink}
              className={dataBox ? classes.dataBoxFileLink : classes.fileLink}
              key={link._id || link.fileLink}
              style={{
                cursor: 'pointer'
              }}
            >
              <div className={classes.providerInfo}>
                <div className={classes.icon}>
                  <Icon />
                </div>
                <div className={classes.content}>
                  <p className="title" title={link.fileName}>
                    {link.title}&nbsp;&nbsp;&nbsp;&nbsp;
                    {link.isNew && <TagNew />}
                  </p>
                  <p className="see-more">{link.fileType.toUpperCase()}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
