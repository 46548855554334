import React from 'react';
import { useTheme } from 'react-jss';

import { useStyles } from './style';

import { ReactComponent as FormatIcon } from '../../assets/format.svg';
import { ReactComponent as DurationIcon } from '../../assets/duration.svg';

interface LearningFormatProps {
  text?: string;
}

export const LearningFormat: React.FC<any> = ({
  type,
  durationType,
  duration,
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.wrapper}>
      <div className={classes.format}>
        <div className={classes.icon}>
          <FormatIcon />
        </div>
        <p className={classes.formatTitle}>Format</p>
        <p className={classes.learningType}>{type}</p>
      </div>

      <div className={`${classes.format} ${classes.duration}`}>
        <div className={classes.icon}>
          <DurationIcon />
        </div>
        <p className={classes.formatTitle}>Duration</p>
        <p className={classes.learningType}>
          {duration} {durationType}
        </p>
      </div>
    </div>
  );
};
