import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  formGroup: {
    paddingBottom: '24px',
    position: 'relative',
  },
  inputLabel: {
    opacity: 0.8,
    fontFamily: 'GoogleSans',
    fontSize: '16px',
    lineHeight: 1.25,
    color: '#ffffff',
  },
  input: {
    width: '100%',
    height: '56px',
    borderRadius: '4px',
    backgroundColor: '#282e51',
    marginTop: '8px',
    border: 'none',
    color: '#fff',
    padding: '18px 20px',
    fontFamily: 'GoogleSans',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.25',

    '&::placeholder, &::-webkit-input-placeholder, &-moz-input-placeholder,  &::-ms-input-placeholder': {
      opacity: 0.3,
      fontFamily: 'GoogleSans',
      fontSize: '16px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      color: '#ffffff',
    },

    '&:-webkit-autofill, &:-webkit-autofill:hover,  &:-webkit-autofill:focus, &:-webkit-autofill:active': {
      WebkitBoxShadow: '0 0 0 40px #282e51 inset !important',
      WebkitTextFillColor: 'white !important',
    },
  },
  error: {
    fontFamily: 'GoogleSans',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#ff8a64',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
});
