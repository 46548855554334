import React from 'react';
import { useTheme } from 'react-jss';
import { Link } from 'react-router-dom';
import { useStyles } from './style';
import { GoBack } from '../../components/GoBack/GoBack';

export const Err404: React.FC = () => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.pageWrapper}>
      <div>
        <p className={classes.Err404}>404</p>
        <p className={classes.title}>Lost in the woods.</p>
        <p className={classes.text}>
          Strange howls of nothingness <br /> it feels unknown here
        </p>

        <div className={classes.linkWrapper}>
          <Link to="/" className={classes.link}>
            <GoBack text="GO BACK" />
          </Link>
        </div>
      </div>
    </div>
  );
};
