import React from 'react';
import { useTheme } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { Tag } from '../../Tag/Tag';
import { TagNew } from '../../TagNew/TagNew';
import { ReactComponent as ChevronRight } from '../../../assets/chevron-right.svg';
import { useStyles } from './style';
import { truncateString } from '../../../helpers';

interface ListItemContentProps {
  type: string;
  title: string;
  applicationGroup: string;
  briefSummary: string;
  token: string;
  cardType: string;
  pathName: string;
  isNew: string;
}

const getTypesShortcuts = (type: string) => {
  return [type];
};

const MAX_TITLE_LENGTH = 110;

export const ListItemContent: React.FC<ListItemContentProps> = ({
  type,
  title,
  applicationGroup,
  briefSummary,
  cardType,
  pathName,
  isNew,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { state } = useLocation();

  return (
    <div className={classes.solutionCard}>
      <div className={classes.tags}>
        {getTypesShortcuts(type).map((tag) => (
          <Tag text={tag} key={tag} />
        ))}
      </div>
      <Link
        to={{
          pathname: pathName,
          state: { area: state && state.area },
        }}
        style={{
          textDecoration: 'none',
          color: 'white',
        }}
      >
        <h3 title={title} className={classes.heading}>
          {truncateString(title, MAX_TITLE_LENGTH)}&nbsp;&nbsp;&nbsp;&nbsp;
          {!!isNew && <TagNew />}
        </h3>
      </Link>
      <p className={classes.subHeading}>{applicationGroup}</p>
      <p className={classes.paragraph}>{briefSummary}</p>

      <div className={classes.actionsButtons}>
        {/* commented until favoriate btn is activated */}
        {/* <div className={classes.favoriate}>
          <FavoritesIcon
            className="mr-8"
            style={{
              position: 'relative',
              top: '-1px',
            }}
          />
          Favorites
        </div> */}

        <Link
          to={{
            pathname: pathName,
            state: { area: state && state.area },
          }}
          style={{
            textDecoration: 'none',
          }}
        >
          <div className={classes.moreDetails}>
            {cardType === 'Solution' ? 'Solution Details' : 'Learning Details'}
            <div
              style={{
                width: 13,
                height: 24,
                marginLeft: 12,
              }}
            >
              <ChevronRight
                style={{
                  verticalAlign: 'middle',
                }}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
