// Sectors news RSS
export const RSS_TO_JSON_BASE_URL = 'https://api.rss2json.com/v1/api.json';

export const OIL_GAS_NEWS_URL = encodeURIComponent(
  'https://news.google.com/rss/search?q=oil+and+gas+indsutryt&hl=en-US&gl=US&ceid=US%3Aen'
);
export const NATURALGAS_NEWS_URL = encodeURIComponent(
  'https://www.oilandgasiq.com/rss/categories/fpso-flng'
);
export const ENERGY_NEWS_URL = encodeURIComponent(
  'https://www.eia.gov/rss/todayinenergy.xml'
);
