import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  btn: {
    height: '48px',
    borderRadius: '4px',
    border: 'none',
    padding: '10px 15px',
    fontFamily: 'GoogleSans',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.11,
    letterSpacing: 'normal',
    textAlign: 'center',
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: '#d3d3d3 !important',
    cursor: 'not-allowed !important',
  },
  primary: {
    backgroundColor: '#5c94f4',
    color: '#fff',
  },
});
