import ReactGA from "react-ga";

interface IGAEventProps {
  category: string,
  action: string,
  label: string,
}

export const initGA = (trackingID: string) => {           
  ReactGA.initialize(trackingID); 
}

export const PageView = () => {
  ReactGA.pageview(
    window.location.pathname + window.location.search
  ); 
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const gAEvent = (eventProps: IGAEventProps) => {
  const { category, action, label } = eventProps
  ReactGA.event({
    category,
    action,
    label
  });
};