import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import { ReactComponent as PlusIcon } from '../../assets/plus-solid.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus-solid.svg';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <MinusIcon width="20px" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <PlusIcon width="20px" />
    </SvgIcon>
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      '& .MuiTreeItem-content': {
        backgroundColor: 'transparent !important',
        color: 'inherit !important',
      },
      '& .MuiTypography-body1': {
        fontFamily: 'inherit !important',
      },
    },
  })
);

const CustomizedTreeView: React.FC<any> = ({ children, defaultExpanded }) => {
  const classes = useStyles();
  return (
    <TreeView
      className={classes.root}
      defaultExpanded={defaultExpanded}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
    >
      {children}
    </TreeView>
  );
};

export default CustomizedTreeView;
