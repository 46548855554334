import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  wrapper: {
    width: '55%',

    '& .text-wrapper': {
      overflow: 'hidden',
      marginBottom: 20,
    },

    '& .intro-text': {
      fontSize: 16,
      lineHeight: 1.5,
      animation: '$rotate-text 1s ease-out 1 forwards',
      opacity: 0,
    },

    '& p': {
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ffffff',
      animation: '$rotate-text 1s ease-out 1 forwards',
      opacity: 0,
    },

    '& h4': {
      margin: '10px 0'
    },

    '& .company': {
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 500,
      animation: '$rotate-text 1s ease-out 1 forwards',
      opacity: 0,
    }

  },

  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    wrapper: {
      width: '95%',
    },
  },
  '@keyframes rotate-text': {
    from: {
      transform: 'rotate(30deg)',
      opacity: 1,
      transformOrigin: '-100% -100%',
    },
    to: {
      transform: 'rotate(0)',
      opacity: 1,
      transformOrigin: '-100% -100%',
    }
  },
});
