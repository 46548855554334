import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  /*******************
   *****  Header Section Styles    *******
   ****************/
  headerSection: {
    display: 'flex',
    height: 'auto',
    marginLeft: 92,
    marginBottom: 10,
  },

  goBack: {
    marginTop: 40,
    position: 'absolute',
  },

  left: {
    width: '50%',
    order: 1,
  },

  textContent: {
    width: '80%',
    marginTop: 120,
    marginBottom: 60,

    '& .title': {
      fontSize: 40,
      fontWeight: 'bold',
      lineHeight: 1.1,
      marginBottom: 44,
    },

    '& .text': {
      fontSize: 15,
      lineHeight: 1.6,
    },
  },

  newSolutionsCounter: {
    backgroundColor: ({ theme }) => theme.colors.skyBlue,
    border: '1px solid white',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: '50%',
    textAlign: 'center',
    display: 'inline-block',
    color: '#ffffff',
    lineHeight: '1.6em',
    width: '1.6em',
    boxSizing: 'content-box'
  },

  right: {
    width: '50%',
    order: 2,
    position: 'relative',
    maxHeight: 352,
    '& .image': {
      maxHeight: 352,
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  floatedBlue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ theme }) => theme.colors.bg.count,
    color: ({ theme }) => theme.colors.solutoinsCount,
    width: 140,
    height: 144,
    position: 'absolute',
    bottom: 0,
    left: '65%',

    '& .number': {
      fontSize: 40,
      fontWeight: 500,
      lineHeight: 1.1,
    },

    '& .text': {
      fontSize: 15,
      lineHeight: 1.23,
    },
  },

  startLearningButton: {
    backgroundColor: 'white',
    color: ({ theme }) => theme.colors.bg.count,
    width: 'fit-content',
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 3,
    borderRadius: 10,
    marginTop: 40,
    marginBottom: 25,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 18,
  },

  '@media (max-width: 1024px)': {
    textContent: {
      width: '90%',
      '& .title': {
        fontSize: 32,
        lineHeight: 1.3,
        marginBottom: 22,
      },

      '& .text': {
        fontSize: 15,
      },
    },
  },

  '@media (max-width: 768px)': {
    headerSection: {
      flexDirection: 'column',
      height: 'auto',
      marginLeft: 0,
      marginBottom: 0,
    },

    goBack: {
      marginTop: 16,
      position: 'absolute',
      backgroundColor: ({ theme }) => theme.colors.bg.count,
      color: ({ theme }) => theme.colors.solutoinsCount,
      zIndex: 3,
    },

    left: {
      order: 3,
      width: '90%',
      margin: '0 auto',
    },

    textContent: {
      width: '100%',
      marginTop: 20,
      '& .title': {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
      },

      '& .text': {
        fontSize: 15,
        lineHeight: 1.6,
        fontWeight: 'normal',
      },
    },

    right: {
      width: '100%',
      order: 2,
      position: 'relative',
      '& .image': {
        maxHeight: 352,
        objectFit: 'cover',
        width: '100%',
        height: 'auto',
      },
    },

    floatedBlue: {
      width: 70,
      height: 74,
      position: 'absolute',
      bottom: 0,
      left: '65%',

      '& .number': {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.1,
      },

      '& .text': {
        fontSize: 11,
        lineHeight: 1.23,
      },
    },
  },
});
