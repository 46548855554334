import React from 'react';

export const learningTourConfig = [
  {
    target: '.start-tour-tip',
    content:
      "Hello there! 👋 Welcome to a Learning Area. Allow us to show you around, Click 'Next' to move along!",
    placementBeacon: 'bottom',
    disableBeacon: true,
  },

  {
    target: '.search-filter',
    content: 'Use this bar to search and filter Learning within this Area.',
    placement: 'bottom',
    disableBeacon: true,
  },

  {
    target: '#first_step_list.first-listing-item',
    content:
      'Each LEARNING is a course, training, or certification offered by a specific learning vendor.',
    placement: 'top',
    disableBeacon: true,
    isFixed: true,
  },

  {
    title: 'FINISHED',
    target: '.finish-tour-tip',
    content:
      'You’re now familiarized with Learning listings. Select a Learning to continue the tour.',
    placementBeacon: 'bottom',
    disableBeacon: true,
  },
];

export const solutionsTourConfig = [
  {
    target: '.start-tour-tip',
    content:
      "Hello there! 👋 Welcome to an Intelligence Area. Allow us to show you around. Click 'Next' to move along!",
    placementBeacon: 'bottom',
    disableBeacon: true,
  },

  {
    target: '.search-filter',
    content: 'Use this bar to search and filter Solutions within this Area.',
    placement: 'bottom',
    disableBeacon: true,
  },

  {
    target: '#first_step_list.first-listing-item',
    content: (
      <p>
        Each SOLUTION is a product or service offered by a specific vendor. This could be a digital platform, an application, a module or
        workflow, an enterprise SaaS tool, or a service related to
        digitalization.
        <br />
        Click on Solution Details to find out more about each solution.
      </p>
    ),
    placement: 'top',
    disableBeacon: true,
    isFixed: true,
  },

  {
    title: 'FINISHED',
    target: '.finish-tour-tip',
    content:
      'You’re now familiarized with Solution listings within an Intelligence Area. Select a Solution to continue the tour.',
    placementBeacon: 'bottom',
    disableBeacon: true,
  },
];
