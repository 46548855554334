import React from 'react';
import { useTheme } from 'react-jss';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Logo } from '../Logo/Logo';
import { useStyles } from './style';
import { ListWithAvatar } from '../ListWithAvatar/ListWithAvatar';
import userPlaceholder from '../../assets/user.png';
import axios from '../../config/axios';
import { useHistory, Link } from 'react-router-dom';
import { CustomDrawer as Drawer } from '../Menu/Menu';
import { BurgerIcon } from '../BurgerIcon/BurgerIcon';
import { SearchBox } from '../SearchBox/SearchBox';
import { SimpleBackdrop } from '../PdfViewer/PdfViewer';

export const UserIcon: React.FC = () => {
  return (
    <div>
      <img
        src={userPlaceholder}
        alt=""
        style={{
          height: '44px',
        }}
      />
    </div>
  );
};

interface NavBarProps {
  withUserList: boolean;
  name?: string;
  jobTitle?: string;
  areas?: any;
  updateAuthState?: any;
  isAuthenticated?: boolean;
  company?: string;
  withSearch?: boolean;
  avatar?: string;
  resetTour?: any;
}

export const NavBar: React.FC<NavBarProps> = ({
  withUserList,
  name = '',
  jobTitle = '',
  areas = [],
  updateAuthState,
  isAuthenticated = false,
  company = '',
  withSearch = true,
  avatar = '',
  resetTour,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();

  const initialState = {
    email: '',
    currentPortal: '',
    jobTitle: '',
    loggedInBefore: '',
    name: '',
    password: '',
    portalId: '',
    role: '',
    avatar: '',
  };

  const logout = async () => {
    await axios.post('/logout');
    updateAuthState({ isAuthenticated: false, ...initialState });
    history.push('/login');
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
  const toggleDrawer = (side: DrawerSide, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (!areas || (areas && areas.length === 0)) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  //pdf viewer
  const [showPdf, setShowPdf] = React.useState(false);

  const handleClose = () => {
    setShowPdf(false);
  };

  const learningUrl = `https://firebasestorage.googleapis.com/v0/b/micro-dynamo-266610.appspot.com/o/learning-courses%2FDataCamp%20Course%20List%20-%20December%202019%20-%20Course%20List%20-%20Formatted.pdf?alt=media&token=528cac4b-90e6-43ea-8bb9-c65b464e62bf`;

  return (
    <div>
      <Drawer
        toggleDrawer={toggleDrawer}
        left={state.left}
        areas={areas}
        setShowPdf={setShowPdf}
      />
      {showPdf && (
        <SimpleBackdrop
          url={learningUrl}
          open={showPdf}
          handleClose={handleClose}
          learning={true}
        />
      )}
      <div className={classes.navbar}>
        <div
          style={{
            marginLeft: '23px',
            display: 'flex',
            alignItems: 'center',
            fontSize: 24,
            cursor: 'pointer',
          }}
        >
          <div onClick={toggleDrawer('left', true)}>
            {areas.length > 0 ? <BurgerIcon origin="navbar" /> : null}
          </div>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Logo />
          </Link>
        </div>
        <div className={classes.searchWrap}>{withSearch && <SearchBox />}</div>

        {isAuthenticated && withUserList && (
          <div className={classes.avatarInfoIconContainer}>
            <div className={classes.infoIcon} onClick={resetTour}>
              <InfoOutlinedIcon />
            </div>
            <ListWithAvatar
              title={name}
              text={jobTitle}
              company={company}
              icon={UserIcon}
              avatar={avatar}
              logout={logout}
            />
          </div>
        )}
      </div>
    </div>
  );
};
