import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
export const Logo: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <div
        className={[classes.logo, 'start-tour-tip', 'finish-tour-tip'].join(
          ' ',
        )}
      >
        <h1 className={classes.companyName}>EnergyExpert</h1>
      </div>
    </div>
  );
};
