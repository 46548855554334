import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widgetWrapper: {
    backgroundColor: ({ theme }) => theme.colors.bg.accent,
    padding: 40,
    maxWidth: 300,
    borderRadius: 4,
    width: 255,
    margin: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 25,
  },
  price: {
    fontSize: 50,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 8,
  },
  diff: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'center',
    color: ({ theme: { colors }, diffSign }) =>
      diffSign === '+' ? colors.green : colors.red,
  },
  trianglePlus: {
    marginRight: 6,
    width: 0,
    height: 0,
    borderRadius: 1,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: ({ theme }) => `10px solid ${theme.colors.green}`,
    position: 'relative',
    top: 3,
  },

  triangleMinus: {
    marginRight: 6,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: ({ theme }) => `10px solid ${theme.colors.red}`,
    position: 'relative',
    top: 3,
  },

  newsTitle: {
    fontSize: 15,
    textAlign: 'center',
  },

  newsText: {
    opacity: 0.5,
    fontSize: 10,
    textAlign: 'center',
  },

  pricePerQuantity: {
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 24,
  },

  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    widgetWrapper: {
      margin: 0,
      minWidth: 300,
      marginBottom: 8,
    },
  },
});
