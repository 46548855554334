import React, { Component } from 'react';
import Slider from 'react-slick';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

export const CustomSlide: React.FC<any> = props => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { index, newsHeadLine, link, ...rest } = props;

  return (
    <div {...rest}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: 'unset',
        }}
      >
        <p className={classes.newsText}>{newsHeadLine}</p>
      </a>
    </div>
  );
};

class NewsSlider extends Component<any, any> {
  render() {
    const { newsList } = this.props;
    const settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Slider {...settings}>
          {newsList?.map((newsHeadLine: any, i: number) => {
            return (
              <CustomSlide
                key={i}
                index={i}
                newsHeadLine={newsHeadLine.title}
                link={newsHeadLine.link}
              />
            );
          })}
        </Slider>
      </div>
    );
  }
}

export { NewsSlider };
