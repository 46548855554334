import React from 'react';
import { useTheme } from 'react-jss';

import { VerifiedBadge } from '../../VerifiedBadge/VerifiedBadge';
import { useStyles } from './style';

interface ListItemExtraDetailsProps {
  applications: string[];
  isVerified: boolean;
  subSectors: any;
}

const comparator = (a: any, b: any) => {
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;
  return 0;
};

const renderApplicationsList = (applications: string[], classes: any) => {
  const MAX_LIST_LENGTH = 4;

  let renderedApps = applications;

  if (applications.length > MAX_LIST_LENGTH) {
    renderedApps = applications.slice(0, MAX_LIST_LENGTH);
    renderedApps.push('...');
  }
  return renderedApps.map(app => {
    return (
      <p className={classes.item} key={app}>
        {app}
      </p>
    );
  });
};
const renderSectorsList = (sectors: any, classes: any) => {
  const data = Object.entries(sectors);
  const sortedSectors = data.sort(comparator);
  return sortedSectors.map(([app, subSectors]: any) => {
    return (
      <p key={app} className={classes.item}>
        {app}:{' '}
        {Object.keys(subSectors).map((sub: any, i: number, arr: any) => (
          <span className={classes.subSectors} key={sub}>
            {sub} {i < arr.length - 1 && ', '}
          </span>
        ))}
      </p>
    );
  });
};

export const ListItemExtraDetails: React.FC<ListItemExtraDetailsProps> = ({
  applications = [],
  isVerified,
  subSectors,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.moreInfo}>
      {isVerified && <VerifiedBadge isSmall={false} />}
      <div className="mt40">
        <h4 className={classes.listTitle}>Sectors</h4>
        {renderSectorsList(subSectors, classes)}
        {applications.length > 0 && (
          <>
            <h4 className={`${classes.listTitle} mt12`}>Business Sub-Areas</h4>
            {renderApplicationsList(applications, classes)}
          </>
        )}
      </div>
    </div>
  );
};
