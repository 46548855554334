import React, { useEffect } from 'react';
import { useTheme } from 'react-jss';
import { Next } from '../Next/Next';
import { useStyles } from './style';

interface ToSupport {
  content: string
}

interface IntroToSupportProps {
  company: string;
  next: () => void;
  portalId: string;
  toSupport: ToSupport;
}

export const IntroToSupport: React.FC<IntroToSupportProps> = ({
  company,
  next,
  portalId,
  toSupport
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });

  useEffect(() => {
    const timerId = setTimeout(() => {
      next();
    }, 25000);

    return function cleanup() {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <div dangerouslySetInnerHTML={{ __html: toSupport.content }} />
      <div onClick={next}>
        <Next text="Next" />
      </div>
    </div>
  );
};
