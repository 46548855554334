import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  listItem: {
    display: 'flex',
    padding: 8,
    height: 376,
    borderRadius: 8,
    backgroundColor: ({ theme, isNew }) =>
      !isNew ? theme.colors.bg.accent : theme.colors.bg.newListings,

    '& > *': {
      flex: 1,
    },
  },
  listImage: {
    '& img': {
      borderRadius: 8,
      width: '100%',
      maxWidth: 380,
      height: '100%',
      objectFit: 'cover',
    },
  },

  '@media (max-width: 1024px)': {
    listImage: {
      display: 'none',
    },
  },
  '@media (max-width: 768px)': {
    listItem: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      height: 'auto',
      borderRadius: 8,
    },
  },
});
