import React, { FC } from 'react';
import { useTheme } from 'react-jss';

import { useStyles } from './style';
import { ReactComponent as VerifiedIcon } from '../../assets/verified.svg';
import VerifiedTooltip from '../VerifiedTooltip';

interface VerifiedBadgeProps {
  isSmall: boolean;
  iconWithTooltip?: boolean;
}

export const VerifiedBadge: FC<VerifiedBadgeProps> = ({
  isSmall,
  iconWithTooltip,
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme, isSmall });

  if (iconWithTooltip) {
    return (
      <VerifiedTooltip>
        <div className={classes.badgeContainer}>
          <div className={classes.badgeCircle}>
            <div className={classes.badgeIcon}>
              <VerifiedIcon fill={theme && theme.colors.verified} />
            </div>
          </div>
        </div>
      </VerifiedTooltip>
    );
  }

  return (
    <div className={classes.badgeWrapper}>
      <div className={classes.badgeIcon}>
        <VerifiedIcon fill={theme && theme.colors.verified} />
      </div>
      <p className={classes.text}>EnergyExpert Verified</p>
    </div>
  );
};
