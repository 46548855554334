import React from 'react';
import { useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { GoBack } from '../../GoBack/GoBack';
import { useStyles } from './style';
import Skeleton from '@material-ui/lab/Skeleton';
import { SimpleBackdrop } from '../../PdfViewer/PdfViewer';
import { pluralize } from '../../../helpers';
import { CurrentArea } from '../../../interfaces';

interface ListingPageHeaderProps {
  listItemsCount: number;
  isFetchingListings: boolean;
  currentArea: CurrentArea;
  newCounter: number;
}

export const ListingPageHeader: React.FC<ListingPageHeaderProps> = ({
  listItemsCount,
  isFetchingListings,
  currentArea,
  newCounter,
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const [showPdf, setShowPdf] = React.useState(false);

  const handleClose = () => {
    setShowPdf(false);
  };

  const LearningPDFLink = `https://firebasestorage.googleapis.com/v0/b/micro-dynamo-266610.appspot.com/o/learning-courses%2FDataCamp%20Course%20List%20-%20December%202019%20-%20Course%20List%20-%20Formatted.pdf?alt=media&token=528cac4b-90e6-43ea-8bb9-c65b464e62bf`;

  return (
    <section className={classes.headerSection}>
      {showPdf && (
        <SimpleBackdrop
          url={LearningPDFLink}
          open={showPdf}
          handleClose={handleClose}
        />
      )}
      <div
        className={classes.goBack}
        onClick={() => history.push('/home')}
        style={{
          cursor: 'pointer',
          padding: '2px 8px 2px 0px',
          marginBottom: 20,
        }}
      >
        <GoBack text="Home Dashboard" />
      </div>
      <div className={classes.left}>
        <div className={classes.textContent}>
          <h2 className="title">
            {currentArea?.title}{' '}
            {!!newCounter && (
              <span className={classes.newSolutionsCounter}>
                {!!newCounter && newCounter}
              </span>
            )}
          </h2>
          <p className="text">{currentArea?.description}</p>
        </div>
      </div>
      <div className={classes.right}>
        <img
          src={currentArea.image}
          alt=""
          className="image"
          style={{ verticalAlign: 'bottom' }}
        />
        <div className={classes.floatedBlue}>
          {isFetchingListings ? (
            <Skeleton variant="rect" animation="wave" height={40} width={40} />
          ) : (
            <p className="number">{listItemsCount}</p>
          )}
          {currentArea?.title === `Learning` ? (
            <p className="text">{pluralize('Item', listItemsCount)}</p>
          ) : (
            <p className="text">{pluralize('Solution', listItemsCount)}</p>
          )}
        </div>
      </div>
    </section>
  );
};
