import React from 'react';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {},
    },
    group: {
      marginLeft: 12,
      paddingLeft: 12,
    },
  })
)((props: TreeItemProps) => <TreeItem {...props} />);

export default StyledTreeItem;
