import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

// Inspired by the Facebook spinners.
const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  top: {
    color: '#fff',
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
});

export const CustomProgressSpinner = (props: CircularProgressProps) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={24}
        thickness={4}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={24}
        thickness={4}
        {...props}
      />
    </div>
  );
};
