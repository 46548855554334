import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

export const TagNew: React.FC<any> = ({ start }) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme, start });
  return (
    <span
      style={{
        backgroundColor: theme?.colors?.bg.detailsBtn,
      }}
      className={classes.tag}
    >
      NEW
    </span>
  );
};
