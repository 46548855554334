import { createUseStyles } from 'react-jss';
import loginBg from '../../assets/login-bg.png';

export const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '50vh',
    width: 'auto',
    margin: '0 15px',
  },
  cardHeader: {
    marginBottom: '30px',
  },
  cardTitle: {
    textAlign: 'center',
  },
  cardBody: {
    borderRadius: '24px !important',
    backgroundColor: '#303865 !important',
    padding: '60px',
    paddingTop: 20,
    width: '618px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 84px)',
    background: `url(${loginBg})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  navbar: {
    height: 84,
  },
  formGroup: {
    marginBottom: '28px',
  },
  inputLabel: {
    opacity: 0.8,
    fontFamily: 'GoogleSans',
    fontSize: '16px',
    lineHeight: 1.25,
    color: '#ffffff',
  },
  input: {
    width: '100%',
    height: '56px',
    borderRadius: '4px',
    backgroundColor: '#282e51',
    marginTop: '8px',
    border: 'none',
    color: '#fff',
    padding: '18px 20px',
    fontFamily: 'GoogleSans',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.25',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  anchor: {
    fontFamily: 'GoogleSans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#5c94f4',
    textDecoration: 'none',
  },
  termsWrap: {
    display: 'block',
    fontFamily: 'GoogleSans',
    color: '#ffffff',
    marginTop: 10,
    textDecoration: 'none',
  },
  terms: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#ffffff',

    '&:hover': {
      color: '#ccc',
    }
  },
  '@media (max-width: 768px)': {
    cardBody: {
      width: '100%',
    },
  },
  '@media (max-width: 425px)': {
    cardBody: {
      padding: '30px',
    },
  },
});
