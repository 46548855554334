import React from 'react';
import { useTheme } from 'react-jss';

import { useStyles } from './style';
import { ListItemAvatar, Avatar } from '@material-ui/core';
import { ReactComponent as UserPlaceholder } from '../../assets/user-circle-solid.svg';

interface Props {
  icon: any;
  title: string;
  text: string;
  company?: string;
  avatar?: string;
  logout?: () => void;
}

export const ListWithAvatar: React.FC<Props> = ({
  icon: Icon,
  title,
  text,
  company,
  avatar,
  logout,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.providerInfo}>
      <div className={classes.content}>
        <p className="title">{title}</p>
        <p className={`see-more ${classes.mb4}`}>
          {text}
        </p>
        <p className="see-more" style={{fontWeight: "bold"}}>
          {company}
        </p>
      </div>
      <div className={classes.iconWrapper}>
        <div className={classes.icon}>
          <ListItemAvatar>
            <Avatar>
              {!!avatar ? (
                <img src={avatar} alt="" width="40px" />
              ) : (
                <UserPlaceholder width="42px" height="42px" fill="#303865" />
              )}
            </Avatar>
          </ListItemAvatar>
        </div>
        <div
          onClick={logout}
          style={{
            cursor: 'pointer',
            fontSize: 12,
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};
