import * as colors from './colors';
import * as typography from './typography';
import * as breakpoints from './breakpoints';

export const getTheme = (mode: string) => {
  if (mode === 'dark') {
    return {
      colors: { ...colors.dark },
      ...typography,
      ...breakpoints,
    };
  } else {
    return {
      colors: { ...colors.light },
      ...typography,
      ...breakpoints,
    };
  }
};
