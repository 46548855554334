import React, { Component } from 'react';
import { ListItem } from './ListItem/ListItem';
import { ListingPageHeader } from './ListingPageHeader/ListingPageHeader';
import ListItemSkeleton from '../Skeleton/ListItemSkeleton';
import { search } from '../../helpers/search';
import { BackToTop } from '../BackToTop/BackToTop';
import { SearchFilter } from './SearchFilter';
import './style.css';
import { getFiltersOptions } from '../../helpers/filters';
import { CurrentArea } from '../../interfaces';

interface ListingPageContentProps {
  listings: any[];
  isFetchingListings: boolean;
  currentArea: CurrentArea;
  filtersOptions?: any;
}

export class ListingPageContent extends Component<any, any> {
  static state: any;
  state = {
    searchTerm: '',
    filters: {},
  };

  handleSearch = (value: string) => {
    this.setState({ searchTerm: value });
  };

  doSearch = () => {
    const results: any = search(this.props.listings, this.state.searchTerm);

    return results;
  };

  handleFilterData = (type: string, value: string) => {
    this.setState((state: any) => {
      return {
        filters: { ...state.filters, [type]: value },
      };
    });
  };

  resetFilters = () => {
    this.setState({ filters: {} });
  };

  resetSubSectors = () => {
    this.setState((state: any) => {
      const { subSectors, ...rest } = state.filters;
      return {
        filters: { ...rest },
      };
    });
  };

  resetApps = () => {
    this.setState((state: any) => {
      const { applications, ...rest } = state.filters;
      return {
        filters: { ...rest },
      };
    });
  };

  render() {
    const { listings = [], isFetchingListings, currentArea } = this.props;
    const { searchTerm, filters } = this.state;
    const options = getFiltersOptions(listings);
    let lists = listings;
    let newCounter = 0;

    lists = lists.reduce((acc: any, element: any) => {
      if (element.isNew) {
        newCounter += 1;
        return [element, ...acc];
      }
      return [...acc, element];
    }, []);

    const results = this.doSearch();

    if (searchTerm) {
      lists = results;
    }

    lists = lists.filter((item: any) => {
      const res = Object.entries(filters).every((filter) => {
        if (filter[0] !== 'subSectors') {
          return filter && item[filter[0]].includes(filter[1]);
        } else {
          const filterCopy: any = filters;
          const sub = Object.keys(item?.subSectors[filterCopy.sectors]);
          const filterItem: any = filter[1];
          return filter && sub.includes(filterItem);
        }
      });
      return res;
    });

    return (
      <div>
        <ListingPageHeader
          listItemsCount={lists?.length}
          isFetchingListings={isFetchingListings}
          currentArea={currentArea}
          newCounter={newCounter}
        />
        <section className="lists">
          <SearchFilter
            searchTerm={searchTerm}
            handleSearch={this.handleSearch}
            filtersOptions={options}
            handleFilterData={this.handleFilterData}
            filters={filters}
            resetFilters={this.resetFilters}
            resetSubSectors={this.resetSubSectors}
            resetApps={this.resetApps}
          />
          <div>
            {isFetchingListings ? (
              <>
                <ListItemSkeleton />
                <ListItemSkeleton />
                <ListItemSkeleton />
              </>
            ) : (
              <>
                {lists?.map((item: any, index: number) => {
                  const itemRendered = (
                    <ListItem
                      key={item.token}
                      listing={item}
                      isFetchingListings={isFetchingListings}
                    />
                  );
                  if (index === 0) {
                    return (
                      <div id="first_step_list" className="first-listing-item" key="first_step_list">
                        {itemRendered}
                      </div>
                    );
                  }
                  return itemRendered;
                })}
                <BackToTop />
              </>
            )}
          </div>
        </section>
      </div>
    );
  }
}
