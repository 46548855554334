import React from 'react';
import { useTheme } from 'react-jss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ListItemContent } from '../ListItemContent/ListItemContent';
import { ListItemExtraDetails } from '../ListItemExtraDetails/ListItemExtraDetails';

import listNoImage from '../../../assets/no-image.jpg';
import { useStyles } from './style';

interface Props {
  listing: any;
  isFetchingListings: boolean;
  origin?: string;
}

export const ListItem: React.FC<Props> = ({ listing, origin }) => {
  const theme = useTheme();

  const { state } = useLocation();
  const { area } = useParams();
  const {
    title,
    applicationGroup,
    type,
    briefSummary,
    token,
    applications,
    displyPhotoUrl,
    verified: isVerified,
    subSectors,
    postedA,
    isNew,
  } = listing;

  const classes = useStyles({ theme, isNew });

  const SEARCH = 'search';

  const pathName =
    origin === SEARCH
      ? `/home/search-results/${token}`
      : `/home/${area}/${token}`;
  return (
    <div className={`${classes.listItem} mb40`}>
      <div className={classes.listImage}>
        <Link
          to={{
            pathname: pathName,
            state: { area: state && state.area },
          }}
          style={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          <img src={displyPhotoUrl || listNoImage} alt="" />
        </Link>
      </div>
      <ListItemContent
        title={title}
        applicationGroup={applicationGroup}
        type={type}
        briefSummary={briefSummary}
        token={token}
        cardType={postedA}
        pathName={pathName}
        isNew={isNew}
      />
      <ListItemExtraDetails
        applications={applications}
        isVerified={isVerified}
        subSectors={subSectors}
      />
    </div>
  );
};
