import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { CustomProgressSpinner } from '../../Spinner/CustomProgressSpinner';

interface CustomButtonProps {
  title: string;
  type: 'button' | 'reset' | 'submit';
  disabled: boolean;
  isLoading?: boolean;
  color?: 'primary';
}

export const Button: React.FC<CustomButtonProps> = (
  props: CustomButtonProps
) => {
  const {
    title,
    type = 'button',
    color = 'primary',
    disabled,
    isLoading,
  } = props;

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <button
      className={`${classes.btn} ${classes[color]}`}
      type={type}
      disabled={disabled}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isLoading && <CustomProgressSpinner />}
        <div
          style={{
            marginLeft: isLoading ? 8 : 0,
          }}
        >
          {title}
        </div>
      </div>
    </button>
  );
};

export default Button;
