import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },

  Err404: {
    fontSize: 22,
  },
  title: {
    fontSize: 48,
  },
  text: {
    letterSpacing: 1.4,
  },

  linkWrapper: {
    marginTop: 40,
  },

  link: {
    textDecoration: 'none',
    color: 'inheret',
  },
});
