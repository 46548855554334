import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import axios from 'axios';
import { OilPriceWidget } from './OilPriceWidget';
import {
  headers,
  LATEST_PRICE_PRINT,
  LATEST_PRICE_WTI,
  CLOSE_PRICE_PRINT,
  CLOSE_PRICE_WTI,
  calcWidgetData,
  calcGasWidgetData,
  gasPriceApiKey,
  HENRY_GAS_PRICE_API_LINK,
} from './utils';

const oilApiAxios = axios.create({
  headers,
});

export const OilPrices: React.FC = () => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });

  const [isFetchingPrices, setIsFetchingPrices] = useState(false);
  const [currentPrentPrice, setcurrentPrentPrice] = useState();
  const [closePrentPrice, setClosePrentPrice] = useState();
  const [currentWTIPrice, setcurrentWTIPrice] = useState();
  const [closeWTIPrice, setCloseWTIPrice] = useState();
  const [currentGasPrice, setCurrentGasPrice] = useState();
  const [closeGasPrice, setCloseGasPrice] = useState();
  
  useEffect(() => {
    const getOilPrices = async () => {
      const laststPrintPromise = oilApiAxios(LATEST_PRICE_PRINT);
      const getClosePricePromise = oilApiAxios(CLOSE_PRICE_PRINT);
      const getLatestWTIPromise = oilApiAxios(LATEST_PRICE_WTI);
      const closeWTIOilPricePromise = oilApiAxios(CLOSE_PRICE_WTI);
      const naturalGasPriceTodayPromise = axios.get(`${HENRY_GAS_PRICE_API_LINK}${gasPriceApiKey}`);

      setIsFetchingPrices(true);
      const [
        laststPrintRes,
        getClosePriceRes,
        getLatestWTIRes,
        closeWTIOilPriceRes,
        naturalGasPriceTodayRes,
      ] = await Promise.all([
        laststPrintPromise,
        getClosePricePromise,
        getLatestWTIPromise,
        closeWTIOilPricePromise,
        naturalGasPriceTodayPromise,
      ]);
      setIsFetchingPrices(false);

      const currentPrentOilPrice = laststPrintRes?.data?.data;
      const closePrentOilPrice = getClosePriceRes?.data?.data;
      const currentWTIOilPrice = getLatestWTIRes?.data?.data;
      const closeWTIOilPrice = closeWTIOilPriceRes?.data?.data;
      const gasPriceRecently = naturalGasPriceTodayRes.data.dataset.data[0][1];
      const gasPriceLastTime = naturalGasPriceTodayRes.data.dataset.data[1][1];

      setcurrentPrentPrice(currentPrentOilPrice);
      setClosePrentPrice(
        closePrentOilPrice?.prices[
          closePrentOilPrice?.prices.length > 1 ? 1 : 0
        ],
      );
      setcurrentWTIPrice(currentWTIOilPrice);
      setCloseWTIPrice(
        closeWTIOilPrice?.prices[closeWTIOilPrice?.prices.length > 1 ? 1 : 0],
      );

      setCurrentGasPrice(gasPriceRecently);
      setCloseGasPrice(
        gasPriceLastTime
      );
    };
    getOilPrices();
  }, []);

 
  const prentData = calcWidgetData(currentPrentPrice, closePrentPrice);
  const WTIData = calcWidgetData(currentWTIPrice, closeWTIPrice);
  const gasData = calcGasWidgetData(currentGasPrice, closeGasPrice);

  return (
    <div className={classes.wrapper}>
      <OilPriceWidget
        currentPrice={currentPrentPrice?.formatted}
        diff={prentData.diff}
        diffPercent={prentData.diffPercent}
        diffSign={prentData.diffSign}
        title="Crude Oil (Brent)"
        isFetchingPrices={isFetchingPrices}
        pricePerQuantity="per Barrel"
      />
      <OilPriceWidget
        currentPrice={currentWTIPrice?.formatted}
        diff={WTIData.diff}
        diffPercent={WTIData.diffPercent}
        diffSign={WTIData.diffSign}
        title="Crude Oil (WTI)"
        isFetchingPrices={isFetchingPrices}
        pricePerQuantity="per Barrel"
      />
      <OilPriceWidget
        currentPrice={`$${Number(currentGasPrice)}`}
        diff={gasData.diff}
        diffPercent={gasData.diffPercent}
        diffSign={gasData.diffSign}
        title="Natural Gas"
        isFetchingPrices={isFetchingPrices}
        pricePerQuantity="per Million BTU"
      />
    </div>
  );
};
