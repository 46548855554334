import React from 'react';
import TreeWrapper from '../MuTree/TreeWrapper';
import TreeItem from '../MuTree/TreeItem';
import { StyledLabel } from './TreeItemLabel';
import shortid from 'shortid';

const comparator = (a: any, b: any) => {
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;
  return 0;
};

const CustomizedTreeView: React.FC<any> = ({ subSectors }) => {
  const sectors = Object.entries(subSectors);
  const sortedSectors = sectors.sort(comparator);

  return (
    <TreeWrapper defaultExpanded={['0', '1', '2']}>
      {sortedSectors &&
        sortedSectors.map(([sector, sub]: any, i) => {
          return (
            <TreeItem
              nodeId={`${i}`}
              label={<StyledLabel sector={sector} level="top" />}
              key={i}
            >
              {sub &&
                Object.entries(sub).map(([item, ops]: any, i) => (
                  <TreeItem
                    nodeId={shortid.generate()}
                    label={<StyledLabel sector={item} level="sub" />}
                    key={i}
                  >
                    {ops &&
                      ops.map((op: any) => (
                        <TreeItem
                          nodeId={shortid.generate()}
                          label={<StyledLabel sector={op} level="sub" />}
                          key={op}
                        />
                      ))}
                  </TreeItem>
                ))}
            </TreeItem>
          );
        })}
    </TreeWrapper>
  );
};

export default CustomizedTreeView;
