import { createUseStyles } from 'react-jss';
import { rightOffset, betweenOffset } from '../constants';

const iconRightIndex = 4; // zero indexed

const sideUpStyle = {
  '0%': {
    position: 'fixed',
    top: '70px',
    right: '22.3%',
  },
  '1%': {
    transform: 'scale(0.79)',
    '-webkit-transform': 'scale(0.79)',
    zIndex: 1000,
  },
  '100%': {
    transform: 'scale(0.79)',
    '-webkit-transform': 'scale(0.79)',
    zIndex: 1000,
    position: 'fixed',
    top: '20px',
    left: `calc(100vw - ${rightOffset + iconRightIndex * betweenOffset}px)`,
  },
};

const fadeInStyle = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

export const useStyles = createUseStyles({
  wrapper: {},
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    height: 70,
    cursor: 'pointer',
  },

  icon: {
    width: 44,
    height: 44,
    marginRight: 30,
  },

  iconTitle: {
    opacity: 0,
    zIndex: 1000,
    transform: `translate(50px, 10px)`,
    position: 'fixed',
    top: '20px',
    left: `calc(100vw - ${rightOffset + iconRightIndex * betweenOffset}px)`,
    marginRight: 30,
  },

  iconWrapper: {
    width: 44,
    height: 44,
    marginRight: 17,
  },

  positionFixed: {
    position: 'fixed',
    zIndex: 1000,

    [`@media (max-width: 850px)`]: {
      position: 'unset',
    },
  },

  generalInfoContent: {
    minHeight: '75px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .title': {
      fontWeight: '500',
      lineHeight: '1.5',
      fontSize: 16,
    },

    '& .see-more': {
      fontSize: 13,
      lineHeight: 1.23,
      color: ({ theme }) => theme.colors.accent,
      '& a': {
        color: 'inherit',
      },
    },
  },
  slideUpAnimation: {},
  fadeInAnimation: {},

  '@-webkit-keyframes slideUp': sideUpStyle,
  '@-moz-keyframes slideUp': sideUpStyle,
  '@-o-keyframes slideUp': sideUpStyle,
  '@keyframes slideUp': sideUpStyle,

  '@-webkit-keyframes fadeIn': fadeInStyle,
  '@-moz-keyframes fadeIn': fadeInStyle,
  '@-o-keyframes fadeIn': fadeInStyle,
  '@keyframes fadeIn': fadeInStyle,

  '@media (min-width: 850px)': {
    slideUpAnimation: {
      '-webkit-animation': `$slideUp 0.75s ease-out forwards`,
      '-moz-animation': `$slideUp 0.75s ease-out forwards`,
      '-o-animation': `$slideUp 0.75s ease-out forwards`,
      animation: `$slideUp 0.75s ease-out forwards`,
    },
    fadeInAnimation: {
      '-webkit-animation': `$fadeIn 0.75s ease-out forwards`,
      '-moz-animation': `$fadeIn 0.75s ease-out forwards`,
      '-o-animation': `$fadeIn 0.75s ease-out forwards`,
      animation: `$fadeIn 0.75s ease-out forwards`,
    },
  },
});
