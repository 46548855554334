import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    height: 70,
    cursor: 'pointer',
  },

  icon: {
    width: 44,
    height: 44,
    marginRight: 30,
  },

  iconTitle: {
    opacity: 0,
    zIndex: 1000,
    transform: `translate(50px, 10px)`,
    position: 'fixed',
    top: '20px',
    marginRight: 30,
  },

  iconWrapper: {
    width: 44,
    height: 44,
    marginRight: 15,
  },

  positionFixed: {
    position: 'fixed',
    zIndex: 1000,

    [`@media (max-width: 850px)`]: {
      position: 'unset',
    },
  },

  generalInfoContent: {
    minHeight: '75px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .title': {
      fontWeight: '500',
      lineHeight: '1.5',
      fontSize: 16,
    },

    '& .see-more': {
      maxWidth: 185,
      fontSize: 13,
      lineHeight: 1.23,
      color: ({ theme }) => theme.colors.accent,
      '& a': {
        color: 'inherit',
      },
    },
  },
});
