import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  testimonialsSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    width: '33%',
    height: '100%',
  },

  controllers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '33%',
  },
  right: {
    display: 'inline-block',
    width: '66%',

    '& .MuiAvatar-colorDefault': {
      backgroundColor: 'transparent !important',
    },

    '& .MuiListItem-gutters': {
      paddingLeft: '0 !important',
    },
  },

  // custom Slide styles
  text: {
    fontSize: 15,
    lineHeight: 1.33,
    marginBottom: 28,
    position: 'relative',
  },
  about: {
    fontSize: 14,
    lineHeight: 1.43,
    color: ({ theme }) => theme.colors.accent,
  },
});
