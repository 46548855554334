import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  /*******************
   *****  Listing Item extra details (third panel)    *******
   ****************/
  moreInfo: {
    flex: 1,
  },

  listTitle: {
    fontSize: 14,
    lineHeight: 1.43,
    color: ({ theme }) => theme.colors.accent,
  },

  item: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 300,
  },

  subSectors: {
    color: ({ theme }) => theme.colors.accent,
  },

  '@media (max-width: 768px)': {
    moreInfo: {
      display: 'none',
    },
  },
});
