import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  badgeWrapper: {
    height: 72,
    backgroundColor: ({ theme }) => theme.colors.bg.verified,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '306px',
    padding: '0 8px',
  },

  badgeIcon: {
    marginRight: ({ isSmall }) => (isSmall ? 8 : 16),
  },

  text: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    color: ({ theme }) => theme.colors.verified,
  },

  badgeContainer: {
    marginTop: 10,
  },

  badgeCircle: {
    height: 48,
    width: 48,
    borderRadius: '100%',
    backgroundColor: ({ theme }) => theme.colors.bg.verified,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    '& > div': {
      margin: 0,
    },
  },
});
