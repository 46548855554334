import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  content: {
    display: 'flex',
    marginLeft: 20,
    flexWrap: 'wrap',
  },

  benefitItem: {
    maxWidth: 227,
    marginBottom: 30,
  },

  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '88px',
    height: '88px',
    fontSize: 30,
    lineHeight: '1.5',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    color: ({ theme }) => '#0d1333',
    backgroundColor: '#5f9afc',
  },

  circleSpace: {
    display: 'flex',
    alignItems: 'center',
    height: '88px',
    fontSize: 30,
    lineHeight: '1.5',
  },

  percent: {
    fontSize: 15,
  },

  text: {
    height: '40px',
    fontSize: '15px',
    lineHeight: '1.33',
    marginTop: '12px',
    width: '95%',
  },

  noCircle: {
    marginTop: 30,
  },
  '@media (max-width: 768px)': {
    content: {
      justifyContent: 'center',
    },
    benefitItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 15,
      minWidth: 300,
    },
  },
});
