import React, { useState } from 'react';
import Slider from 'react-slick';
import { useTheme } from 'react-jss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useStyles } from './style';

import { ReactComponent as ChevronLeft } from '../../assets/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import { ReactComponent as UserPlaceholder } from '../../assets/user-circle-solid.svg';

export const CustomSlide: React.FC<any> = props => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const {
    index,
    testimonial: { text, title, company },
    ...rest
  } = props;

  return (
    <div {...rest}>
      <div
        style={{
          padding: '15px',
        }}
      >
        <div className={classes.text}>
          <span
            style={{
              fontSize: 28,
              position: 'absolute',
              left: '-15px',
              top: -15,
              zIndex: 1000,
            }}
          >
            &ldquo;
          </span>
          <p
            style={{
              display: 'inline',
            }}
          >
            {text}
          </p>
          <span
            style={{
              fontSize: 28,
              position: 'relative',
              top: '-2px',
              marginLeft: 8,
              verticalAlign: 'top',
            }}
          >
            &rdquo;
          </span>
        </div>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <UserPlaceholder width="42px" height="42px" fill="#303865" />
              </Avatar>
            </ListItemAvatar>
            <p className={classes.about}>
              {title} - {company}
            </p>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

const SlickGoTo: React.FC<any> = props => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });

  let sliderRef = React.createRef<Slider>();
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current: any, next: any) => {
      setSlideIndex(next);
    },
  };

  const { testimonials } = props;
  const testimonialsArr: any = Object.values(testimonials);
  return (
    <div className={classes.testimonialsSection}>
      <div className={classes.controllers}>
        <h4
          style={{
            marginBottom: '50px',
            color: '#5f9afc',
          }}
        >
          Testimonials
        </h4>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 13,
            lineHeight: 1.85,
          }}
        >
          <div
            onClick={(e: any) => {
              sliderRef.current?.slickGoTo(slideIndex - 1);
            }}
            style={{
              width: 13,
              height: 24,
              marginRight: 12,
            }}
          >
            <ChevronLeft
              fill={
                testimonialsArr.length === 1 ? '#303865' : theme.colors.primary
              }
            />
          </div>
          {slideIndex + 1}/{testimonialsArr.length}
          <div
            onClick={(e: any) => {
              sliderRef.current?.slickGoTo(slideIndex + 1);
            }}
            style={{
              width: 13,
              height: 24,
              marginLeft: 12,
            }}
          >
            <ChevronRight
              fill={
                testimonialsArr.length === 1 ? '#303865' : theme.colors.primary
              }
            />
          </div>
        </div>
      </div>

      <div className={classes.right}>
        <Slider ref={sliderRef} {...settings}>
          {testimonialsArr.map((testimonial: any, i: number) => (
            <CustomSlide key={i} index={i + 1} testimonial={testimonial} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SlickGoTo;
