import React, { useEffect } from 'react';
import { useTheme } from 'react-jss';
import { Next } from '../Next/Next';
import { useStyles } from './style';

interface IntroPreparedForProps {
  name: string;
  title: string;
  company: string;
  email: string;
  loggedInBefore: string;
  next: () => void;
}

const LOGGEDIN_LOCALSTORAGE_KEY = 'loggedInBefore';

export const IntroPreparedFor: React.FC<IntroPreparedForProps> = ({
  name,
  title,
  company,
  next,
  email,
  loggedInBefore,
}) => {
  useEffect(() => {
    const timerId = setTimeout(() => {
      next();
    }, 15000);

    return function cleanup() {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function changeUserLoggedInStauts() {
      const hasLoggedInBefore = localStorage.getItem(LOGGEDIN_LOCALSTORAGE_KEY);
      if (!hasLoggedInBefore) {
        localStorage.setItem(LOGGEDIN_LOCALSTORAGE_KEY, 'true');
      }
    }

    changeUserLoggedInStauts();
  });

  const theme: any = useTheme();
  const classes = useStyles({ theme });
  return (
    <div>
      <div className={classes.textWrapper}>
        <h2 className={classes.greeting}>Hello!</h2>
      </div>
      <div className={classes.introWrapper}>
        <p className={classes.introText}>
          This EnergyExpert Intelligence Portal has been prepared for:
        </p>
      </div>
      <div className={classes.nameWrapper}>
        <h3 className={classes.name}>{name}</h3>
      </div>
      <div className={classes.titleWrapper}>
        <p className={classes.title}>{title}</p>
      </div>

      <div className={classes.textWrapper}>
        <p className={classes.company}>{company}</p>
      </div>

      <div onClick={next}>
        <Next text="Next" />
      </div>
    </div>
  );
};
