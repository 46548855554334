import React from 'react';
import { useTheme } from 'react-jss';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Logo } from '../Logo/Logo';
import { useStyles } from './style';
import { SearchBox } from '../SearchBox/SearchBox';

interface HomePageNavBarProps {
  resetTour: () => void;
}

export const HomePageNavBar: React.FC<HomePageNavBarProps> = ({
  resetTour,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.navbar}>
      <Link to="/home" style={{ textDecoration: 'none' }}>
        <Logo />
      </Link>
      <div className={[classes.searchBox, 'search-box-tip'].join(' ')}>
        <SearchBox />
      </div>
      <div onClick={resetTour}>
        <InfoOutlinedIcon />
      </div>
    </div>
  );
};
