import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  navbar: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 24,
    position: 'relative',
  },

  searchBox: {
    position: 'absolute',
    transform: 'translate(50%, 0%)',
    right: '50%',
  },

  '@media (max-width: 768px)': {
    navbar: {
      minHeight: 50,
    },
  },
});
