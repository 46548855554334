import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {},
  navbar: {
    height: 84,
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },

  mainView: {
    display: 'flex',
  },

  mainContent: {
    width: '67%',
  },

  contentWrapper: {
    margin: '40px 92px 0',
  },

  sideBar: {
    width: '33%',
    backgroundColor: '#111944',
  },

  contactUs: {},

  '@media (max-width: 768px)': {
    mainContent: {
      width: '100%',
    },

    contentWrapper: {
      margin: '0px 20px 0',
    },

    sideBar: {
      display: 'none',
    },
    contactUs: {
      display: 'none',
    },
  },
});
