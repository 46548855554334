import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  widgetWrapper: {
    backgroundColor: ({ theme }) => theme.colors.bg.accent,
    padding: '20px 30px',
    maxWidth: 200,
    flexBasis: 200,
    margin: 10,
    borderRadius: 4,
    height: 180,
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 50,
  },
  price: {
    fontSize: 60,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 15,
  },
  diff: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'center',
    color: ({ theme: { colors }, diffSign }) =>
      diffSign === '+' ? colors.green : colors.red,
    marginBottom: 30,
  },
  trianglePlus: {
    marginRight: 4,
    width: 0,
    height: 0,
    borderRadius: 1,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: ({ theme }) => `10px solid ${theme.colors.green}`,
  },

  triangleMinus: {
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: ({ theme }) => `10px solid ${theme.colors.red}`,
  },

  newsTitle: {
    fontSize: 15,
    textAlign: 'center',
    marginTop: 0,
  },

  newsText: {
    opacity: 0.7,
    fontSize: 14,
    textAlign: 'center',
    overflow: 'hidden',
    width: 140,
    height: 'auto',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },
});
