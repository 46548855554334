import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import axios from '../../config/axios';
import { IntelligenceDetailsLayout } from '../../layouts/IntelligenceDetailsLayout/IntelligenceDetailsLayout';
import { SolutionSideBar } from '../../components/SolutionSideBar/SolutionSideBar';
import { SolutionContent } from '../../components/HomeContent/SolutionContent';
import { ScrollToTopOnMount } from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import useFetch from '../../hooks/useFetch';
import { Spinner } from '../../components/Spinner/Spinner';

export const HomePage: React.FC<any> = (props) => {
  const {
    name,
    jobTitle,
    currentPortal,
    updateAuthState,
    isAuthenticated,
    avatar,
    fetchData,
  } = props;

  const [isTourFinished, setFinishedTour] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const renders = useRef(0);
  renders.current++;

  const [{ isLoading: isFetchingListings, data: list }] = useFetch(
    `/listings/${id}`,
    [],
  );
  const areas = currentPortal?.groupedAreas ? currentPortal?.groupedAreas.reduce((areas: any, group: any) => areas.concat(group.areas), []) : [];

  const { rowNumber } = list;

  useEffect(() => {
    const updateSolutionState = async () => {
      if (name && rowNumber) {
        await axios.put(`/listings/${id}`, { rowNumber, portalId: currentPortal.id });

        if (renders.current <= 3) {
          renders.current++;
          await fetchData();
        }
      }
    };
    updateSolutionState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowNumber]);

  if (isFetchingListings) {
    return <Spinner isLoading={isFetchingListings} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {list?.title ? `${list.title} | EnergyExpert` : 'EnergyExpert'}
        </title>
      </Helmet>
      <ScrollToTopOnMount />
      <IntelligenceDetailsLayout
        main={
          <SolutionContent
            {...list}
            isAuthenticated={isAuthenticated}
            areas={areas}
            company={currentPortal?.name}
            isTourFinished={isTourFinished}
          />
        }
        sidebar={
          <SolutionSideBar
            {...list}
            company={currentPortal?.name}
            isAuthenticated={isAuthenticated}
            state={state}
          />
        }
        isTourFinished={isTourFinished}
        setFinishedTour={setFinishedTour}
        name={name}
        jobTitle={jobTitle}
        updateAuthState={updateAuthState}
        isAuthenticated={isAuthenticated}
        areas={currentPortal?.areas}
        provider={list.provider}
        title={list.title}
        company={currentPortal?.name}
        avatar={avatar}
        withTourGuide
        list={list}
      />
    </>
  );
};
