export const breakPoints = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 680,
  tablet: 768,
  laptopS: 1024,
  laptopM: 1200,
  laptopL: 1440,
  desktop: 2560,
};
