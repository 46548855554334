import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { HomePage } from '../pages/Home/HomePage';
import { Listing } from '../pages/Listing/Listing';
import { PortalIntro } from '../pages/Intro/Intro';
import { SearchResults } from '../pages/SearchPage/SearchPage';
import axios from '../config/axios';

const SubRoutes: React.FC<any> = props => {
  const { portalId, name, jobTitle, updateAuthState, isAuthenticated } = props;
  const [listingsObj, setListingsObj] = useState({});
  const [isFetchingListings, setIsFetchingListings] = useState(false);

  const fetchData = async () => {
    try {
      setIsFetchingListings(true);
      const { data } = await axios.get(`/portal/${portalId}`);
      setListingsObj(data);
      setIsFetchingListings(false);
    } catch (err) {
      setIsFetchingListings(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path="/home">
        <PortalIntro
          isFetchingListings={isFetchingListings}
          {...props}
          portalAreasList={listingsObj}
          updateAuthState={updateAuthState}
          isAuthenticated={isAuthenticated}
          fetchData={fetchData}
        />
      </Route>
      <Route exact path="/home/search">
        <SearchResults
          isFetchingListings={isFetchingListings}
          {...props}
          portalAreasList={listingsObj}
          updateAuthState={updateAuthState}
          isAuthenticated={isAuthenticated}
        />
      </Route>
      <Route exact path="/home/:area">
        <Listing
          listings={listingsObj}
          isFetchingListings={isFetchingListings}
          name={name}
          jobTitle={jobTitle}
          {...props}
          updateAuthState={updateAuthState}
          isAuthenticated={isAuthenticated}
        />
      </Route>
      <Route path="/home/search-results/:id">
        <HomePage isFetchingListings={isFetchingListings} {...props} />
      </Route>
      <Route path="/home/listings/:id">
        <HomePage
          isFetchingListings={isFetchingListings}
          {...props}
          fetchData={fetchData}
        />
      </Route>
      <Route path="/home/:area/:id">
        <HomePage
          isFetchingListings={isFetchingListings}
          {...props}
          fetchData={fetchData}
        />
      </Route>
    </Switch>
  );
};

export default SubRoutes;
