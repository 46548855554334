import React from 'react';
import { Helmet } from 'react-helmet';
import { useStyles } from './style';
import { useTheme } from 'react-jss';
import { NavBar } from '../../components/NavBar/NavBar';

export const TermsPage: React.FC<any> = props => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <Helmet>
        <title>
          EnergyExpert - Terms & Conditions
        </title>
      </Helmet>
      <NavBar withUserList={false} withSearch={false} />

      <section className={classes.container}>
        <h1 className={[classes.title, classes.mainTitle].join(' ')}>EnergyExpert General User Terms</h1>
        <p>
          These General User Terms ("General User Terms" or "User Terms") are entered into and agreed to by EnergyExpert (ENERGYEXPERT.ME Limited, a company registered in United Kingdom under the laws of England and Wales, under Company Number 12314176, which includes all its affiliates and representatives, and whose registered office is located at 219 Kensington High Street, Kensington, London, W8 6BD, United Kingdom) and all users of the EnergyExpert Sites and Services ("Sites and Services") as a condition of accessing and using those Sites and Services. These General User Terms govern use of the Sites.
        </p>
        <p>
          Users to whom these General User Terms apply may include, without limitation: potential product or service buyers, product or service providers, product or service researchers, and industry professionals (each a “User” or “You”).
        </p>
        <p>
          EnergyExpert reserves the right to update or otherwise modify these General User Terms from time to time. Users are responsible for checking these General User Terms periodically for changes and updates. Your use of the Sites and Services following such posted changes and updates constitutes acceptance of such changes and updates.
          By accessing and using the sites and services, you attest that you have read, understood, and agreed to these General User Terms.In consideration of the premises set forth above, you the User hereby agree as follows:
        </p>
        <p>
          A company which offers product, goods, and/or services that are, in some capacity, referenced or characterized on the EnergyExpert website is hereby referred to as a “Vendor”.
        </p>

        <h1 className={classes.title}>Eligibility</h1>
        <p>
          The Sites and Services are intended solely for Users who are 18 years of age or older. Any access to or use of the Sites or Services by anyone under 18 is expressly prohibited. By accessing or using the Sites or Services you represent and warrant that you are 18 years of age or older.
        </p>

        <h1 className={classes.title}>Account Registration</h1>
        <p>
          [to the extent our Sites and Services require a User Account]
        </p>

        <h1 className={classes.title}>User Accounts</h1>
        <p>
          When creating a User Account, you agree to: (i) provide and keep current accurate and complete information about yourself and your company ("Registration Data"); (ii) maintain the confidentiality of your Account and log-in credentials, if applicable; and (iii) restrict access to all others. You agree to accept responsibility for all activities that occur under your Account. If we have reasonable grounds to suspect that your Account has been compromised by another person, we reserve the right to suspend or terminate your Account. All Registration Data will be stored and used in accordance with our Privacy Policy.
        </p>

        <h1 className={classes.title}>Use of Website</h1>
        <p>
          By accessing and using our Sites, you acknowledge that you are responsible for your actions. You represent and warrant, to the best of your knowledge:
        </p>

        <ul>
          <li>
            That you have all necessary right, power and authority to enter into these General User Terms and to fulfill your contractual obligations hereunder;
          </li>
          <li>
            That you will not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information of the Website or our Providers without our express written consent;
          </li>
          <li>
            That you will not avoid, bypass, reverse engineer, interfere with, deactivate, impair, descramble or otherwise circumvent any technical measure implemented by us to administer and protect our Sites and Services;
          </li>
          <li>
            That you will not use any automated means or form of data scraping or data extraction to access, query, download or otherwise collect our Content or related information from our Site (except as expressly permitted by us) or otherwise without authorization use or upload our Content; or create new links, reposts, or referrals through the use of any engine, software, tool, agent, device or mechanism (including automated scripts, spiders, robots, crawlers and data mining tools);
          </li>
          <li>
            That you will not duplicate, download, publish, modify or otherwise distribute our Content for any purpose other than for your own individual use;
          </li>
          <li>
            That you will not collect or "harvest" from our Sites the personal information ("Information") of other Users without their consent for the purpose of transmitting unsolicited commercial mass mailings, "spamming" or for any other unlawful purpose;
          </li>
          <li>
            That you will not access our Sites and Services by any means other than through interfaces expressly authorized by us and these General User Terms;
          </li>
        </ul>

        <h1 className={classes.title}>Intellectual Property Rights</h1>
        <p>
          Our Sites are comprised of Content created by us, our Partners and our Users. This section sets out the ownership and usage rights for each type of Content.
          Vendor company names listed on EnergyExpert are the service marks or trademarks of their respective companies. Copyright-protected product or service descriptions, images, marketing materials, and other related documents and media shown on EnergyExpert are owned solely by the Vendor company unless otherwise specified, and have been obtained from publicly available sources.
        </p>
        <p>
          <span className={classes.title}>Our IP: </span>
          Our Sites and all intellectual property rights therein, including without limitation the Vendor listings we create from publicly available or licensed Content, along with our Services and/or our domain names (collectively, "EnergyExpert IP"), constitute the property of EnergyExpert, its Affiliates and/or its authorized licensors, and are protected by United Kingdom and international copyright, trademark and other intellectual property laws.
        </p>
        <p>
          We grant to User a worldwide, revocable, royalty-free, non-exclusive license to use, view and download the EnergyExpert IP for User's personal, non-commercial use in accordance with these General User Termsand applicable United Kingdom and international copyright laws.
        </p>
        <p>
          Except to the extent otherwise expressly permitted under copyright law, User will not copy, reproduce, modify, use, distribute, display, create derivative works of or otherwise exploit the EnergyExpert IP without the express written consent of EnergyExpert or the applicable copyright owner.
        </p>
        <p>
          All intellectual property rights not expressly granted hereunder are expressly reserved to us.
        </p>

        <h1 className={classes.title}>Copyright Infringement Claims</h1>
        <p>
          We respect the intellectual property rights of others and will not tolerate infringing activity on its Sites.
        </p>
        <p>
          If you are a copyright owner or agent, and you believe your rights under applicable Copyright laws are being infringed by us or another person or entity using our Sites or Services, you may submit your written notification to <a href="mailto:enquire@energyexpert.me">enquire@energyexpert.me</a>, pursuant to the Digital Millennium Copyright Act ("DMCA") (17 U.S.C. § 512), which includes the following information:
        </p>

        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that has/have been allegedly infringed;
          </li>
          <li>
            Identification of the work or material being infringed, or, if multiple works are covered by a single notification, a representative list of such works;
          </li>
          <li>
            Your contact information, including phone number and e-mail address at which you may be contacted;
          </li>
          <li>
            Your good faith statement that the use of the work or material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
          </li>
          <li>
            A statement that the information in your notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of the allegedly infringed copyright.
          </li>
        </ul>
        <p>
          We reserve the right to seek damages from any person who knowingly, materially submits a DMCA notification claim under this section in violation of the law.
        </p>

        <h1 className={classes.title}>Data Protection</h1>
        <p>
          Each of us and User agrees, in fulfilling its respective obligations under these General User Terms, to comply with all applicable data privacy legislation and with the terms of our Privacy Policy, which describes how we collect, use and safeguard your personal information (Information) to administer your Services and Site experience. By communicating with us via e-mail or by submitting content, including reviews or enquiries - or any other transactional exchange on our Sites, you consent to receive e-mail or other electronic communications from us and our Affiliates. We also take Security seriously and endeavor through the use of industry standard technical, physical and operational controls to ensure that our Services and Sites are compliant with all applicable laws.
        </p>

        <h1 className={classes.title}>Representations & Warranties</h1>
        <p>
          User represents and warrants to the best of its knowledge: (i) that it has all necessary right, power and authority to enter into these General User Terms and to fulfill its contractual obligations hereunder; (ii) that the information that it uploads, posts, e-mails, transmits, or otherwise makes available to us or on our Sites, including without limitation content, reviews, screenshots, is accurate and free of third party encumbrances; (iii) that it has not breached any third party rights, including without limitation: intellectual property, publicity or privacy, consumer protection, tort and product liability rights; (iv) that it complies with all applicable security standards and is free from any viruses, including without limitation malware or Trojan horses; and (v) that it complies with all applicable laws, statutes, ordinances and regulations.<br></br>
          <span className={classes.title}>DISCLAIMER:</span> <br></br>
          Your use of our sites and services is at your own risk. To the fullest extent permitted by applicable law, our sites and services are provided on an "as is" and "as available" basis, without warranties of any kind, express or implied, including without limitation: Any implied warranties of merchantability, fitness for a particular purpose, non-infringement of third party intellectual property rights, title, custom, trade, quiet enjoyment, accuracy of informational content or system integration. We do not warrant that our sites will be secure, available or operate in an uninterrupted or error-free manner; That errors or defects will be corrected; Or that the content on our sites is accurate or appropriate for you and your business needs.
        </p>

        <h1 className={classes.title}>Limitation of Liability</h1>
        <p>
          Neither we nor User will be liable for any consequential, special, indirect, exemplary or punitive damages arising out of or in any way related to these General User Terms or Your use of our Sites and Services, including without limitation loss of profits, revenue, interest, goodwill, loss or corruption of data or other interruption of business (whether in contract, tort or under other legal theory), even if advised of the possibility of such damages.
        </p>
        <p>
          Except for breach of the indemnification and confidentiality sections hereunder, each party's aggregate liability to the other for direct damages under these General User Terms is limited to USD$100 (one hundred U.S. Dollars). We expressly disclaim liability for any and all disputes arising between Users of our Sites and Services. By accessing and using our Sites and Services, You release usand Affiliates from any and all liability for any and all claims arising from disputes between Users of our Sites and/or Services.
        </p>

        <h1 className={classes.title}>Indemnification</h1>
        <p>
          Each of us and User (in its capacity as "Indemnitor") agrees to indemnify, defend and hold harmless the other party, its agents, affiliates and employees (in its capacity as "Indemnitee") from and against any and all third party claims, liabilities, losses and expenses (including damage awards, settlement amounts and reasonable attorneys' fees) arising out of or relating to a violation of these General User Terms, except to the extent such losses and expenses arise from the negligence or willful misconduct of Indemnitee.
        </p>

        <h1 className={classes.title}>Termination</h1>
        <p><span className={classes.title}>
          Termination by Us.
        </span>
          We reserve the right in our sole discretion, without penalty and at any time without notice, to modify or discontinue (temporarily or permanently) User's access to our Sites and Services, or any part thereof if we believe that User is violating these General User Terms. If User uses or attempts to use our Sites and/or Services for any purpose that contravenes these General User Terms (including without limitation tampering, hacking, data scraping, modifying or otherwise corrupting the security or functionality of our Sites and Services), User may also be subject to civil and criminal liability.
          <span className={classes.title}>Termination for Convenience. By User.</span> User may terminate its User Account at any time by sending an email request to <a href="mailto:enquire@energyexpert.me">enquire@energyexpert.me</a>. We will endeavor to remove your User Account within 5 (five) business days. While you still may be able to access certain portions of our Sites and Services following termination of your Account, you will no longer have access to the full functionality.
          By Us: We may in our sole discretion terminate your User Account, or impose limits on or restrict your access to parts or all of our Sites and/or Services at any time, without notice or liability.
        </p>

        <h1 className={classes.title}>Force Majeure</h1>
        <p>
          Neither party will be liable for any failure or delay of performance under these General User Terms resulting from a force majeure event beyond the reasonable control of a party, including without limitation, natural disasters, acts of God, government regulations, war, terrorism, labor disputes and power failures.
        </p>

        <h1 className={classes.title}>Governing Law</h1>
        <p>
          These General User Terms are governed by the laws of United Kingdom except for its conflicts of interest principles. All claims arising out of or relating to these General User Terms will be litigated exclusively in the courts of United Kingdom and each of EnergyExpert and User consents to personal jurisdiction in those courts. Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to our Sites, Services or these General User Terms must be filed or otherwise commenced within 1 (one) year after such claim or cause of action arose or shall be forever barred.
        </p>

        <h1 className={classes.title}>Waiver and Severability</h1>
        <p>
          Our failure to act with respect to a breach of these General User Terms will not constitute a waiver and does not waive our right to act with respect to subsequent or similar breaches. If any of these General User Terms shall be deemed invalid, void, or for any reason unenforceable under applicable law, then that term shall be deemed severable and shall not affect the validity and enforceability of any remaining term or provision of these General User Terms.
        </p>

        <h1 className={classes.title}>Entire Agreement</h1>
        <p>
          These General User Terms, along with our Vendor Agreements and our Privacy Policy, constitute the entire and exclusive agreement between the parties with respect to the subject matter hereof; and supersede, replace any/or nullify any conflicting or additional terms.
        </p>
        <p>
          Policy Last Updated: January 2020
        </p>
      </section>
    </>
  );
};
