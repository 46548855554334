import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import Skeleton from '@material-ui/lab/Skeleton';

interface OilPricesWidgetProps {
  currentPrice: string;
  diff: number;
  diffPercent: any;
  diffSign: string;
  title: string;
  newsSectiontitle?: string;
  newsText?: string;
  isFetchingPrices: boolean;
  pricePerQuantity: string;
}

export default function LoadingSkeleton() {
  return (
    <div>
      <Skeleton variant="rect" width={210} height={118} />
    </div>
  );
}

export const OilPriceWidget: React.FC<OilPricesWidgetProps> = ({
  currentPrice,
  diff,
  diffPercent,
  diffSign,
  title,
  pricePerQuantity,
  isFetchingPrices,
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme, diffSign });

  const triClassName =
    diffSign === '+' ? classes.trianglePlus : classes.triangleMinus;

  return (
    <>
      {isFetchingPrices ? (
        <LoadingSkeleton />
      ) : (
          <div className={classes.widgetWrapper}>
            <h4 className={classes.title}>{title}</h4>
            <p className={classes.price}>{currentPrice}</p>
            <p className={classes.pricePerQuantity}>{pricePerQuantity}</p>
            <div className={classes.diff}>
              <div className={triClassName}></div>
              {Math.abs(diff).toFixed(2)} ( {diffSign} {Math.abs(+diffPercent).toFixed(2)}% )
            </div>
          </div>
        )}
    </>
  );
};
