import React, { Component } from "react";
import { PDFObject } from "react-pdfobject";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import Modal from "@material-ui/core/Modal";
import { breakPoints } from "../../theme/breakpoints";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    },
    registerDataCamp: {
      position: "absolute",
      bottom: 70,
      left: "10%",
      width: "30%",
      backgroundColor: "rgba(8, 12, 34, 0.9)",
      padding: 10,
      borderRadius: 5
    },
    button: {
      padding: 20,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: "#5f9afc",
      borderRadius: 5,
      margin: `10px auto`,
      width: "fit-content",
      "& a": {
        textDecoration: "none",
        color: "white",
        fontWeight: "bold"
      }
    },
    registerText: {
      padding: 10
    },
    flashIcon: {
      fontSize: 18,
      marginBottom: -4,
      marginRight: 2
    },
    [`@media (max-width: ${breakPoints.tablet}px)`]: {
      registerDataCamp: {
        width: "60%"
      }
    },
    image: {
      width: "100%",
      height: "100%",
      "&:focus": {
        outline: "none"
      }
    },
    modal: {
      width: "80vw",
      height: "90vh",
      margin: "auto"
    }
  })
);

const checkURL = (url: any) => {
  return url.match(/\.(jpeg|jpg|JPG|gif|png)$/) != null;
};

export const SimpleBackdrop = ({ url, open, handleClose, learning }: any) => {
  const classes = useStyles();
  const dataCampLink =
    "https://www.datacamp.com/?tap_a=5644-dce66f&tap_s=697635-baf21c&utm_medium=affiliate&utm_source=eliasghantous";

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        {checkURL(url) ? (
          <Modal className={classes.modal} open={true}>
            <img src={url} alt="" className={classes.image} />
          </Modal>
        ) : (
          <PdfViewer url={url} />
        )}
        {learning && (
          <div
            className={classes.registerDataCamp}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <p className={classes.registerText}>
              We've partnered with DataCamp© to help you develop data science,
              statistics, and machine learning skills.
            </p>
            <div className={classes.button}>
              <a href={dataCampLink} target="_blank" rel="noopener noreferrer">
                <FlashOnIcon fontSize={"small"} className={classes.flashIcon} />
                Start Learning
              </a>
            </div>
          </div>
        )}
      </Backdrop>
    </div>
  );
};

class PdfViewer extends Component<any, any> {
  render() {
    return (
      <div>
        <PDFObject width="80vw" height="90vh" url={this.props.url} />
      </div>
    );
  }
}

export { PdfViewer };
