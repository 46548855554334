import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import backIcon from '../../assets/left-arrow.png';

interface GoBackProps {
  text: string;
}

export const GoBack: React.FC<GoBackProps> = ({ text }) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.goBack}>
      <span className={classes.mockIcon}>
        <img src={backIcon} alt="" />
      </span>
      <span className={classes.areaName}>{text}</span>
    </div>
  );
};
