export const regions = {
  'North America': [
    'Bermuda',
    'Canada',
    'Greenland',
    'Saint Pierre and Miquelon',
    'United States of America',
  ],
  'Central America': [
    'Belize',
    'Costa Rica',
    'El Salvador',
    'Guatemala',
    'Honduras',
    'Mexico',
    'Nicaragua',
    'Panama',
  ],
  Caribbean: [
    'Anguilla',
    'Antigua and Barbuda',
    'Aruba',
    'Bahamas',
    'Barbados',
    'British Virgin Islands',
    'Cayman Islands',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'Grenada',
    'Guadeloupe',
    'Haiti',
    'Jamaica',
    'Martinique',
    'Montserrat',
    'Netherlands Antilles',
    'Puerto Rico',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Trinidad and Tobago',
    'Turks and Caicos Islands',
    'Bonaire, Sint Eustatius and Saba',
    'Curaçao',
    'Saint Barthélemy',
    'Saint Martin (French Part)',
    'Saint Vincent and the Grenadines',
    'Sint Maarten (Dutch part)',
    'United States Virgin Islands',
  ],
  'South America': [
    'Argentina',
    'Bolivia',
    'Bouvet Island',
    'Brazil',
    'Chile',
    'Colombia',
    'Ecuador',
    'Falkland Islands',
    'French Guiana',
    'Guyana',
    'Paraguay',
    'Peru',
    'South Georgia and the South Sandwich Islands',
    'Suriname',
    'Uruguay',
    'Venezuela',
  ],

  // Europe
  'Northern Europe & UK': [
    'Åland Islands',
    'Guernsey',
    'Jersey',
    'Sark',
    'Denmark',
    'Estonia',
    'Faroe Islands',
    'Finland',
    'Iceland',
    'Ireland',
    'Isle of Man',
    'Latvia',
    'Norway',
    'Lithuania',
    'Svalbard and Jan Mayen Islands',
    'Sweden',
    'United Kingdom',
  ],
  'Western Europe': [
    'Austria',
    'Belgium',
    'France',
    'Germany',
    'Liechtenstein',
    'Luxembourg',
    'Monaco',
    'Netherlands',
    'Switzerland',
  ],
  'Eastern Europe': [
    'Belarus',
    'Bulgaria',
    'Czech Republic', //Czechia
    'Hungary',
    'Moldova', //Republic of Moldova
    'Poland',
    'Romania',
    'Russia',
    'Slovakia',
    'Ukraine',
    'Kosovo',
  ],
  'Southern Europe': [
    'Albania',
    'Andorra',
    'Bosnia and Herzegovina',
    'Croatia',
    'Gibraltar',
    'Greece',
    'Holy See',
    'Italy',
    'Macedonia',
    'Malta',
    'Montenegro',
    'Portugal',
    'San Marino',
    'Serbia',
    'Slovenia',
    'Spain',
  ],

  // Africa
  'Northern Africa': [
    'Algeria',
    'Egypt',
    'Libya',
    'Morocco',
    'Sudan',
    'Tunisia',
    'Western Sahara',
  ],
  'Western Africa': [
    'Benin',
    'Burkina Faso',
    'Cabo Verde',
    'Ivory Coast',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Liberia',
    'Mali',
    'Mauritania',
    'Niger',
    'Nigeria',
    'Saint Helena',
    'Senegal',
    'Sierra Leone',
    'Togo',
  ],
  'Middle Africa': [
    'Angola',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'Democratic Republic of the Congo',
    'Republic of the Congo',
    'Equatorial Guinea',
    'Gabon',
    'Sao Tome and Principe',
  ],
  'Eastern Africa': [
    'British Indian Ocean Territory',
    'Burundi',
    'Comoros',
    'Djibouti',
    'Eritrea',
    'Ethiopia',
    'French Southern Territories',
    'Kenya',
    'Madagascar',
    'Malawi',
    'Mauritius',
    'Mayotte',
    'Mozambique',
    'Réunion',
    'Rwanda',
    'Seychelles',
    'Somalia',
    'Somaliland',
    'South Sudan',
    'United Republic of Tanzania',
    'Uganda',
    'Zambia',
    'Zimbabwe',
  ],
  'Southern Africa': [
    'Botswana',
    'Lesotho',
    'Namibia',
    'South Africa',
    'Swaziland', //Eswatini
  ],

  ////// Asia
  'Middle East - Levant': [
    'Iraq',
    'Syria',
    'Lebanon',
    'Jordan',
    'Israel',
    'Palestine',
  ],
  'Middle East - Gulf / GCC': [
    'Bahrain',
    'Kuwait',
    'Oman',
    'Qatar',
    'Saudi Arabia',
    'United Arab Emirates',
    'Yemen',
  ],
  'Central Asia': [
    'Kazakhstan',
    'Kyrgyzstan',
    'Tajikistan',
    'Turkmenistan',
    'Uzbekistan',
  ],
  'Southern Asia': [
    'Afghanistan',
    'Bangladesh',
    'Bhutan',
    'India',
    'Iran',
    'Maldives',
    'Nepal',
    'Pakistan',
    'Sri Lanka',
  ],
  'Eastern Asia': [
    'China',
    'China, Hong Kong Special Administrative Region',
    'China, Macao Special Administrative Region',
    'Taiwan',
    'Japan',
    'South Korea',
    'North Korea',
    'Mongolia',
  ],
  'South-Eastern Asia': [
    'Brunei',
    'Cambodia',
    'Indonesia',
    'Laos',
    'Malaysia',
    'Myanmar',
    'Philippines',
    'Singapore',
    'Thailand',
    'East Timor',
    'Vietnam',
  ],

  'Western Asia': ['Armenia', 'Azerbaijan', 'Cyprus', 'Georgia', 'Turkey'],

  /// Oceania
  'Australia & New Zealand': [
    'Australia',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Heard Island and McDonald Islands',
    'New Zealand',
    'Norfolk Island',
  ],
  Melanesia: [
    'Fiji',
    'New Caledonia',
    'Papua New Guinea',
    'Solomon Islands',
    'Vanuatu',
  ],
  Micronesia: [
    'Guam',
    'Kiribati',
    'Marshall Islands',
    'Micronesia (Federated States of)',
    'Nauru',
    'Northern Mariana Islands',
    'Palau',
    'United States Minor Outlying Islands',
  ],
  Polynesia: [
    'American Samoa',
    'Cook Islands',
    'French Polynesia',
    'Niue',
    'Pitcairn',
    'Samoa',
    'Tokelau',
    'Tonga',
    'Tuvalu',
    'Wallis and Futuna Islands',
  ],
  Antarctica: ['Antarctica'],
};
