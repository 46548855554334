import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';

interface SearchInputProps {
  value: string;
  handleChange: React.Dispatch<any>;
}

export const SearchInput: React.FC<SearchInputProps> = (
  props
) => {
  const {
    value, handleChange
  } = props;

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.searchBox}>
      <div>
        <SearchIcon className={classes.searchIcon} />
      </div>
      <input
        className={classes.input}
        value={value}
        type="text"
        placeholder="Search..."
        onChange={e => handleChange((e.target.value))}
      />
    </div>
  );
};
