import React, { ReactElement, useState, useRef } from 'react';
import { ACTIONS, EVENTS, STATUS, StoreHelpers } from 'react-joyride';
import { PortalIntroNavBar } from '../../components/PortalIntroNavBar/PortalIntroNavBar';
import { HomePageNavBar } from '../../components/HomePageNavBar/HomePageNavBar';
import Backdrop from '../../components/Backdrop/Backdrop';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import particlesBodyImg from '../../assets/particles-body.png';
import polygonsImg from '../../assets/polygons.png';
import { TourGuide } from './../../components/TourGuide/TourGuide';
import { tourConfig } from './homeTips';
import { localStorageKeys } from './../../constants';
import useLocalStorage from '../../hooks/useLocalStorage';

export const PortalIntroLayout: React.FC<{
  main?: ReactElement;
  withSearch?: boolean;
  withTourGuide?: boolean;
}> = ({ main, withSearch, withTourGuide }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [isTourFinished, setFinishedTour] = React.useState(false);
  const [key, setKey] = useState(0);

  const [isHomeToured, setIsHomeToured] = useLocalStorage(
    localStorageKeys.isHomeToured,
    false,
  );

  const [tourSettings, setTourSettings] = useState({
    stepIndex: 0,
    run: true,
  });

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setTourSettings({
        ...tourSettings,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //mark tour as completed
      setIsHomeToured(true);
    }
    if (action === 'close') {
      setTourSettings({
        ...tourSettings,
        run: false,
      });
      helpersRef.current.skip();
      setIsHomeToured(true);
    }
  };

  React.useEffect(() => {
    setFinishedTour(isHomeToured);
  }, [isHomeToured]);

  const helpersRef: any = useRef();
  const setHelpers = (helpers: StoreHelpers) => {
    helpers.reset(true);
    helpersRef.current = helpers;
  };

  const handleClickRestart = () => {
    setIsHomeToured(null);
    const { reset } = helpersRef.current;
    setKey((k) => k++);
    reset(true);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div>
          <nav>
            {withSearch ? (
              <HomePageNavBar resetTour={handleClickRestart} />
            ) : (
              <PortalIntroNavBar />
            )}
          </nav>
          <div>
            <main>
              <div className={classes.introScreenContent}>{main}</div>
              <Backdrop>
                <div className={classes.backgroundImageWrapper}>
                  <img
                    src={particlesBodyImg}
                    alt=""
                    className={classes.backgroundImage}
                  />
                  <img
                    src={polygonsImg}
                    alt=""
                    className={classes.polygonsImage}
                  />
                </div>
              </Backdrop>
            </main>
          </div>
        </div>
      </div>
      {!isTourFinished && withTourGuide && (
        <TourGuide
          stepsContent={tourConfig}
          handleJoyrideCallback={handleJoyrideCallback}
          tourSettings={tourSettings}
          setHelpers={setHelpers}
          key={key}
        />
      )}
    </>
  );
};
