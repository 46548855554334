import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from './config/axios';
import Routes from './routes';
import './App.css';
import { Spinner } from './components/Spinner/Spinner';
import {PageView, initGA} from './components/EnergyExpertTracking';


class App extends Component {
  state = {
    isAuthenticated: false,
    isLoading: true,
  };

  async componentDidMount() {

    let gaTrackingId: any = process.env.REACT_APP_GA_ID_PROD;

    if(process.env.REACT_APP_API_BASE_URL_PROD === window.location.host){
      gaTrackingId = process.env.REACT_APP_GA_ID_PROD;
    }else {
      gaTrackingId = process.env.REACT_APP_GA_ID_DEV
    }

    initGA(gaTrackingId);
    PageView();
    console.log(gaTrackingId);

    try {
      
      const { data: userInfo }: any = await axios.get('/is-auth');
      
      this.setState({
        isLoading: false,
        isAuthenticated: true,
        ...userInfo,
      });
      
    } catch (err) {
      this.setState({ isLoading: false, isAuthenticated: false });
      
    }
  }

  updateAuthState = (userInfo: any) => {
    this.setState({ isLoading: false, ...userInfo });
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Spinner isLoading={isLoading} />;
    }

    return (
      <div className="App">
        <Router>
          <Routes  {...this.state} updateAuthState={this.updateAuthState} />
        </Router>
      </div>
    );
  }
}

export default App;
