import React from 'react';
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

const CustomCheckbox = withStyles({})((props: CheckboxProps) => (
  <Checkbox color="default" {...props} />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      '& .MuiTypography-body1': {
        fontFamily: 'inherit !important',
      },
    },
  })
);

export const CheckboxLabels = ({ inputRef, name }: any) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.label}
      control={
        <CustomCheckbox inputRef={inputRef} name={name} color="primary" />
      }
      label="Remain anonymous to Vendor"
    />
  );
};
