import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: 17,
    width: 44,
    height: 44,
    overflow: 'hidden',
    borderRadius: '50%',
  },

  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  mb4: {
    marginBottom: 4,
  },

  content: {
    marginRight: 24,
    '& .title': {
      fontWeight: '500',
      lineHeight: '1.5',
      fontSize: 16,
    },

    '& .see-more': {
      fontSize: 13,
      lineHeight: 1.23,
      color: ({ theme }) => theme.colors.accent,
    },
  },

  '@media (max-width: 768px)': {
    content: {
      display: 'none',
      marginRight: 12,
    },
  },
});
