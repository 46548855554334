import React, { useEffect } from 'react';
import { useTheme } from 'react-jss';
import { Next } from '../Next/Next';
import { useStyles } from './style';

interface IntroDiagnosisProps {
  title: string;
  briefDiagnosis: string;
  next: () => void;
}

export const IntroDiagnosis: React.FC<IntroDiagnosisProps> = ({
  title,
  briefDiagnosis,
  next,
}) => {
  useEffect(() => {
    const timerId = setTimeout(() => {
      next();
    }, 22000);

    return function cleanup() {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme: any = useTheme();
  const classes = useStyles({ theme });
  return (
    <div>
      <div className={classes.textWrapper}>
        <h2 className={classes.company}>{title}</h2>
      </div>
      <div className={classes.textWrapper}>
        <p className={classes.briefDiagnosis} dangerouslySetInnerHTML={{__html: briefDiagnosis}}/>
      </div>
      <div onClick={next}>
        <Next text="Next" />
      </div>
    </div>
  );
};
