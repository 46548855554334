import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  wrapper: {
    margin: '0 92px',
    marginTop: 68,
  },

  introScreenContent: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 46,
    position: 'relative',
  },

  backgroundImageWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  backgroundImage: {
    width: '598px',
    height: '598px',
    position: 'absolute',
    bottom: '-299px',
    right: '0px',
    left: '0px',
    display: 'block',
    margin: 'auto',
    animation:
      '1s cubic-bezier(0.27, 0.01, 0.15, 1.02) 1 forwards $move-up, 80s 0.5s linear infinite forwards $rotate-360',
  },
  polygonsImage: {
    width: '638px',
    height: 'auto',
    position: 'absolute',
    bottom: '-299px',
    right: '0px',
    left: '0px',
    display: 'block',
    margin: 'auto',
    animation:
      '1s cubic-bezier(0.27, 0.01, 0.15, 1.02) 1 forwards $move-up-polygon, 3s 0.5s ease-out 1 forwards alternate $scall-up',
  },
  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    wrapper: {
      margin: '0 8px',
      marginTop: 10,
    },
    introScreenContent: {
      marginTop: 16,
    },
  },
  '@keyframes move-up': {
    from: { bottom: '-729px' },
    to: { bottom: '-299px' },
  },
  '@keyframes move-up-polygon': {
    from: { bottom: '-274px' },
    to: { bottom: '0' },
  },
  '@keyframes rotate-360': {
    from: {
      transform: 'rotate(0)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes scall-up': {
    from: {
      width: '638px',
    },
    to: {
      width: '688px',
    },
  },
});
