import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import axios from 'axios';
import { NewsWidget } from './NewsWidget';
import {
  RSS_TO_JSON_BASE_URL,
  ENERGY_NEWS_URL,
  OIL_GAS_NEWS_URL,
  NATURALGAS_NEWS_URL,
} from './utils';

export const NewsSection: React.FC<any> = () => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });

  const [isFetchingNews, setIsFetchingNews] = useState(false);
  const [energyNews, setEnergyNews] = useState();
  const [oilGasNews, setOilGasNews] = useState();
  const [fpsoFlngNews, setFpsoFlngNews] = useState();

  useEffect(() => {
    const getNews = async () => {
      const oilGasNewsPromise = axios(
        `${RSS_TO_JSON_BASE_URL}?rss_url=${OIL_GAS_NEWS_URL}`
      );
      const energyNewsPromise = axios(
        `${RSS_TO_JSON_BASE_URL}?rss_url=${ENERGY_NEWS_URL}`
      );
      const fpsoFlngNewsDataPromise = axios(
        `${RSS_TO_JSON_BASE_URL}?rss_url=${NATURALGAS_NEWS_URL}`
      );

      setIsFetchingNews(true);

      const [
        oilGasNewsRes,
        energyNewsRes,
        fpsoFlngNewsDataRes,
      ] = await Promise.all([
        oilGasNewsPromise,
        energyNewsPromise,
        fpsoFlngNewsDataPromise,
      ]);

      setIsFetchingNews(false);

      const oilGasNews = oilGasNewsRes?.data.items;
      const energyNews = energyNewsRes?.data?.items;
      const fpsoFlngNewsData = fpsoFlngNewsDataRes?.data?.items;

      setEnergyNews(energyNews);
      setOilGasNews(oilGasNews);
      setFpsoFlngNews(fpsoFlngNewsData);
    };
    getNews();
  }, []);

  return (
    <div>
      {isFetchingNews ? (
        <div>Loading...</div>
      ) : (
        <div className={classes.wrapper}>
          <NewsWidget news={energyNews} sectionTitle="Today in Energy" />
          <NewsWidget news={oilGasNews} sectionTitle="Oil & Gas News" />
          <NewsWidget news={fpsoFlngNews} sectionTitle="FPSO & FLNG News" />
        </div>
      )}
    </div>
  );
};
