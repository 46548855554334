import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  item: {
    marginBottom: 8,
    '& .case': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      color: '#fff',
    },
  },
});
