import React from 'react';
import { useTheme } from 'react-jss';
import classNames from 'classnames';
import { useStyles } from './style';
import TextField from '@material-ui/core/TextField';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

const useStylesInput = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#303865',
      width: '100%',
      fontWeight: 'lighter',
      fontSize: 18,
      fontFamily: 'GoogleSans',

      '& > *': {
        margin: theme.spacing(1),
        color: '#ffffff80',
        opacity: 1,
      },

      '& .MuiSvgIcon-root': {
        marginRight: 10,
        marginLeft: 10,
      },
      '& .MuiInputBase-input': {
        fontFamily: 'GoogleSans',
        fontSize: 18,
      },

      '& .MuiInput-underline:before': {
        border: 'none',
      },

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },

      '& :-webkit-autofill, & :-webkit-autofill:hover,  & :-webkit-autofill:focus, & :-webkit-autofill:active': {
        WebkitBoxShadow: '0 0 0 40px #282e51 inset !important',
        WebkitTextFillColor: 'white !important',
      },
    },
  }),
);

const iconStyles = {
  selectIcon: {
    color: '#5f9afc',
    right: 12,
  },
};

const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }: any) => {
    return (
      <ExpandMoreIcon
        {...rest}
        className={classNames(className, classes.selectIcon)}
        fontSize="small"
      />
    );
  },
);

interface SearchFilterProps {
  searchTerm: string;
  handleSearch: any;
  filtersOptions: any;
  handleFilterData: any;
  resetFilters: any;
}

export const SearchFilter: React.FC<any> = ({
  searchTerm,
  handleSearch,
  filtersOptions,
  handleFilterData,
  filters,
  resetFilters,
  resetSubSectors,
  resetApps,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const currentClasses = useStylesInput();
  const handleChange = (event: React.ChangeEvent<any>) => {
    if (event.target.name === 'sectors') {
      resetSubSectors();
      handleFilterData(event.target.name, event.target.value);
    } else if (event.target.name === 'applicationGroup') {
      resetApps();
      handleFilterData(event.target.name, event.target.value);
    } else {
      handleFilterData(event.target.name, event.target.value);
    }
  };

  const getSubsctorsOptios = () => {
    if (filtersOptions) {
      const options = filtersOptions?.subSectors;
      if (options && filters.sectors) {
        const currentOptions = options[filters.sectors];
        return currentOptions;
      }
    }
  };
  const getCurrentGroupApps = () => {
    if (filtersOptions) {
      const options = filtersOptions?.applications;
      if (options && filters.applicationGroup) {
        const currentOptions = options[filters.applicationGroup];
        return currentOptions;
      }
    }
  };

  const currentApps = getCurrentGroupApps();

  return (
    <div className={`search-filter ${classes.filterWrap}`}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          fontFamily: 'GoogleSans',
        }}
        id="back-to-top-anchor"
      >
        <TextField
          id="filled-bsic"
          placeholder="Search for something..."
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          className={currentClasses.root}
          value={searchTerm}
        />
      </div>

      <div className={classes.rightSide}>
        <Select
          name="sectors"
          defaultValue="Sectors"
          placeholder="Sectors"
          IconComponent={CustomExpandMore}
          className={classes.select}
          displayEmpty
          value={filters.sectors || ''}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            <em>Sector</em>
          </MenuItem>
          {filtersOptions &&
            Object.entries(filtersOptions?.sectors).map((option: any) => {
              return (
                <MenuItem value={option[1]} key={option}>
                  {option[1]}
                </MenuItem>
              );
            })}
        </Select>

        <Select
          name="subSectors"
          IconComponent={CustomExpandMore}
          className={classes.select}
          displayEmpty
          value={filters.subSectors || ''}
          onChange={handleChange}
          disabled={filters.sectors ? false : true}
        >
          <MenuItem value="" disabled>
            <em>Sub-sector</em>
          </MenuItem>
          {getSubsctorsOptios()?.map((option: any) => {
            return (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          name="applicationGroup"
          IconComponent={CustomExpandMore}
          className={classes.select}
          displayEmpty
          value={filters.applicationGroup || ''}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            <em>Business Area</em>
          </MenuItem>
          {filtersOptions &&
            filtersOptions.applicationGroupOptions.map((option: any) => {
              return (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              );
            })}
        </Select>

        <Select
          name="applications"
          IconComponent={CustomExpandMore}
          className={classes.select}
          displayEmpty
          value={filters.applications || ''}
          onChange={handleChange}
          disabled={filters.applicationGroup ? false : true}
        >
          <MenuItem value="" disabled>
            <em>Business Sub-Area</em>
          </MenuItem>
          {currentApps &&
            Array.from(currentApps).map((option: any) => {
              return (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              );
            })}
        </Select>

        <div className={classes.searchButton} onClick={resetFilters}>
          <p>Reset Filters</p>
        </div>
      </div>
    </div>
  );
};
