import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
  require('dotenv').config();
}

let baseURL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:4000/api/v1';
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default axiosInstance;
