export const GAS_SUB_SCTORS = [
  'Upstream / Exploration & Production',
  'Midstream & Transportation',
  'Downstream & Refining',
  'Liquefied Natural Gas (LNG)',
  'Petrochemicals',
];

export const POWER_SUB_SECTORS = [
  'Generation',
  'Transmission',
  'Trade',
  'Distribution',
  'Retail',
  'Storage',
];

export const ENERGY_SUB_SECTORS = ['Generation', 'Fuels'];

export const SECTORS: any = {
  oilGas: 'Oil & Gas',
  power: 'Power & Utilities',
  energy: 'Renewable Energy',
};

export interface IFilterOptions {
  applications: any[];
  applicationGroupOptions: any[];
  sectors: any;
  subSectors: any;
}

export const getFiltersOptions = (searchListings: any): IFilterOptions => {
  const applications: any = {};

  searchListings?.forEach((list: any) => {
    const currentAppGroup = list?.applicationGroup;
    if (!applications[currentAppGroup]) {
      applications[currentAppGroup] = new Set(list?.applications);
    } else {
      list?.applications.forEach((app: any[]) => {
        applications[currentAppGroup].add(app);
      });
    }
  });

  return {
    applications: applications,
    applicationGroupOptions: Object.keys(applications),
    sectors: SECTORS,
    subSectors: {
      'Oil & Gas': GAS_SUB_SCTORS,
      'Power & Utilities': POWER_SUB_SECTORS,
      'Renewable Energy': ENERGY_SUB_SECTORS,
    },
  };
};
