import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  lists: {
    margin: '0 92px',
    paddingTop: 10,
  },

  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 10,
    backgroundColor: '#303865',
    borderRadius: 5,
  },

  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  searchButton: {
    width: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#5c94f4',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    '& p': {
      lineHeight: 4.3,
      fontSize: 14,
      color: 'white',
      marginLeft: 15,
      fontWeight: '500',
    },
    '& img': {
      width: 18,
      height: 18,
      marginRight: 10,
    },
  },

  select: {
    width: 'auto',
    minWidth: 100,
    fontFamily: 'GoogleSans !important',
    fontSize: 10,
    fontWeight: 'lighter',
    color: '#5c94f4 !important',
    '&.MuiInputBase-root': {
      color: ({ theme }) => theme.colors.detailsBtnLight,
      lineHeight: 2.5,
      borderLeftWidth: 0.5,
      paddingLeft: 10,
      paddingRight: 10,

      '&:before': {
        display: 'none',
      },

      '& .MuiSelect-select': {
        lineHeight: 3.1,
        fontSize: '1rem',
      },
      '& .MuiSvgIcon-fontSizeSmall': {
        color: ({ theme }) => theme.colors.skyBlue,
        opacity: 0.5,
      },
      '&.MuiInput-underline:after': {
        borderBottom: '2px solid #5c94f4',
      },
    },

    searchIcon: {
      opacity: 0.5,
    },
  },

  '@media (max-width: 1000px)': {
    lists: {
      margin: '0 10px',
      height: 'auto',
    },

    filterWrap: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    },

    rightSide: {
      flexWrap: 'wrap',
    },
  },

  '@media (max-width: 768px)': {
    lists: {
      margin: '0 10px',
      height: 'auto',
    },

    searchButton: {
      borderRadius: 5,
      marginBottom: 5,

      '& p': {
        lineHeight: 3,
        marginLeft: 15,
      },
    },
  },
});
