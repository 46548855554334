import React from 'react';
import EmailInput from '../../components/Form/EmailInput/EmailInput';
import TextField from '../../components/Form/TextField/TextField';
import Button from '../../components/Form/Button/Button';
import { useTheme } from 'react-jss';
import { useStyles } from './style';

export const Form = (props: any) => {
  const {
    values: { email, password },
    errors,
    handleSubmit,
    handleChange,
    isValid,
    isSubmitting,
  } = props;

  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <form
      style={{
        width: '100%',
      }}
      onSubmit={handleSubmit}
    >
      <EmailInput
        title="Email"
        required={false}
        value={email}
        handleChange={handleChange}
        error={errors.email}
        name="email"
        placeholder="Enter your email"
      ></EmailInput>

      <TextField
        title="Password"
        required={false}
        value={password}
        handleChange={handleChange}
        error={errors.password}
        name="password"
        hidden={true}
        placeholder="Enter your password"
      ></TextField>

      <div className={classes.actions}>
        <Button
          title="Login"
          type="submit"
          disabled={!isValid || isSubmitting}
          isLoading={isSubmitting}
          color="primary"
        />
      </div>

      <span className={classes.termsWrap}>
        By continuing to EnergyExpert, you agree with these <a href="/terms" target="_blank" className={classes.terms}>User Terms</a>
      </span>
    </form>
  );
};
