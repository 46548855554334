import React, { FC, useState } from 'react';
import { useTheme } from 'react-jss';
import ReactGA from 'react-ga';
import { useStyles } from './style';
import { ReactComponent as AskIcon } from '../../../assets/ask.svg';
import AskModal from './AskModal';
import { gAEvent } from '../../../components/EnergyExpertTracking';

const askEnergyExpertData = {
  id: 2,
  icon: AskIcon,
  title: 'Ask EnergyExpert a question',
  text: 'You can remain anonymous to the vendor',
};

interface AskEnergyExpertProps {
  isAuthenticated: boolean;
  provider: string;
  title: string;
  area?: string;
  postedA: string;
  enableAnimation: boolean;
}

export const AskEnergyExpert: FC<AskEnergyExpertProps> = ({
  isAuthenticated,
  provider,
  title,
  area,
  postedA,
  enableAnimation,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    gAEvent({
      category: provider,
      action: 'Ask EnergyExpert',
      label: title,
    });
    ReactGA.set({ contentGroup1: provider, contentGroup2: title });
  };

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <div className={classes.providerInfoWrapper}>
      <AskModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        isAuthenticated={isAuthenticated}
        provider={provider}
        title={title}
        area={area}
        subText={`Find out more about this ${postedA}. You can choose whether to remain anonymous to the Vendor.`}
        showIsAnonymousCheck={true}
        success={success}
        setSuccess={setSuccess}
      />
      <div onClick={handleOpen} className={`${classes.providerInfo}`}>
        <div className={classes.iconWrapper}>
          <div
            className={`${classes.icon} ${
              enableAnimation
                ? classes.slideUpAnimation + ' ' + classes.positionFixed
                : ''
            }`}
          >
            <AskIcon />
          </div>
        </div>
        <p
          className={`${classes.iconTitle} ${
            enableAnimation ? classes.fadeInAnimation : ''
          }`}
        >
          Ask
        </p>
        <div className={`${classes.content}`}>
          <p className="title">{askEnergyExpertData.title}</p>
          <p className="see-more">{askEnergyExpertData.text}</p>
        </div>
      </div>
    </div>
  );
};
