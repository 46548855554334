import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '9px',
  },
  image: {
    width: '24px',
    height: '21px',
    backgroundColor: 'transparent',
    marginRight: '5px',
  },
  companyName: {
    color: ({ theme }) => theme.colors.primary,
    fontSize: 'inherit',
  },

  '@media (max-width: 768px)': {
    logo: {
      fontSize: 13,
      width: 25
    },
    image: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
    companyName: {
      display: 'none',
    },
  },
});
