import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { NewsSlider } from './NewsSlider';

interface NewsWidgetProps {
  news: any;
  sectionTitle: string;
}

export const NewsWidget: React.FC<NewsWidgetProps> = ({
  news,
  sectionTitle,
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.widgetWrapper}>
      <h5 className={classes.newsTitle}>{sectionTitle}</h5>
      <div className={classes.newsText}>
        <NewsSlider newsList={news} />
      </div>
    </div>
  );
};
