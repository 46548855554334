import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  burgerIcon: {},
  dash1: {
    width: '31px',
    height: '2px',
    backgroundColor: ({ origin, theme }: any) =>
      origin === 'intro' ? theme.colors.skyBlue : theme.colors.accent,
    marginBottom: '6px',
  },

  dash2: {
    width: '19px',
    height: '2px',
    backgroundColor: ({ origin, theme }: any) =>
      origin === 'intro' ? theme.colors.skyBlue : theme.colors.accent,
  },
});

interface BurgerIconProps {
  origin?: string;
}

export const BurgerIcon: React.FC<BurgerIconProps> = ({ origin = 'intro' }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, origin });
  return (
    <div className={classes.burgerIcon}>
      <div className={classes.dash1} />
      <div className={classes.dash2} />
    </div>
  );
};
