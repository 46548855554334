import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  goBack: {
    alignItems: 'baseline',
    cursor: 'pointer',
  },
  mockIcon: {
    height: '30px',
    width: '20px',
    marginRight: '18px',
    position: 'relative',
    top: '8px',
    color: ({ theme }) => theme.colors.accent,
  },
  areaName: {
    fontSize: '20px',
    fontWeight: '500',
    color: ({ theme }) => theme.colors.accent,
  },

  '@media (max-width: 768px)': {
    sector: {
      color: ({ theme }) => theme.colors.primary,
      fontSize: 15,
    },
    mockIcon: {
      color: ({ theme }) => theme.colors.primary,
      height: 20,
      width: 18,
    },
  },
});
