import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignIn from '../pages/Login/Login';
import PrivateRoute from '../components/Auth/PrivateRoute';
import LoggedoutRoute from '../components/Auth/LoggedoutRoute';
import SubRoutes from './PortalRouter';
import { HomePage } from '../pages/Home/HomePage';
import { TermsPage } from '../pages/Terms/TermsPage';
import { Err404 } from '../pages/404/404';

const Routes: React.FC<any> = props => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route
        exact
        path="/submit-your-challenge"
        render={() => {
          window.location.href = 'challengeForm/index.html';
          return null;
        }}
      />
      <LoggedoutRoute exact path="/listings/:id" {...props}>
        <HomePage />
      </LoggedoutRoute>
      <LoggedoutRoute exact path="/login" {...props}>
        <SignIn updateAuthState={props.updateAuthState} />
      </LoggedoutRoute>

      <Route exact path="/terms">
        <TermsPage />
      </Route>

      <Route path="/404">
        <Err404 />
      </Route>

      <PrivateRoute path="/home" allowedRoles={['admin', 'client']} {...props}>
        <SubRoutes {...props} />
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
