import Fuse from 'fuse.js';

export type ListingFuse = {
  title: string;
  applications: string[];
  tags: string[];
  briefSummary: string;
  digitalTechnologies: string[];
  sectors: string[];
  applicationGroup: string;
  detailedDescretpion: string;
  deployedCases: string[];
};

export const searchOptions: Fuse.FuseOptions<ListingFuse> = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 30,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'title',
      weight: 0.9,
    },
    {
      name: 'applications',
      weight: 0.8,
    },
    {
      name: 'briefSummary',
      weight: 0.7,
    },
    {
      name: 'digitalTechnologies',
      weight: 0.6,
    },
    {
      name: 'sectors',
      weight: 0.5,
    },
    {
      name: 'applicationGroup',
      weight: 0.4,
    },
    {
      name: 'detailedDescretpion',
      weight: 0.3,
    },
    {
      name: 'deployedCases',
      weight: 0.2,
    },
    {
      name: 'tags',
      weight: 0.1,
    },
  ],
};

export const search = (
  listings: ListingFuse[] = [],
  searchTerm: string
): any => {
  const fuse = new Fuse(listings, searchOptions);
  const results: any = fuse.search(searchTerm);
  return results;
};
