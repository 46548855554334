import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    marginBottom: 72,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  format: {
    width: '45%',
    borderRadius: 4,
    backgroundColor: '#303865',
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    minWidth: 300,
    marginBottom: 30,
  },

  icon: {
    alignSelf: 'flex-end',
  },

  formatTitle: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.33,
    color: '#5f9afc',
  },

  learningType: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.2,
  },

  duration: {
    width: '30%',
  },
  '@media (max-width: 1200px)': {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
});
