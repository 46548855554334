import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { ReactComponent as SectorsIcon } from '../../assets/sectors.svg';
import { ReactComponent as AppGroupsIcon } from '../../assets/application-groups.svg';
import SectorsTree from './SectorsTree';
import ApplicationsTree from './ApplicationsTree';

interface SectorListProps {
  applicationGroup: any;
  applications: string[];
  subSectors: any;
  isTourFinished: any;
}

export const SectorList: React.FC<SectorListProps> = ({
  applicationGroup,
  applications = [],
  subSectors = {},
  isTourFinished,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, isTourFinished });

  return (
    <div className={classes.content}>
      <div className={classes.lists}>
        <div className={classes.icon}>
          <SectorsIcon />
        </div>
        <div className={classes.sectors}>
          <p
            style={{
              marginBottom: '15px',
            }}
            className="header-sectors-title"
          >
            <span className="sectors-tip">Sectors</span>
          </p>
          <SectorsTree subSectors={subSectors} />
        </div>
      </div>

      <div className={classes.lists}>
        <div className={classes.icon}>
          <AppGroupsIcon />
        </div>
        <div className={classes.sectors}>
          <p
            style={{
              marginBottom: '15px',
            }}
            className="title-applications"
          >
            <span id="applications-tip" className="applications-tip">
              Business Areas
            </span>
          </p>
          <ApplicationsTree
            applicationGroup={applicationGroup}
            applications={applications}
          />
        </div>
      </div>
    </div>
  );
};
