import { createUseStyles } from 'react-jss';
import { rightOffset, betweenOffset } from '../constants';

const iconRightIndex = 3; // zero indexed

const sideUpStyle = {
  '0%': {
    position: 'fixed',
    top: '170px',
    right: '22.3%',
  },
  '1%': {
    transform: 'scale(0.79)',
    zIndex: 1000,
  },
  '100%': {
    transform: 'scale(0.79)',
    zIndex: 1000,
    position: 'fixed',
    top: '20px',
    left: `calc(100vw - ${rightOffset + iconRightIndex * betweenOffset}px)`,
  },
};

const fadeInStyle = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

export const useStyles = createUseStyles({
  requestDemo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    height: 70,
    cursor: 'pointer',
    position: 'relative',
  },

  disableRequestDemo: {
    pointerEvents: 'none',
  },

  icon: {
    width: 44,
    height: 44,
    marginRight: 30,
  },

  iconTitle: {
    opacity: 0,
    zIndex: 1000,
    transform: `translate(50px, 10px)`,
    position: 'fixed',
    top: '20px',
    left: `calc(100vw - ${rightOffset + iconRightIndex * betweenOffset}px)`,
    marginRight: 30,
  },

  iconWrapper: {
    width: 44,
    height: 44,
    marginRight: 17,
  },

  positionFixed: {
    position: 'fixed',
    zIndex: 1000,

    [`@media (max-width: 850px)`]: {
      position: 'unset',
    },
  },

  content: {
    '& .title': {
      fontWeight: '500',
      lineHeight: '1.5',
      fontSize: 16,
    },

    '& .see-more': {
      fontSize: 13,
      lineHeight: 1.23,
      color: ({ theme }) => theme.colors.accent,
      '& a': {
        color: 'inherit',
      },
    },
  },

  slideUpAnimation: {},
  fadeInAnimation: {},

  '@-webkit-keyframes slideUp': sideUpStyle,
  '@-moz-keyframes slideUp': sideUpStyle,
  '@-o-keyframes slideUp': sideUpStyle,
  '@keyframes slideUp': sideUpStyle,

  '@-webkit-keyframes fadeIn': fadeInStyle,
  '@-moz-keyframes fadeIn': fadeInStyle,
  '@-o-keyframes fadeIn': fadeInStyle,
  '@keyframes fadeIn': fadeInStyle,

  '@media (min-width: 850px)': {
    slideUpAnimation: {
      '-webkit-animation': `$slideUp 0.75s ease-out forwards`,
      '-moz-animation': `$slideUp 0.75s ease-out forwards`,
      '-o-animation': `$slideUp 0.75s ease-out forwards`,
      animation: `$slideUp 0.75s ease-out forwards`,
    },
    fadeInAnimation: {
      '-webkit-animation': `$fadeIn 0.75s ease-out forwards`,
      '-moz-animation': `$fadeIn 0.75s ease-out forwards`,
      '-o-animation': `$fadeIn 0.75s ease-out forwards`,
      animation: `$fadeIn 0.75s ease-out forwards`,
    },
  },
  laptopIcon: {
    width: 44,
    height: 44,
    '& > svg': {
      maxWidth: '90%',
      maxHeight: '90%',
    },
  },
  processAlert: {
    width: '100%',
    maxWidth: 320,
    padding: '5px 10px 5px 5px',
  },
  successAlert: {
    '& .MuiAlert-filledSuccess': {
      backgroundColor: '#5a8fec',
    },
  },
  processAlertContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '2%',
    left: '0',
    right: '0',
  },
  hideProcessAlert: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s 3s, opacity 2s linear',
  },
  errorMsg: {
    color: 'red',
    position: 'absolute',
    left: '61px',
    bottom: '-15px',
  },
});
