import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { Link } from 'react-router-dom';
import { useStyles } from './style';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import { SimpleBackdrop } from '../PdfViewer/PdfViewer';
import { OilPrices } from '../OilPrices/OilPrices';
import { NewsSection } from '../RssNewsFeed/SectorNews';
import { DataBox } from './DataBox/DataBox';
import axios from './../../config/axios';
import { LookingForMoreModal } from './LookingForMoreModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pluralize } from '../../helpers';

interface IntroAreasProps {
  company: string;
  portalAreasList: any;
  groupedAreas: any;
  portalId: any;
  next: () => void;
  fetchData: () => void;
  isFetchingListings: boolean;
}

const LEARNING_TYPE = 'Learning';

export const IntroAreas: React.FC<IntroAreasProps> = ({
  company,
  groupedAreas = [],
  portalAreasList,
  portalId,
  fetchData,
  isFetchingListings,
}) => {
  const [showPdf, setShowPdf] = React.useState(false);

  const handleClose = () => {
    setShowPdf(false);
  };

  const learningUrl = `https://firebasestorage.googleapis.com/v0/b/micro-dynamo-266610.appspot.com/o/learning-courses%2FDataCamp%20Course%20List%20-%20December%202019%20-%20Course%20List%20-%20Formatted.pdf?alt=media&token=528cac4b-90e6-43ea-8bb9-c65b464e62bf`;

  const theme: any = useTheme();
  const classes = useStyles({ theme });

  let learning: any = null;
  const filteredGroupedAreas: any[] = [];
  
  groupedAreas.forEach((group: any, groupIndex: number) => {
    filteredGroupedAreas.push({
      title: group.title ?? '',
      id: group.id ?? '',
      areas: [],
    });
    group.areas.forEach((area: any, index: number) => {
      if (area.title === LEARNING_TYPE) {
        learning = area;
        // group.areas.splice(index, 1);
      } else {
        filteredGroupedAreas[groupIndex].areas.push(area);
      }
    });
  });

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSuccess(false);
  };

  const updateSeenStatus = async (id: string, rowNumber: any) => {
    try {
      await axios.put(`/listings/${id}`, { rowNumber, portalId });
    } catch (error) {
      console.log('err', error);
    }
  };

  return (
    <div className={classes.wrapper}>
      {showPdf && (
        <SimpleBackdrop
          url={learningUrl}
          open={showPdf}
          handleClose={handleClose}
          learning={true}
        />
      )}

      {open && (
        <LookingForMoreModal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleCloseModal}
          isAuthenticated={true}
          subText="Tell us about a new area you would like to explore. More knowledge, more power!"
          success={success}
          setSuccess={setSuccess}
        />
      )}

      <p className={classes.introTitle}>
        <span className="intelligence-areas-tip">Intelligence Areas</span>
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 24,
        }}
      >
        <p onClick={handleOpen} className={classes.lookingForMore}>
          <strong
            style={{
              fontWeight: 700,
            }}
          >
            Looking for more?
          </strong>{' '}
          Ask EnergyExpert to explore a new area
        </p>
      </div>
      {filteredGroupedAreas?.map((group: any) => (
        <div className={classes.groupArea} key={group.id}>
          {group.title && <p className={classes.groupTitle}>
            <span className="group-title">{group.title}</span>
            <span className={classes.groupCount}>{group.areas?.length} {pluralize('Area', group.areas?.length)}</span>
          </p>
          }
          <div className={classes.areasGrid}>
            {group.areas?.map((area: any) => (
              <div className={classes.area} key={area.id}>
                <Link
                  to={{
                    pathname: `/home/${area.id}`,
                    state: { area: area.title },
                  }}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <div className={classes.icon}>
                    <img src={area.icon} alt="" className={classes.iconImage} />
                    {!!portalAreasList.count && portalAreasList.count[area.id] > 0 && (
                      <div className={classes.tooltip}>
                        <p>{portalAreasList.count[area.id]}</p>
                        <span className={classes.tooltiptext}>
                          {portalAreasList.count[area.id] > 1
                            ? `new solutions`
                            : `new solution`}
                        </span>
                      </div>
                    )}
                  </div>
                  <h3 className={classes.areaName}>{area.title}</h3>
                  <p className={classes.areaShortDesc}>{area.shortDescription}</p>
                </Link>
              </div>
            ))}
            </div>
        </div>
      ))}
      {learning && (
        <>
          <p className={[classes.introTitle, classes.marginsTB].join(' ')}>
            <span className="learning-tip">Learning</span>
          </p>
          <div className={classes.areasGrid}>
            <div className={classes.area} key={learning.id}>
              <Link
                to={{
                  pathname: `/home/${learning.id}`,
                  state: { area: learning.title },
                }}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <div className={classes.icon}>
                  <img src={learning.icon} alt="" className={classes.iconImage} />
                  {!!portalAreasList.count && portalAreasList.count.learning > 0 && (
                    <div className={classes.tooltip}>
                      <p>{portalAreasList.count.learning}</p>
                      <span className={classes.tooltiptext}>
                        {portalAreasList.count.learning > 1
                          ? `new learning`
                          : `new learning`}
                      </span>
                    </div>
                  )}
                </div>
                <h3 className={classes.areaName}>Learning Listings</h3>
                <p className={classes.areaShortDesc}>{learning.shortDescription}</p>
              </Link>
            </div>
            <div
              onClick={() => setShowPdf(true)}
              className={[classes.area, classes.pointer].join(' ')}
            >
              <div className={classes.icon}>
                <LaptopWindowsIcon className={classes.iconImage} />
              </div>
              <h3 className={classes.areaName}>Learn Data Science Online</h3>
              <p className={classes.areaShortDesc}>
                We've Partnered with DataCamp&copy; to help you develop data
                science, statistics, and machine learning skills.{' '}
              </p>
            </div>
          </div>
        </>
      )}
      <div className={classes.marketSnapshotWrapper}>
        <h2 className={classes.marketSanpshotTitle}>
          <span className="market-snapshot-tip">Market Snapshot</span>
        </h2>
        <div className={classes.oilPricesSection}>
          <OilPrices />
        </div>

        <div>
          <NewsSection />
        </div>

        {isFetchingListings ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div>
            {!!portalAreasList.dataBox && portalAreasList.dataBox.length > 0 && (
              <div>
                <p className={classes.dataBox}>
                  <span className="data-box-tip">Databox</span>
                  {!!portalAreasList.count &&
                    portalAreasList.count.dataBox > 0 && (
                      <span className={classes.counter}>
                        {portalAreasList.count.dataBox}
                      </span>
                    )}
                </p>

                <div>
                  <div
                    className={`${classes.sideContentWrapper} ${classes.deployedCases}`}
                  >
                    <DataBox
                      links={portalAreasList.dataBox}
                      dataBox={true}
                      fullUrl={true}
                      updateSeenStatus={updateSeenStatus}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{ display: 'none' }}>
        <iframe
          title="news-bar"
          src="https://boereport.com/widgets/ticker.html"
          style={{
            height: 42,
            width: '100%',
            border: 'none',
          }}
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};
