export const typography = {
  font: {
    heading: `GoogleSans, 'Times New Roman', 'sans-serif'`,
    body: `GoogleSans,'Times New Roman', 'sans-serif'`,
  },
  size: {
    xs: '16px',
    s: '20px',
    s_md: '25px',
    md: '30px',
    md_lg: '38.55px',
    lg: '40px',
    xl: '50px',
    xxl: '90px',
  },
};
