import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  textWrapper: {
    overflow: 'hidden',
    marginBottom: 16,
  },
  company: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.14,
    color: '#5f9afc',
    animation: '$rotate-text 1s ease-out 1 forwards',
    opacity: 0,
  },
  briefDiagnosis: {
    width: '60%',
    margin: '0 auto',
    fontSize: 16,
    lineHeight: 1.5,
    color: '#ffffff',
    animation: '$rotate-text 1s ease-out 1 0.3s forwards',
    opacity: 0,
  },

  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    briefDiagnosis: {
      width: '95%',
    },
  },
  '@keyframes rotate-text': {
    from: {
      transform: 'rotate(30deg)',
      opacity: 1,
      transformOrigin: '-50% -100%',
    },
    to: {
      transform: 'rotate(0)',
      opacity: 1,
      transformOrigin: '-50% -100%',
    }
  },
});
