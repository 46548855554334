import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { ReactComponent as LaptopIcon } from '../../../assets/laptopIcon.svg';
import ActionModal from './ActionModal/index';
import SuccessAlert from '../../Alert/SuccessAlert';
import axiosInstance from '../../../config/axios';
import ErrorAlert from '../../Alert/ErrorAlert';

const requestDemoData = {
  id: 1,
  title: 'Request a Demo',
  text: 'Request a live demonstration of this solution with the Vendor',
};

interface RequestDemoProps {
  enableAnimation: boolean;
  isAuthenticated: boolean;
  solutionProvider: string;
  solutionTitle: string;
}

export const RequestDemo: React.FC<RequestDemoProps> = ({
  enableAnimation,
  isAuthenticated,
  solutionProvider,
  solutionTitle,
}) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDemoRequested, setIsDemoRequested] = useState({
    status: false,
    success: false,
    error: false,
  });

  //appends classes.hideSuccessAlert
  const [hideProcessAlert, setHideProcessAlert] = useState(false);

  const callBackendApi = (endpoint: string, payload: any) => {
    setIsDemoRequested({ ...isDemoRequested, status: true });

    axiosInstance
      .post(endpoint, payload)
      .then((response) => {
        setIsDemoRequested({ status: false, success: true, error: false });
      })
      .catch((error) => {
        setIsDemoRequested({ status: false, success: false, error: true });
      })
      .finally(() => {
        setTimeout(() => {
          setHideProcessAlert(true);
        }, 3000);
      });
  };

  const handleRequestDemoClick = () => {
    setIsDemoRequested({ ...isDemoRequested, success: false, error: false });
    setHideProcessAlert(false);
    if (isAuthenticated) {
      callBackendApi('/request-demo', {
        provider: solutionProvider,
        title: solutionTitle,
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const hideAlert = hideProcessAlert ? classes.hideProcessAlert : '';
  const disableRequestDemo = isDemoRequested.status
    ? classes.disableRequestDemo
    : '';

  return (
    <>
      <div
        onClick={handleRequestDemoClick}
        className={`${`${classes.requestDemo} ${disableRequestDemo}`}`}
      >
        <div className={classes.iconWrapper}>
          <div
            className={`${classes.icon} ${
              enableAnimation
                ? classes.slideUpAnimation + ' ' + classes.positionFixed
                : ''
            }`}
          >
            <LaptopIcon className={classes.laptopIcon} />
          </div>
        </div>
        <p
          className={`${classes.iconTitle} ${
            enableAnimation ? classes.fadeInAnimation : ''
          }`}
        >
          Demo
        </p>
        <div className={`${classes.content}`}>
          <p className="title">{requestDemoData.title}</p>
          <p className="see-more">{requestDemoData.text}</p>
        </div>
      </div>

      <ActionModal
        isAuthenticated={false}
        open={isModalOpen}
        handleClose={handleCloseModal}
        title={solutionTitle}
        provider={solutionProvider}
        isRequestDemoForm={true}
      />
      {(isDemoRequested.success || isDemoRequested.error) && (
        <div className={`${classes.processAlertContainer} ${hideAlert}`}>
          {isDemoRequested.success &&
            <div className={`${classes.processAlert} ${classes.successAlert}`}>
              <SuccessAlert msg="Success! Demo has been requested for this solution. The EnergyExpert team will get back to you shortly" />
            </div>
          }
          {isDemoRequested.error &&
            <div className={classes.processAlert}>
              <ErrorAlert msg="something went wrong. please try again later." />
            </div>
          }
        </div>
      )}
    </>
  );
};
