import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, JssProvider } from 'react-jss';
import { createGlobalStyle } from './global.style';
import HttpsRedirect from 'react-https-redirect';
import {
  ThemeProvider as MUThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';

import { getTheme } from './theme';

const muTheme = createMuiTheme({
  typography: {
    fontFamily: `GoogleSans, 'Times New Roman', 'sans-serif'`,
  },
});

const themeMode = 'dark';
const theme = getTheme(themeMode);
const sheets = createGlobalStyle(theme);

const ThemedApp = () => (
  <MUThemeProvider theme={muTheme}>
    <ThemeProvider theme={theme}>
      <JssProvider registry={sheets}>
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </JssProvider>
    </ThemeProvider>
  </MUThemeProvider>
);

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
