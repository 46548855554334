import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  contactUs: {
    height: 288,
    position: 'relative',
    marginBottom: 81,
    backgroundColor: '#303865',
    borderRadius: 8,
  },

  textContent: {
    paddingTop: '56px',
    paddingLeft: '56px',
  },

  lookingForSomething: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.2,
    marginBottom: 10,
  },

  contactSupportTeam: {
    fontSize: 15,
    lineHeight: 1.33,
    color: ({ theme }) => '#5f9afc',
    marginTop: 8,
    cursor: 'pointer'
  },

  image: {
    position: 'absolute',
    height: 288,
    top: 0,
    right: '10%',
  },
});
