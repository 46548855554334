import React from 'react';
export const solutionsTourConfig = [
  {
    target: '.start-tour-tip',
    title: 'START',
    content:
      "Hello there! 👋 Welcome to a Solution Page. Allow us to show you around. Click 'Next' to move along!",
    placementBeacon: 'bottom',
    disableBeacon: true,
    stepId: 1,
  },
  {
    target: '.header-sectors-title span.sectors-tip',
    content:
      'Energy Sectors (and Sub-sectors) in which this Solution can be used.',
    placement: 'right',
    disableBeacon: true,
    stepId: 2,
  },
  {
    target: '.title-applications #applications-tip',
    content:
      'Operational or Business areas in which this Solution can be used.',
    placement: 'right',
    disableBeacon: true,
    stepId: 3,
  },
  {
    target: '.technologies-tip',
    content:
      'Digital technologies which this Solution utilizes or is based on.',
    placement: 'right',
    disableBeacon: true,
    stepId: 4,
  },
  {
    target: '.compatibility-tip',
    content:
      'Software Systems, Hardware Systems, Equipment Types, ERP, or Operating Systems with which this solution is compatible.',
    placement: 'right',
    disableBeacon: true,
    stepId: 5,
  },
  {
    target: '.benefits-tip',
    content:
      'Main benefits that this Solution can provide. Benefits can be based on actual results and/or design specifications.',
    placement: 'right',
    disableBeacon: true,
    stepId: 6,
  },
  {
    target: '.solution-badge-success',
    content:
      'This badge indicates that the Vendor offering this Solution has been pre-screened by EnergyExpert for authenticity, delivery capabilities, and track record.',
    placement: 'left',
    disableBeacon: true,
    stepId: 7,
  },
  // new
  {
    target: '.solution-ask-quetions #solution-request-demo',
    content: (
      <React.Fragment>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 17,
          }}
        >
          <strong>Press this button to request a Demo of this solution.</strong>
        </h4>
        <p>We'll get back to you to set up a demo date & time.</p>
      </React.Fragment>
    ),
    placement: 'left',
    disableBeacon: true,
    stepId: 8,
  },
  {
    target: '.solution-ask-quetions #solution-ask-quetions',
    content: (
      <React.Fragment>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 17,
          }}
        >
          <strong>Want to know more about this Solution?</strong>
        </h4>
        <p>
          Ask us anything. You can choose to remain anonymous to the Vendor.
        </p>
      </React.Fragment>
    ),
    placement: 'left',
    disableBeacon: true,
    stepId: 9,
  },
  {
    target: '.solution-ask-quetions #solution-spread-share',
    title: 'Spread the knowledge!',
    content: 'Share this Solution page with a colleague or external contact.',
    placement: 'left',
    disableBeacon: true,
    stepId: 10,
  },
  {
    target: '.related-media',
    content:
      'Brochures, reports, case studies, manuals, specifications, or other media related to this Solution.',
    placement: 'left',
    disableBeacon: true,
    stepId: 11,
  },
  {
    target: '.deployment-cases-tip',
    content:
      'Specific locations and assets/facilities/businesses where this Solution has been deployed.',
    placement: 'left',
    disableBeacon: true,
    stepId: 12,
  },
  {
    target: '.regions-deployed-tip',
    content:
      'The global regions where this Solution has been deployed (based on available information).',
    placement: 'left',
    disableBeacon: true,
    stepId: 13,
  },
  {
    title: 'FINISHED',
    target: '.finish-tour-tip',
    content:
      'You’re now familiarized with Solution pages. Remember to use the buttons on the right to learn more.',
    placementBeacon: 'bottom',
    disableBeacon: true,
    stepId: 14,
  },
];

export const learningSolutionsTourConfig = [
  {
    target: '.start-tour-tip',
    content:
      "Hello there! 👋 Welcome to a Learning Page. Allow us to show you around. Click 'Next' to move along!",
    placementBeacon: 'bottom',
    disableBeacon: true,
    stepId: 1,
  },
  {
    target: '.header-sectors-title span.sectors-tip',
    content:
      'Energy Sectors (and Sub-sectors) in which this Learning can be used.',
    placement: 'right',
    disableBeacon: true,
    stepId: 2,
  },
  {
    target: '.title-applications span.applications-tip',
    content:
      'Operational or Business areas in which this Learning can be used.',
    placement: 'right',
    disableBeacon: true,
    stepId: 3,
  },
  {
    target: '.technologies-tip',
    content:
      'Digital technologies which this Learning utilizes or is based on.',
    placement: 'right',
    disableBeacon: true,
    stepId: 4,
  },
  {
    target: '.solution-badge-success',
    content:
      'This badge indicates that the Vendor offering this Learning has been pre-screened by EnergyExpert for authenticity, delivery capabilities, and track record.',
    placement: 'left',
    disableBeacon: true,
    stepId: 5,
  },
  // new
  {
    target: '.solution-ask-quetions #solution-ask-quetions',
    content: (
      <React.Fragment>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 17,
          }}
        >
          <strong>Want to know more about this Learning?</strong>
        </h4>
        <p>
          Ask us anything. You can choose to remain anonymous to the Vendor.
        </p>
      </React.Fragment>
    ),
    placement: 'left',
    disableBeacon: true,
    stepId: 6,
  },
  {
    target: '.solution-ask-quetions #solution-spread-share',
    title: 'Spread the knowledge!',
    content: 'Share this Learning page with a colleague or external contact.',
    placement: 'left',
    disableBeacon: true,
    stepId: 7,
  },
  {
    target: '.related-media',
    content:
      'Brochures, reports, case studies, manuals, specifications, or other media related to this Learning.',
    placement: 'left',
    disableBeacon: true,
    stepId: 8,
  },
  {
    title: 'FINISHED',
    target: '.finish-tour-tip',
    content:
      'You’re now familiarized with Learning pages. Remember to use the buttons on the right to learn more.',
    placementBeacon: 'bottom',
    disableBeacon: true,
    stepId: 9,
  },
];
