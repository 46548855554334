import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 'none',
      outline: 'none',
      backgroundColor: '#282e51',
      '& > *': {
        margin: theme.spacing(1),
        border: 'none',
      },
    },
    input: {
      color: '#fff',
      backgroundColor: '#282e51',
      borderRadius: 4,
      padding: 8,
      paddingLeft: 14,
      '& > *': {
        border: 'none',
      },
      '& :-webkit-autofill, & :-webkit-autofill:hover,  & :-webkit-autofill:focus, & :-webkit-autofill:active': {
        WebkitBoxShadow: '0 0 0 40px #282e51 inset !important',
        WebkitTextFillColor: 'white !important',
      },
    },
    error: {
      border: '1px solid red',
    },
    label: {
      opacity: 0.8,
      fontSize: 16,
      lineHeight: 1.25,
      color: '#ffffff',
      marginBottom: 6,
      display: 'block',
    },
  })
);

interface TextFieldProps {
  placeholder?: string;
  label: string;
  name: string;
  inputRef: any;
  error: any;
  id: string;
  multiline?: boolean;
  rows?: string;
  type: string;
}

export const TextFields: React.FC<TextFieldProps> = ({
  placeholder,
  label,
  name,
  inputRef,
  error,
  id,
  multiline,
  rows,
  type = 'text',
}) => {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: 12 }}>
      <label htmlFor={id} className={classes.label}>
        {label}
      </label>
      <Input
        type={type}
        className={`${classes.input} ${!!error && classes.error}`}
        placeholder={placeholder}
        fullWidth={true}
        disableUnderline={true}
        inputRef={inputRef}
        name={name}
        id={id}
        multiline={multiline}
        rows={rows}
      />
    </div>
  );
};
