import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { ReactComponent as TechnologiesIcon } from '../../assets/technologies.svg';
import { ReactComponent as CompatibilityIcon } from '../../assets/compatibility.svg';

interface SectorListProps {
  digitalTechnologies: string[];
  solutionCompatibility?: string[];
  isTourFinished: any;
}

export const TechAndCompatibility: React.FC<SectorListProps> = ({
  digitalTechnologies,
  solutionCompatibility = [],
  isTourFinished,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, isTourFinished });

  return (
    <div className={classes.content}>
      <div className={classes.lists}>
        <div className={classes.icon}>
          <TechnologiesIcon />
        </div>
        <div className={classes.sectors}>
          <p className="technologies-tip">Technologies</p>
          <ul className={classes.sectorsList}>
            {digitalTechnologies &&
              digitalTechnologies.map((tech) => (
                <li style={{ fontSize: '17px' }} key={tech}>
                  {tech}
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className={classes.lists}>
        {!!solutionCompatibility.length && (
          <>
            <div className={classes.icon}>
              <CompatibilityIcon />
            </div>
            <div className={classes.sectors}>
              <p className="compatibility-tip">Compatibility</p>
              <ul className={classes.sectorsList}>
                {solutionCompatibility.map((tech) => (
                  <li key={tech}>{tech}</li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
