export function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
}

export const pluralize = (word: string, count: number) => {
  if (count === 1) {
    return word;
  }
  return `${word}s`;
};

export const setFinishedTourInStorage = (finishedTour: string) => {
  localStorage.setItem(finishedTour, 'true');
};

export const deleteItemFromStorage = (item: string) => {
  localStorage.removeItem(item);
};

export const getItemFromLocalStorage = (item: string) => {
  const value: any = localStorage.getItem(item);
  return JSON.parse(value);
};
