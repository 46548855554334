import React from 'react';
import { useTheme } from 'react-jss';
import moment from 'moment';

import { useStyles } from './style';
import { SideBarSectionTitle } from '../SolutionSideBar/SideBarSectionTitle/SideBarSectionTitle';

interface DeploymentCasesProps {
  deployedCases: string[];
  sectionTitle: any;
}

export const DeploymentCases: React.FC<DeploymentCasesProps> = ({
  deployedCases,
  sectionTitle,
}) => {
  let cleanData: any = deployedCases;
  if (sectionTitle === 'Dates Offered') {
    cleanData = cleanData.map((date: any) => {
      if (date.includes('-')) {
        const dateRange = date.split('-');
        const convertedDate = dateRange.map((newDate: any) => {
          const [day, month, year] = newDate.split('/');
          const formatedate = `${year} ${month} ${day}`;
          return moment(formatedate, 'YYYY MM DD').format('ddd, MMM D, YYYY');
        });
        return convertedDate.join('\xa0\xa0 → \xa0\xa0');
      } else {
        const [day, month, year] = date.split('/');
        const formatedate = `${year} ${month} ${day}`;
        return moment(formatedate, 'YYYY MM DD').format('ddd, MM D YYYY');
      }
    });
  }

  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <SideBarSectionTitle>{sectionTitle}</SideBarSectionTitle>
      {cleanData.map((item: any) => (
        <div className={classes.item} key={item}>
          <p className="case">{item}</p>
        </div>
      ))}
    </>
  );
};
