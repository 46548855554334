import React from 'react';
import Alert from './Alert';

interface ErrorAlertProps {
  msg: string;
}

export default function ErrorAlert({ msg }: ErrorAlertProps) {
  return (
    <Alert severity="error">{msg}</Alert>
  );
}