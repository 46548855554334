import React from 'react';
import { useTheme } from 'react-jss';
import { Link } from 'react-router-dom';
import { Logo } from '../Logo/Logo';
import { useStyles } from './style';

export const PortalIntroNavBar: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.navbar}>
      <Link to="/home" style={{ textDecoration: 'none' }}>
        <Logo />
      </Link>
      <div className={classes.searchBox}>
      </div>
    </div>
  );
};
