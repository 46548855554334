import React from 'react';
import { useTheme } from 'react-jss';

import {
  EmailShareButton,
  EmailIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { useStyles } from './style';

const socialShareOptions = (type: string) => ({
  email: {
    subject: `Check out this ${type} I found on EnergyExpert`,
    body: `Hello, check out this ${type} I found on EnergyExpert:`,
    separator: '=> \n ',
  },
  whatsApp: {
    title: `Hello, check out this ${type} I found on EnergyExpert:`,
    separator: ' ',
  },
  linkedin: {
    title: `Check out this ${type} I found on EnergyExpert`,
    summary: `Hello, check out this ${type} I found on EnergyExpert:`,
    source: `EnergyExpert`,
  },
});

interface SocialMediaShareProps {
  url: string;
  postedA: string;
}

export const SocialMediaShare: React.FC<SocialMediaShareProps> = ({
  url,
  postedA,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.socialButtonsWrapper}>
      <EmailShareButton
        url={url + '\n\n'}
        subject={socialShareOptions(postedA).email.subject}
        body={socialShareOptions(postedA).email.body}
        separator={socialShareOptions(postedA).email.separator}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>

      <WhatsappShareButton
        url={url}
        title={socialShareOptions(postedA).whatsApp.title}
        separator={socialShareOptions(postedA).whatsApp.separator}
        windowWidth={800}
        windowHeight={600}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <LinkedinShareButton
        url={url}
        title={socialShareOptions(postedA).linkedin.title}
        summary={socialShareOptions(postedA).linkedin.summary}
        source={socialShareOptions(postedA).linkedin.source}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </div>
  );
};
