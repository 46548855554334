import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.2,
    color: ({ theme: { colors } }) => '#5f9afc',
    marginBottom: 20
  },
});
