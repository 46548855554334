import jss from 'jss';
import preset from 'jss-preset-default';
import { SheetsRegistry } from 'react-jss';

export const createGlobalStyle = ({ colors, typography }) => {
  const globalStyles = {
    backgroundColor: colors.bg.main,
    color: colors.primary,
    fontFamily: typography.font.body,
    fontSize: 16,
  };

  const setupJss = () => {
    jss.setup(preset());
    const sheetsRegistry = new SheetsRegistry();
    const globalStyleSheet = jss
      .createStyleSheet({
        '@global': { body: globalStyles },
      })
      .attach();
    sheetsRegistry.add(globalStyleSheet);
    return sheetsRegistry;
  };

  return setupJss();
};
