import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  tag: {
    backgroundColor: '#000',
    color: '#fff',
    display: 'inline-block',
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8,
    textAlign: 'center',
    fontSize: 14,
    borderRadius: 3,
  },
});
