import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'inline-block',
    marginRight: '8px',
    marginBottom: 4,
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    border: ({ theme }) => `solid 1px ${theme.colors.bg.tagBorder}`,
    padding: '3px 12px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: '500',
    color: ({ theme }) => theme.colors.accent,
    '&::before': {
      content: '""',
      display: 'inline-block',
      borderRadius: '50%',
      width: '6px',
      height: '6px',
      backgroundColor: '#0271e3',
      marginRight: '6px',
    },
  },
});
