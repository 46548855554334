import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { TextFields as MTextField } from '../../../Form/MuTextField/MuTextField';
import { AskEnergyModalButton } from '../../../Form/AskButton/AskButton';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../../../config/axios';

interface ActionModal {
  isAuthenticated: boolean;
  open: boolean;
  handleClose: () => void;
  provider: string;
  title: string;
}

export default function ActionModal({
  open,
  handleClose,
  provider,
  title,
}: any) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);

  const callBackendApi = (endpoint: string, payload: any) => {
    setLoading(true);

    axiosInstance
      .post(endpoint, payload)
      .then((response) => {
        setIsSubmitted(true);
        setSuccess(true);
        setTimeout(() => {
          handleClose();
          setIsSubmitted(false);
        }, 6000);
      })
      .catch((error) => {
        setError('Something went wrong! please try again.');
        setTimeout(() => {
          handleClose();
          setError('');
        }, 6000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (data: any, e: any) => {
    const fullData = {
      ...data,
      provider,
      title,
    };
    e.preventDefault();

    callBackendApi('/request-demo', fullData);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.contentWapper}
          >
            <h2 id="transition-modal-title" className={classes.title}>
              Request a Demo
            </h2>
            <p className={classes.introParagraph}>
              Enter your contact details and we’ll get back to you to arrange a
              demo for this Solution.
            </p>
            <div id="transition-modal-description">
              <MTextField
                id="name"
                placeholder="Enter your name"
                name="name"
                label="Name"
                inputRef={register({ required: true })}
                error={!!errors.name}
                type="text"
              />
              <MTextField
                id="email"
                name="email"
                placeholder="Enter your email"
                label="Email"
                type="email"
                inputRef={register({
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address',
                  },
                })}
                error={!!errors.email}
              />
            </div>
            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}
            {error && <span className={classes.error}>{error}</span>}
            {success && (
              <span className={classes.success}>
                Your message has been sent. We'll get back to you shortly.
              </span>
            )}
            <br />
            <div className={classes.actionsButton}>
              {!isSubmitted ? (
                <>
                  <div
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    <AskEnergyModalButton
                      type="submit"
                      title="Request"
                      disabled={loading ? true : false}
                      isLoading={loading}
                    />
                  </div>
                  <div onClick={handleClose}>
                    <AskEnergyModalButton
                      type="button"
                      title="Cancel"
                      disabled={false}
                    />
                  </div>
                </>
              ) : (
                <div onClick={handleClose}>
                  <AskEnergyModalButton
                    type="button"
                    title="Close"
                    disabled={false}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
