import React, { useState } from 'react';
import throttle from 'lodash.throttle';
import { useTheme } from 'react-jss';
import { ListItem } from '../ListingPageContent/ListItem/ListItem';
import { useStyles } from './style';
import ListItemSkeleton from '../Skeleton/ListItemSkeleton';
import { BackToTop } from '../BackToTop/BackToTop';
import { search } from '../../helpers/search';
import { useHistory } from 'react-router-dom';
import { GoBack } from '../GoBack/GoBack';

import { SearchInput } from './SearchInput';
import {
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
  ListRowProps,
} from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once

const renderListItem = (list: any[], isFetchingListings: boolean) => {
  if (list?.length < 1) {
    return <h2>No results found</h2>;
  }

  const cache = new CellMeasurerCache({
    defaultHeight: 375,
    fixedWidth: true,
  });

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
    parent,
  }: ListRowProps) {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        rowIndex={index}
        key={key}
        parent={parent}
      >
        {({ measure }) => (
          <div key={key} style={{ ...style }}>
            <div onLoad={measure}>
              <ListItem
                key={list[index].token}
                listing={list[index]}
                isFetchingListings={isFetchingListings}
                origin="search"
              />
            </div>
          </div>
        )}
      </CellMeasurer>
    );
  }

  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              rowCount={list.length}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRenderer}
              scrollTop={scrollTop}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

export const SearchPageContent: React.FC<any> = ({
  listings = [],
  isFetchingListings,
  currentArea,
  filtersOptions,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [searchTerm, setSearchTerm] = useState(history?.location?.state);
  let list = listings;
  const results: any = throttle(() => search(listings, searchTerm), 1000);

  if (searchTerm) {
    list = results();
  } else {
    list = [];
  }

  return (
    <div>
      <section className={classes.lists}>
        <div
          onClick={() => history.goBack()}
          style={{ cursor: 'pointer', marginBottom: 20 }}
        >
          <GoBack text={'Go back'} />
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: 20,
          }}
          id="back-to-top-anchor"
        >
          <SearchInput value={searchTerm} handleChange={setSearchTerm} />
        </div>
        {!!list?.length && (
          <h2 style={{ marginBottom: 22 }}>
            <span
              style={{
                fontSize: 30,
                fontWeight: 'normal',
                color: '#8389b8',
              }}
            >
              {list.length}
            </span>{' '}
            results
          </h2>
        )}

        <div>
          {isFetchingListings ? (
            <>
              <ListItemSkeleton />
              <ListItemSkeleton />
              <ListItemSkeleton />
            </>
          ) : (
            <>
              {renderListItem(list, isFetchingListings)}
              <BackToTop />
            </>
          )}
        </div>
      </section>
    </div>
  );
};
