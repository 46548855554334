import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  sidebar: {},

  badgeWrapper: {
    width: '95%',
    maxWidth: 306,
    margin: '0 auto',
    marginTop: 112,
    display: 'flex',
    justifyContent: 'space-between',
  },

  sideContentWrapper: {
    width: '85%',
    maxWidth: 306,
    margin: '0 auto',
    marginTop: 75,
  },

  sideContentMargin: {
    marginTop: 20,
  },

  location: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#fff',
  },

  deployedRegions: {},
});
