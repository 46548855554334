import React, { FC, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';

import { VerifiedBadge } from '../VerifiedBadge/VerifiedBadge';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import { DeployedRegions } from '../DeployedRegions/DeployedRegions';
import { SolutionAreas } from '../SolutionAreas/SolutionAreas';
import { SideBarSectionTitle } from './SideBarSectionTitle/SideBarSectionTitle';
import { DeploymentCases } from '../DeploymentCases/DeploymentCases';
import { Media } from '../Media/Media';
import { useStyles } from './style';
import { ProviderInfo } from './ProviderInfo/ProviderInfo';

interface SolutionSideBarProps {
  provider: string;
  postedA: string;
  title: string;
  type: string;
  applicationGroup: any;
  applications: string[];
  sectors: string[];
  subSectors: any;
  digitalTechnologies: string[];
  solutionCompatibility?: string[]; // sol or boht???
  customizations?: string[];
  briefSummary: string;
  tags: string[];
  displyPhotoUrl: string;
  // both(sol + learning;
  detailedDescretpion: string; // required
  testimonials?: any;
  websiteUrl?: string;
  email?: string;
  media?: any;
  submittedAt: string;
  token: string;
  verified: boolean;

  // sol only
  deployedRegions?: string[];
  deployedCases?: string[];
  benefits?: any;
  dates?: any;
  locations?: any;
  company?: string;
  showProviderInfo: boolean;
  isAuthenticated: boolean;
  state: any;
  isNew: any;
}

export const SolutionSideBar: FC<SolutionSideBarProps> = ({
  verified: isVerified = false,
  deployedRegions = [],
  deployedCases = [],
  media = {},
  email,
  provider,
  websiteUrl,
  postedA,
  dates = [],
  locations = [],
  company,
  showProviderInfo,
  isAuthenticated,
  title,
  state,
  isNew,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [mediaLinks, setMediaLinks] = useState<any>(null);

  useEffect(() => {
    const links: any[] = Object.values(media);
    setMediaLinks(links);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.sidebar}>
      {isVerified && (
        <>
          <div className={classes.badgeWrapper}>
            <div>
              <ProviderInfo
                provider={provider}
                postedA={postedA}
                title={title}
                isVerified={isVerified}
              />
            </div>
            <div className="badge solution-badge-success">
              <VerifiedBadge isSmall={true} iconWithTooltip={true}/>
            </div>
          </div>
        </>
      )}
      <div
        className={`${classes.sideContentWrapper} ${classes.sideContentMargin}`}
      >
        <div className="solution-ask-quetions">
          <GeneralInfo
            email={email}
            provider={provider}
            websiteUrl={websiteUrl}
            postedA={postedA}
            company={company}
            showProviderInfo={showProviderInfo}
            isAuthenticated={isAuthenticated}
            area={state?.area}
            title={title}
            isVerified={isVerified}
          />
        </div>
      </div>

      {mediaLinks && mediaLinks.length > 0 && (
        <div className={classes.sideContentWrapper}>
          <Media
            links={mediaLinks}
            sectionTitle={<span className="related-media">Related Media</span>}
          />
        </div>
      )}

      {locations.length > 0 && (
        <>
          <div
            style={{ marginBottom: -20 }}
            className={classes.sideContentWrapper}
          >
            <SideBarSectionTitle>Locations Offered </SideBarSectionTitle>
          </div>
          <div
            style={{
              width: '85%',
              maxWidth: 306,
              margin: '0 auto',
              paddingTop: 16,
            }}
          >
            {locations.map((location: any) => (
              <p key={location} className={classes.location}>
                {location}
              </p>
            ))}
          </div>
          {!locations[0].includes('house') && (
            <div className={classes.deployedRegions}>
              <SolutionAreas locations={locations} origin="learning" />
            </div>
          )}
        </>
      )}
      {(deployedCases.length > 0 || dates.length > 0) && (
        <div className={classes.sideContentWrapper}>
          <DeploymentCases
            deployedCases={deployedCases.length > 0 ? deployedCases : dates}
            sectionTitle={
              deployedCases.length > 0 ? (
                <span className="deployment-cases-tip">Deployment Cases</span>
              ) : (
                'Dates Offered'
              )
            }
          />
        </div>
      )}

      {deployedRegions.length > 0 && (
        <>
          <div
            style={{ marginBottom: -20 }}
            className={classes.sideContentWrapper}
          >
            <SideBarSectionTitle>
              <span className="regions-deployed-tip">Regions Deployed</span>
            </SideBarSectionTitle>
          </div>
          <div className={classes.deployedRegions}>
            <DeployedRegions deployedRegions={deployedRegions} />
          </div>
        </>
      )}
    </div>
  );
};
