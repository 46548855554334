import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  solutionCard: {
    marginLeft: 40,
    marginRight: 48,
    flex: 3,
    maxWidth: 500,
    minWidth: 450,
    display: 'flex',
    flexDirection: 'column',
  },

  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    marginBottom: '8px',
  },

  heading: {
    fontWeight: '500',
    marginBottom: '8px',
    fontSize: 28,
    lineHeight: 1.17,
    letterSpacing: '-0.15px',
    minHeight: '35px',
  },

  subHeading: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    marginBottom: 24,
    color: ({ theme: { colors } }) => colors.accent,
  },

  paragraph: {
    marginBottom: 'auto',
    maxWidth: '425px',
    fontSize: 14,
    whiteSpace: 'pre-line',
    letterSpacing: 'normal',
  },

  /* The Action Buttons styles */
  actionsButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  favoriate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 111,
    height: 40,
    borderRadius: 2,
    backgroundColor: '#ffe69c',
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 1.54,
    color: ({ theme }) => theme.colors.favBtnClicked,
  },

  moreDetails: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: 40,
    borderRadius: 2,
    backgroundColor: ({ theme }) => theme.colors.bg.detailsBtn,
    color: ({ theme }) => theme.colors.detailsBtn,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
  },

  '@media (max-width: 768px)': {
    solutionCard: {
      marginLeft: 10,
      marginRight: 10,
      flex: 1.4,
      maxWidth: 425,
      minWidth: 250,
    },

    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      marginTop: '16px',
      marginBottom: '8px',
    },

    heading: {
      fontWeight: '500',
      marginBottom: '4px',
      fontSize: 16,
      lineHeight: 1.17,
      letterSpacing: '-0.15px',
    },

    subHeading: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.43,
      marginBottom: 24,
      color: ({ theme: { colors } }) => colors.accent,
    },

    paragraph: {
      marginBottom: 12,
      maxWidth: '425px',
    },

    /* The Action Buttons styles */
    actionsButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
    },

    favoriate: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 95,
      height: 40,
      borderRadius: 2,
      backgroundColor: '#ffe69c',
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: 1.54,
      color: ({ theme }) => theme.colors.favBtnClicked,
    },

    moreDetails: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 130,
      height: 40,
      borderRadius: 2,
      backgroundColor: ({ theme }) => theme.colors.bg.detailsBtn,
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: 1.54,
      color: ({ theme }) => theme.colors.detailsBtn,

      '& .hide': {
        display: 'none',
      },
    },
  },
});
