import React from 'react';
import Joyride from 'react-joyride';

interface TourGuideProps {
  stepsContent: any;
  tourSettings: any;
  handleJoyrideCallback: any;
  setHelpers: any;
  key: any;
  scrollingEnabled?: boolean;
}

export const TourGuide: React.FC<TourGuideProps> = ({
  stepsContent,
  tourSettings,
  handleJoyrideCallback,
  setHelpers,
  scrollingEnabled,
  key,
}) => {
  return (
    <Joyride
      key={key}
      steps={stepsContent}
      run={tourSettings.run}
      getHelpers={setHelpers}
      continuous
      scrollToFirstStep
      spotlightClicks
      callback={handleJoyrideCallback}
      disableScrolling={scrollingEnabled ? true : false}
      disableScrollParentFix={scrollingEnabled ? false : true}
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'Close',
        next: 'Next',
        skip: 'Skip',
      }}
      styles={{
        options: {
          arrowColor: '#2F3965',
          backgroundColor: '#2F3965',
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: '#5f9afc',
          textColor: 'white',
          width: '375px',
          zIndex: 1000,
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
        },
        spotlight: {
          backgroundColor: 'rgba(95, 154, 252, 0.2)',
          zIndex: 100,
        },

        buttonBack: {
          fontFamily: 'inherit',
          display: 'none',
        },

        buttonSkip: {
          fontFamily: 'inherit',
        },

        buttonNext: {
          fontFamily: 'inherit',
        },
      }}
    />
  );
};
