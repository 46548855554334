import React, { useEffect, useState } from 'react';
import { Map as MapLeaflet, TileLayer, GeoJSON } from 'react-leaflet';
import { useTheme } from 'react-jss';
import useWindowWidth from '../../hooks/useWindowWidth';
import worldCountries from '../../assets/world-countries.json';
import { regions as regionsList } from './UNSDRegions';

export default function LeafLetMap({ deployedRegions, origin }) {
  const { breakPoints } = useTheme();
  const width = useWindowWidth();

  const getHighlightedRegionsStyle = () => {
    return {
      fillColor: '#5f9afc',
      weight: 0,
      fillOpacity: 1,
    };
  };
  const getWorldMapStyle = () => {
    return {
      fillColor: '#303865',
      weight: 0,
      fillOpacity: 1,
    };
  };

  const getCountries = (regions = []) => {
    let deployedRegionsArray = [];
    regions.forEach(region => {
      if (regionsList[region]) {
        deployedRegionsArray = deployedRegionsArray.concat(regionsList[region]);
      }
    });
    const data = worldCountries.features.filter(country => {
      return deployedRegionsArray.includes(country.properties.name);
    });

    return data;
  };
  const getLearningCountries = (countries = []) => {
    const data = worldCountries.features.filter(country => {
      return (
        countries.includes(country.properties.name) ||
        countries.includes(country.properties.otherNames) ||
        countries.includes(country.id)
      );
    });

    return data;
  };

  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (width > breakPoints.laptopL) {
      setZoom(0.9);
    } else if (width > breakPoints.laptopM) {
      setZoom(0.65);
    } else if (width > breakPoints.laptopS) {
      setZoom(0.35);
    } else if (width > breakPoints.tablet) {
      setZoom(0.15);
    } else {
      setZoom(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  // map center latlng
  const lat = 51.505;
  const lng = -0.09;
  const position = [lat, lng];

  return (
    <MapLeaflet
      center={position}
      zoom={zoom}
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#111944',
      }}
      zoomSnap={0.05}
      zoomControl={false}
      attributionControl={false}
      dragging={false}
      scrollWheelZoom={false}
      tap={false}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        opacity={0}
        noWrap={true}
      />
      <GeoJSON data={worldCountries} style={getWorldMapStyle} />
      {origin === 'learning' ? (
        <GeoJSON
          data={getLearningCountries(deployedRegions)}
          style={getHighlightedRegionsStyle}
        />
      ) : (
        <GeoJSON
          data={getCountries(deployedRegions)}
          style={getHighlightedRegionsStyle}
        />
      )}
    </MapLeaflet>
  );
}
