import React from 'react';
import { IntelligenceListLayout } from '../../layouts/IntelligenceListLayout/IntelligenceListLayout';
import { SearchPageContent } from '../../components/SearchPageContent/SearchPageContent';
import { ScrollToTopOnMount } from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import Helmet from 'react-helmet';
import useFetch from '../../hooks/useFetch';

export const SearchResults: React.FC<any> = props => {
  const {
    name,
    jobTitle,
    updateAuthState,
    isAuthenticated,
    currentPortal,
    filtersOptions,
  } = props;

  const areas = currentPortal?.groupedAreas ? currentPortal?.groupedAreas.reduce((areas: any, group: any) => areas.concat(group.areas), []) : [];
  const [{ isLoading: isFetchingSearchResults, data: listings }] = useFetch(
    `/search/portal/${currentPortal?.id}`,
    []
  );

  return (
    <>
      <ScrollToTopOnMount />
      <Helmet>
        <title>EnergyExpert</title>
      </Helmet>
      <IntelligenceListLayout
        main={
          <SearchPageContent
            listings={listings}
            isFetchingListings={isFetchingSearchResults}
            filtersOptions={filtersOptions}
          />
        }
        withSearch={false}
        name={name}
        jobTitle={jobTitle}
        updateAuthState={updateAuthState}
        isAuthenticated={isAuthenticated}
        areas={areas}
        company={currentPortal?.name}
      />
    </>
  );
};
