import React from 'react';
import { useTheme } from 'react-jss';

import { useStyles } from './style';

import { ReactComponent as BackIcon } from '../../assets/next.svg';

interface NextProps {
  text: string;
}

export const Next: React.FC<NextProps> = ({ text }) => {
  const theme: any = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.goBack}>
      <div className={classes.sector}>{text}</div>
      <div className={classes.mockIcon}>
        <BackIcon fill={theme?.colors?.primary} />
      </div>
    </div>
  );
};
