import React, { ReactElement, useState, useRef } from 'react';
import { ACTIONS, EVENTS, STATUS, StoreHelpers } from 'react-joyride';
import { NavBar } from '../../components/NavBar/NavBar';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { TourGuide } from '../../components/TourGuide/TourGuide';
import { learningTourConfig, solutionsTourConfig } from './ListingsTips';
import { localStorageKeys } from './../../constants';
import useLocalStorage from '../../hooks/useLocalStorage';
import { CurrentArea } from '../../interfaces';

interface IntelligenceListLayoutProps {
  main: ReactElement;
  name: string;
  jobTitle: string;
  updateAuthState: any;
  isAuthenticated: boolean;
  areas: string;
  company?: string;
  avatar?: string;
  withTourGuide?: boolean;
  currentArea?: CurrentArea;
  withSearch?: boolean;
}

export const IntelligenceListLayout: React.FC<IntelligenceListLayoutProps> = ({
  main,
  name,
  jobTitle,
  updateAuthState,
  isAuthenticated,
  areas,
  company,
  avatar,
  withTourGuide,
  currentArea = {},
  withSearch,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [tourConfig, setTourConfig] = React.useState<any>([]);
  const [isTourFinished, setFinishedTour] = React.useState(false);
  const [key, setKey] = useState(0);

  const [isLearningToured, setIsLearningToured] = useLocalStorage(
    localStorageKeys.isLearningListingsToured,
    false,
  );
  const [isListingsToured, setIsListingsToured] = useLocalStorage(
    localStorageKeys.isListingsToured,
    false,
  );

  const [tourSettings, setTourSettings] = useState({
    stepIndex: 0,
    run: true,
  });

  const setAreaToured =
    currentArea.id === 'learning' ? setIsLearningToured : setIsListingsToured;

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setTourSettings({
        ...tourSettings,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //mark tour as completed
      setAreaToured(true);
    }
    if (action === 'close') {
      setTourSettings({
        ...tourSettings,
        run: false,
      });
      helpersRef.current.skip();
      setAreaToured(true);
    }
  };

  React.useEffect(() => {
    if (currentArea.id === 'learning') {
      setTourConfig(learningTourConfig);
      setFinishedTour(isLearningToured);
    } else {
      setTourConfig(solutionsTourConfig);
      setFinishedTour(isListingsToured);
    }
  }, [currentArea.id, isLearningToured, isListingsToured]);

  const helpersRef: any = useRef();
  const setHelpers = (helpers: StoreHelpers) => {
    helpers.reset(true);
    helpersRef.current = helpers;
  };

  const handleClickRestart = () => {
    if (currentArea && currentArea.id === 'learning') {
      setIsLearningToured(null);
    } else {
      setIsListingsToured(null);
    }
    const { reset } = helpersRef.current;
    setKey((k) => k++);
    reset(true);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className="main-view">
          <nav className="navbar">
            <NavBar
              withUserList={true}
              name={name}
              jobTitle={jobTitle}
              updateAuthState={updateAuthState}
              isAuthenticated={isAuthenticated}
              areas={areas}
              company={company}
              avatar={avatar}
              resetTour={handleClickRestart}
              withSearch={withSearch}
            />
          </nav>
          <div className="page-wrapper container">
            <main>
              <div className="content-wrapper">{main}</div>
            </main>
          </div>
        </div>
      </div>

      {!isTourFinished && withTourGuide && (
        <TourGuide
          stepsContent={tourConfig}
          handleJoyrideCallback={handleJoyrideCallback}
          tourSettings={tourSettings}
          setHelpers={setHelpers}
          key={key}
        />
      )}
    </>
  );
};
