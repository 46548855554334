import React from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { Tag } from './BenefitTag';

interface BenefitsProps {
  benefits: any;
}

const amountStyleBreakPoint = 1000;

export const Benefits: React.FC<BenefitsProps> = ({ benefits }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const benefitsList: any = Object.entries(benefits);
  return (
    <div className={classes.content}>
      {benefitsList.map(([_, details]: any, i: any) => {
        const { type, effect, metric, range, amount }: any = details;
        return (
          <div className={classes.benefitItem} key={i}>
            {!!metric && !!amount ? (
              <div style={{ marginTop: '30px' }}>
                {amount >= amountStyleBreakPoint ? (
                  <div className={classes.circleSpace}>
                    <p>
                      {amount}
                      <span className={classes.percent}>
                        {metric === 'hours' ? 'hr' : metric}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className={classes.circle}>
                    <p>
                      {amount}
                      <span className={classes.percent}>
                        {metric === 'hours' ? 'hr' : metric}
                      </span>
                    </p>
                  </div>
                )}
                <div className={classes.text}>
                  {`${range} ${amount} ${metric} ${type} ${effect}`}
                </div>
              </div>
            ) : (
              <div className={`${classes.text} ${classes.noCircle}`}>
                <Tag text={`${range} ${amount} ${metric} ${type} ${effect}`} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
