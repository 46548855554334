import React, { FC } from 'react';
import { useTheme } from 'react-jss';

import { useStyles } from './style';

export const SideBarSectionTitle: FC<{ children: any }> = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return <h3 className={classes.title}>{children}</h3>;
};
