import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  tags: {
    display: 'flex',
    marginBottom: 20,
  },

  heading: {
    fontSize: '32px',
    fontWeight: '500',
    marginBottom: 20,
    marginTop: 40,
    color: ({ theme }) => theme.colors.primary,
  },

  verifedIcon: {
    marginLeft: '15px',
    display: 'none',
  },

  slider: {
    width: '100%',
    border: 'none',
    outline: 'none',
  },

  location: {
    fontSize: 16,
    fontWeight: 500,
    color: '#fff',
  },

  list: {
    marginBottom: 50,
  },

  techAndCompatibility: {
    marginTop: 20,
    marginBottom: 50,
  },

  sectionHeading: {
    marginBottom: '15px',
    color: '#5f9afc',
  },

  description: {
    display: 'flex',
    '& p': {
      fontSize: '15px',
      marginBottom: 40,
      '& span': {
        textDecoration: 'underline',
      },
    },
  },

  regionsMap: {
    display: 'none',
  },

  deployedCases: {
    display: 'none',
  },

  '@media (max-width: 768px)': {
    content: {
      justifyContent: 'center',
    },
    benefitItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 15,
      minWidth: '300 !important',
    },

    regionsMap: {
      display: 'block',
      marginBottom: 70,
    },
    deployedCases: {
      display: 'block',
      marginBottom: 70,
    },
    verifedIcon: {
      display: 'inline',
    },
  },
});
