import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { TextFields as MTextField } from '../Form/MuTextField/MuTextField';
import { AskEnergyModalButton } from '../Form/AskButton/AskButton';

import { useForm } from 'react-hook-form';
import axiosInstance from '../../config/axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    root: {
      opacity: 0.7,
      backgroundColor: 'white',
    },

    paper: {
      backgroundColor: '#303865',
      width: 618,
      borderRadius: 24,
      border: 'none',
      outline: 'none',
    },
    contentWapper: {
      padding: '30px 48px',
    },
    title: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.14,
      marginBottom: 6,
    },
    introParagraph: {
      opacity: 0.8,
      fontSize: 14,
      lineHeight: 1.43,
      fontWeight: 'normal',
      marginBottom: 22,
    },
    actionsButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    error: {
      color: 'red',
    },

    address: {
      padding: 48,
      paddingTop: 24,
      paddingBottom: 30,
      fontSize: 14,
      lineHeight: 1.43,
      color: '#ffffff',
    },

    filled: {
      borderRadius: 4,
      backgroundColor: '#282e51',
    },

    success: {
      color: 'green',
    },
  })
);

interface AskModal {
  isAuthenticated: boolean;
  open: boolean;
  handleClose: () => void;
  provider: string;
  title: string;
  area?: string;
  subText?: string;
  isContactUsForm?: Boolean;
}

export const LookingForMoreModal = ({
  open,
  handleClose,
  isAuthenticated,
  provider,
  title,
  area,
  subText = '',
  success,
  setSuccess,
}: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = (data: any, e: any) => {
    const fullData = {
      ...data,
      provider,
      title,
      area,
      isExploreMoreEmail: true,
    };
    e.preventDefault();
    setLoading(true);

    axiosInstance
      .post('/support', fullData)
      .then(response => {
        setError('');
        setSuccess(true);
        setIsSubmitted(true);
        setTimeout(() => {
          handleClose();
          setIsSubmitted(false);
        }, 6000);
      })
      .catch(error => {
        setError('Something went wrong!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.contentWapper}
          >
            <h2 id="transition-modal-title" className={classes.title}>
              Request new Intelligence Area
            </h2>
            <p className={classes.introParagraph}>{subText}</p>
            <div id="transition-modal-description">
              <MTextField
                id="message"
                name="text"
                type="text"
                placeholder="Enter your message"
                label="Message"
                inputRef={register({ required: true })}
                error={!!errors.text}
                multiline={true}
                rows="4"
              />
            </div>

            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}
            {error && <span className={classes.error}>{error}</span>}
            {success && (
              <span className={classes.success}>
                Your message has been sent. We'll get back to you shortly.
              </span>
            )}
            <br />
            <div className={classes.actionsButton}>
            {!isSubmitted ? (
              <>
                <div
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <AskEnergyModalButton
                    type="submit"
                    title="Submit"
                    disabled={loading ? true : false}
                    isLoading={loading}
                  />
                </div>
                <div onClick={handleClose}>
                  <AskEnergyModalButton
                    type="button"
                    title="Cancel"
                    disabled={false}
                  />
                </div>
              </>
              ) : (
                <div onClick={handleClose}>
                  <AskEnergyModalButton
                    type="button"
                    title="Close"
                    disabled={false}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};
