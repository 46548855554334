import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './style';
import { useTheme } from 'react-jss';

export default function ListItemSkeleton() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={`${classes.listItem} mb40`}>
      <div className={classes.listImage}>
        <Skeleton variant="rect" height={340} width={389} />
      </div>
      <div className={classes.solutionCard}>
        <div className={classes.tags}>
          <Skeleton width={90} />
        </div>

        <div className={classes.subHeading}>
          <Skeleton width={280} />
          <Skeleton width={120} />
        </div>
        <div className={classes.paragraph}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>

        <div className={classes.actionsButtons}>
          <Skeleton variant="rect" height={40} width={111} />
          <Skeleton variant="rect" height={40} width={150} />
        </div>
      </div>

      <div className={classes.moreInfo}>
        <Skeleton variant="rect" height={72} width={306} />
        <div className="mt40">
          <h4 className={classes.listTitle}>
            <Skeleton width={90} />
          </h4>
          <div className={classes.item}>
            <Skeleton width={200} />
          </div>
          <h4 className={`${classes.listTitle} mt12`}>
            <Skeleton width={90} />
          </h4>
          <Skeleton width={210} />
          <Skeleton width={160} />
          <Skeleton width={190} />
        </div>
      </div>
    </div>
  );
}
