import React, { FC, useState, useEffect, useRef } from 'react';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import { AskEnergyExpert } from '../AskEnergyExpert/AskEnergyExpert';
import { ShareWithSomeone } from '../ShareWithSomeone/ShareWithSomeone';
import { RequestDemo } from './../RequestDemo'

interface GeneralInfoProps {
  postedA: string;
  email?: string;
  websiteUrl?: string;
  company?: string;
  showProviderInfo: boolean;
  isAuthenticated: boolean;
  provider: string;
  title: string;
  area?: string;
  isVerified: boolean;
}

export const GeneralInfo: FC<GeneralInfoProps> = ({
  provider,
  email,
  websiteUrl,
  postedA,
  company,
  isAuthenticated,
  title,
  area,
  isVerified,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [enableAnimation, setEnableAnimation] = useState(false);
  const generalInfoRef = useRef(null);

  const onScrollY = () => {
    const breakpoint = 300;
    const offsetY = window.pageYOffset;

    if (offsetY >= breakpoint) {
      setEnableAnimation(true);
    } else {
      setEnableAnimation(false);
    }
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', onScrollY);
    }

    watchScroll();

    return () => {
      window.removeEventListener('scroll', onScrollY);
    };
  });

  const scrollToRef = (ref: any) => {
    setEnableAnimation(false);
    window.scrollTo(0, ref.current.offsetTop - 135);
  };

  return (
    <div className={classes.wrapper} ref={generalInfoRef}>
      {postedA && postedA.toLowerCase() === 'solution' &&
        <div id="solution-request-demo">
        <RequestDemo
          enableAnimation={enableAnimation}
          isAuthenticated={isAuthenticated}
          solutionTitle={title}
          solutionProvider={provider}
        />
      </div>}
      <div id="solution-ask-quetions">
        <AskEnergyExpert
          isAuthenticated={isAuthenticated}
          provider={provider}
          title={title}
          area={area}
          postedA={postedA}
          enableAnimation={enableAnimation}
        />
      </div>
      <div id="solution-spread-share">
        <ShareWithSomeone
          company={company}
          postedA={postedA}
          enableAnimation={enableAnimation}
          generalInfoRef={generalInfoRef}
          onScrollUp={scrollToRef}
          title={title}
          provider={provider}
        />
      </div>
    </div>
  );
};
