import React, { FC, useState } from 'react';
import { useTheme } from 'react-jss';
import ReactGA from 'react-ga';
import { useStyles } from './style';
import infoIcon from '../../../assets/provider-info-house.png';
import { gAEvent } from '../../../components/EnergyExpertTracking';

const getProviderGeneralInfo = (intelligenceType: any) => {
  return {
    id: 1,
    icon: infoIcon,
    title: 'Vendor Info.',
    text: `Click to see the ${intelligenceType} Vendor’s details`,
  };
};

export const ProviderInfo: FC<any> = ({
  provider,
  postedA,
  title,
  isVerified,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [userInfo, setUserInfo] = useState(false);

  return (
    <>
      {isVerified && (
        <div
          className={classes.providerInfo}
          onClick={() => {
            gAEvent({
              category: provider,
              action: 'Provider Info',
              label: title,
            });
            ReactGA.set({ contentGroup1: provider, contentGroup2: title });

            setUserInfo((state) => !state);
          }}
        >
          <div className={classes.iconWrapper}>
            <div className={classes.icon}>
              <img src={infoIcon} alt="" width="100%" height="100%" />
            </div>
          </div>
          <div
            className={classes.generalInfoContent}
            onClick={() => setUserInfo((state) => !state)}
          >
            {!userInfo ? (
              <div onClick={() => setUserInfo((state) => !state)}>
                <p className="title">{getProviderGeneralInfo(postedA).title}</p>
                <p className="see-more">
                  {getProviderGeneralInfo(postedA).text}
                </p>
              </div>
            ) : (
              <p className="title">{provider}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
