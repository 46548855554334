const isHomeToured = 'isHomeToured';
const isListingsToured = 'isListingsToured';
const isLearningListingsToured = 'isLearningListingsToured';
const isSolutionToured = 'isSolutionToured';
const isLearningSolutionToured = 'isLearningSolutionToured';

export const localStorageKeys = {
  isHomeToured,
  isListingsToured,
  isLearningListingsToured,
  isSolutionToured,
  isLearningSolutionToured,
};
