import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  providerInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    pointerEvents: 'none',
    '& *': {
      pointerEvents: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    }
  },

  icon: {
    width: 56,
    height: 56,
    minWidth: 56,
    marginRight: 16,
    background: '#ffffff',
    borderRadius: '5px',
  },

  mediaWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxHeight: 250,
    overflowY: 'scroll',
    paddingRight: 15,

    '&::-webkit-scrollbar': {
      width: 5,
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: 'none'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: 'rgb(48, 56, 101)'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#030e48'
    }
  },

  content: {
    width: '100%',
    '& a': {
      textDecoration: 'none',
    },
    '& .title': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      color: '#fff',
      overflow: 'hidden',
      width: '90%',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },

    '& .see-more': {
      fontSize: 13,
      lineHeight: 1.23,
      color: ({ theme }) => theme.colors.accent,
    },
  },
  dataBoxFileLink: {
    textDecoration: 'none',
    width: '50%'
  },
  fileLink: {
    textDecoration: 'none',
    width: '100%',
    overflowX: 'hidden',
  },
  item: {
    marginBottom: 24,
    '& .case': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },
  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    dataBoxFileLink: {
      width: '100%'
    },
  }
});
