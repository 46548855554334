import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  textWrapper: {
    overflow: 'hidden',
    marginBottom: 8,
  },
  introWrapper: {
    overflow: 'hidden',
    marginBottom: '40px',
  },
  nameWrapper: {
    overflow: 'hidden',
    marginBottom: 8,
  },
  titleWrapper: {
    overflow: 'hidden',
    marginBottom: 16,
  },

  greeting: {
    fontSize: 63,
    lineHeight: 1.14,
    color: '#5f9afc',
    fontWeight: 'normal',
    animation: '$rotate-text 1s ease-out 1 0.3s forwards',
    opacity: 0,
  },

  introText: {
    fontSize: 16,
    lineHeight: 1.5,
    animation: '$rotate-text 1s ease-out 1 0.6s forwards',
    opacity: 0,
  },

  name: {
    fontSize: 28,
    lineHeight: 1.14,
    fontWeight: 500,
    animation: '$rotate-text 1s ease-out 1 1.1s forwards',
    opacity: 0,
  },

  title: {
    fontSize: 15,
    lineHeight: 1.6,
    animation: '$rotate-text 1s ease-out 1 1.45s forwards',
    opacity: 0,
  },
  company: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 500,
    animation: '$rotate-text 1s ease-out 1 1.6s forwards',
    opacity: 0,
  },
  '@keyframes rotate-text': {
    from: {
      transform: 'rotate(30deg)',
      opacity: 1,
      transformOrigin: '-50% -100%',
    },
    to: {
      transform: 'rotate(0)',
      opacity: 1,
      transformOrigin: '-50% -100%',
    }
  },
});
