import moment from 'moment-timezone';

/************************
 ********  Constants    *********
 ******************************/
// get records between start and end data (time in seconds)
export const yesturdayEndOfDay =
  moment
    .tz('Europe/London')
    .startOf('day')
    .subtract(1, 'minute')
    .utc()
    .valueOf() / 1000;
export const todayStartOfDay =
  moment
    .tz('Europe/London')
    .startOf('day')
    .utc()
    .valueOf() / 1000;

export const LATEST_PRICE_PRINT =
  'https://api.oilpriceapi.com/v1/prices/latest';
export const CLOSE_PRICE_PRINT = `https://api.oilpriceapi.com/v1/prices?by_period[from]=${yesturdayEndOfDay}&by_period[to]=${todayStartOfDay}`;

export const LATEST_PRICE_WTI =
  'https://api.oilpriceapi.com/v1/prices/latest/?by_code=WTI_USD';

export const CLOSE_PRICE_WTI = `https://api.oilpriceapi.com/v1/prices?by_code=WTI_USD&by_period[from]=${yesturdayEndOfDay}&by_period[to]=${todayStartOfDay}`;

export const oilPriceApiKey =
  process.env.REACT_APP_OIL_PRICE_API_KEY || 'b39ddfd6d12c2feffc732a3e5ec3987f';

export const headers = {
  Authorization: `Token ${oilPriceApiKey}`,
  'Content-Type': 'application/json',
};

export const gasPriceApiKey =
  process.env.REACT_APP_HENRY_HUB_GAS_API_KEY

export const HENRY_GAS_PRICE_API_LINK = "https://www.quandl.com/api/v3/datasets/EIA/NG_RNGWHHD_D.json?api_key="

////////////////

export const calcWidgetData = (current: any, close: any) => {
  const diff: number = current?.price - close?.price;
  const diffPercent = ((diff / close?.price) * 100).toFixed(2);
  const diffSign = diff > 0 ? '+' : '-';

  return { diff, diffPercent, diffSign };
};

export const calcGasWidgetData = (current: any, close: any) => {
  const diff: number = Number(current) - Number(close);
  const diffPercent = ((diff / Number(close)) * 100).toFixed(2);
  const diffSign = diff > 0 ? '+' : '-';

  return { diff, diffPercent, diffSign };
};


