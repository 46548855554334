import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<any> = ({
  children,
  path,
  exact,
  role,
  allowedRoles,
  ...rest
}) => {
  const isAuthorizes = allowedRoles.includes(role);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (rest.isAuthenticated) {
          if (isAuthorizes) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: rest.location },
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
