import { createUseStyles } from 'react-jss';
import { breakPoints } from '../../theme/breakpoints';

export const useStyles = createUseStyles({
  wrapper: {
    width: '70%',
    maxWidth: 832,
    textAlign: 'initial',
  },
  introText: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    color: ({ theme }) => theme.colors.skyBlue,
    marginBottom: 20,
    textAlign: 'center',
  },

  lookingForMore: {
    fontSize: 15,
    lineHeight: 1.6,
    textAlign: 'center',
    color: 'white',
    fontWeight: 300,
    marginBottom: 16,
    display: 'inline-block',
    margin: '0 auto',
    cursor: 'pointer',
  },

  introTitle: {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#5f9afc',
    textAlign: 'center',
    marginBottom: 16,
  },

  groupArea: {
    marginBottom: 32,
  },

  groupTitle: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#5f9afc',
    textAlign: 'center',
    marginBottom: 8,
    position: 'relative',
  },

  groupCount: {
    position: 'absolute',
    right: 20,
  },

  areasGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: 395,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,

    '&::-webkit-scrollbar': {
      width: 5,
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: 'none',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: 'rgb(48, 56, 101)',
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#030e48',
    },

    '& div:nth-child(odd)': {
      marginRight: 12,
    },
  },

  area: {
    width: 'calc(50% - 6px)',
    height: 'auto',
    borderRadius: 4,
    backgroundColor: ({ theme }) => theme.colors.bg.accent,
    padding: '18px 32px 18px',
    marginBottom: 12,
    transition: 'background 0.8s, transform 0.8s',

    '&:nth-child(odd)': {
      '&:hover': {
        backgroundColor: ({ theme }) => theme.colors.bg.intelAreaHighlighted,
        transform: 'translate(-8px, -8px)',
      },
    },
    '&:nth-child(even)': {
      '&:hover': {
        backgroundColor: ({ theme }) => theme.colors.bg.intelAreaHighlighted,
        transform: 'translate(8px, -8px)',
      },
    },
  },
  divider: {
    height: 2,
    opacity: 0.3,
    border: ({ theme }) => `solid 2px ${theme.colors.bg.intelAreaHighlighted}`,
    margin: '30px 0',
  },
  learningArea: {
    height: 'auto',
    cursor: 'pointer',
    paddingLeft: 80,
    borderRadius: 4,
    backgroundColor: ({ theme }) => theme.colors.bg.accent,
    padding: '18px 32px 40px',
    marginBottom: 12,
    transition: 'background 0.8s, transform 0.8s',
    border: ({ theme }) => `solid 2px ${theme.colors.bg.intelAreaHighlighted}`,

    '&:nth-child(odd)': {
      '&:hover': {
        backgroundColor: ({ theme }) => theme.colors.bg.intelAreaHighlighted,
        transform: 'translate(-8px, -8px)',
      },
    },
    '&:nth-child(even)': {
      '&:hover': {
        backgroundColor: ({ theme }) => theme.colors.bg.intelAreaHighlighted,
        transform: 'translate(8px, -8px)',
      },
    },
  },
  tooltip: {
    position: 'relative',
    display: 'inline-block',

    '& span': {
      visibility: 'hidden',
      width: 120,
      backgroundColor: ({ theme }) => theme.colors.bg.newListings,
      color: ({ theme }) => theme.colors.primary,
      textAlign: 'center',
      padding: '5px 0',
      borderRadius: 6,
      position: 'absolute',
      bottom: '140%',
      left: '50%',
      marginLeft: -70,
      zIndex: 3,
    },
    '&:hover span': {
      visibility: 'visible',
    },
  },

  icon: {
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    margin: `0 !important`,
    '& p': {
      borderRadius: '50%',
      backgroundColor: ({ theme }) => theme.colors.skyBlue,
      border: '1px solid white',
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      position: 'absolute',
      top: -10,
      right: -24,
    },
  },
  iconImage: {
    height: 25,
  },
  areaName: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.14,
    marginBottom: 12,
  },
  areaShortDesc: {
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 1.43,
  },
  marketSnapshotWrapper: {
    marginTop: 60,
    marginBottom: 40,
  },
  marketSanpshotTitle: {
    fontSize: 32,
    fontWeight: 500,
    textAlign: 'center',
    color: ({ theme }) => theme.colors.skyBlue,
    marginBottom: 30,
  },
  oilPricesSection: {
    marginBottom: 40,
  },
  pointer: {
    cursor: 'pointer',
  },
  marginsTB: {
    marginTop: 20,
    marginBottom: 30,
  },
  dataBox: {
    color: ({ theme }) => theme.colors.skyBlue,
    fontSize: 32,
    marginTop: 30,
    fontFamily: 'GoogleSans',
    fontWeight: 500,
    marginBottom: 40,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  counter: {
    borderRadius: '50%',
    backgroundColor: ({ theme }) => theme.colors.skyBlue,
    border: '1px solid white',
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
  },
  mediaContent: {
    color: 'white',
    opacity: 0.6,
    fontSize: 18,
    fontFamily: 'GoogleSans',
    marginBottom: 40,
  },
  [`@media (max-width: ${breakPoints.tablet}px)`]: {
    wrapper: {
      width: '95%',
    },
    area: {
      width: '100%',
    },
    areasGrid: {
      '& div:nth-child(odd)': {
        marginRight: 0,
      },
    },
    areaName: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.2,
      marginBottom: 12,
    },
    areaShortDesc: {
      opacity: 0.5,
      fontSize: 12,
      lineHeight: 1.43,
    },
    learningArea: {
      height: 'auto',
      paddingLeft: 32,
    },
  },
});
