export const tourConfig = [
  //'start-tour-tip'
  {
    target: '.start-tour-tip',
    content:
      "Hello there! 👋 Welcome to your EnergyExpert Intelligence Portal. Allow us to show you around. Click 'Next' to move along!",
    disableBeacon: true,
  },

  {
    target: '.intelligence-areas-tip',
    content:
      'Intelligence Areas are operational or business areas that have been identified as beneficial to your team. Within each Area, we identify and research the most relevant products and services for your team’s needs.',
    placementBeacon: 'top',
    disableBeacon: true,
  },

  {
    target: '.learning-tip',
    content:
      'Within Learning, you’ll find online, in-person, or virtual learning content that has been identified as beneficial to your team.',
    disableBeacon: true,
  },

  {
    target: '.market-snapshot-tip',
    content:
      'Market Snapshot shows market indicators and news that have been identified as relevant to your team.',
    disableBeacon: true,
  },

  {
    target: '.data-box-tip',
    content:
      'Your Databox contains reports and other media content that have been identified as relevant to your team.',
    disableBeacon: true,
  },

  {
    target: '.search-input',
    content:
      'Use this global Search to find any product, service, learning, or content on your EnergyExpert portal.',
    disableBeacon: true,
  },

  {
    target: '.finish-tour-tip',
    content:
      'You’re now familiarized with the Home Dashboard. Select one of the Intelligence or Learning areas to continue the tour.',
    disableBeacon: true,
  },
];
