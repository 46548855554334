import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import axios from '../../config/axios';
import { Form } from './LoginForm';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import { NavBar } from '../../components/NavBar/NavBar';
import { useTheme } from 'react-jss';
import { useStyles } from './style';
import ReactGA from "react-ga";


const InputForm: React.FC<any> = ({ updateAuthState }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [errorMsg, setErrorMsg] = useState('');

  const submit = async (data: any) => {
    try {
      
      const { data: user } = await axios.post('/login', data);
      updateAuthState({ isAuthenticated: true, ...user });
      ReactGA.set({ dimension1: user.email });
      history.push(`/home`);

    } catch (error) {
      const errorMsg = error?.response?.data?.output?.payload?.message;
      setErrorMsg(errorMsg);
    }
  };

  const values = { email: '', password: '' };

  return (
    <React.Fragment>
      <nav className={classes.navbar}>
        <NavBar withUserList={false} withSearch={false} />
      </nav>
      <div className={classes.container}>
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <h2 className={classes.cardTitle}>
              Login to Your EnergyExpert Account
            </h2>
          </div>
          <div className={classes.cardBody}>
            <div
              style={{
                marginBottom: 20,
                height: 48,
              }}
            >
              {!!errorMsg && <ErrorAlert msg={errorMsg} />}
            </div>
            <Formik
              render={props => <Form {...props} />}
              initialValues={values}
              onSubmit={submit}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputForm;
